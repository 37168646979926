import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Order } from '../../../shared/model/order';
import { OrderService } from '../../../shared/services/order.service';
import { Subscription } from 'rxjs';
import { PhonePipe, XPOConstants } from '../../../shared';
import { AppSettings } from '../../../core/app-settings';
import { DomSanitizer } from '@angular/platform-browser';
import { I18nService } from 'app/shared/services/i18n.service';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss']
})

export class ContactSupportComponent implements OnInit, OnDestroy {
  @Input() isLandingPage: boolean = false;
  public order: Order;
  private orderSubscription: Subscription;
  public showContact: boolean = true;
  public XPOCustomerCarephoneNumber: string;
  public supportEmail: string;
  public supportEmailSubject: string;
  constructor(
    private orderService: OrderService,
    private phonePipe: PhonePipe,
    private appSettings: AppSettings,
    private sanitization: DomSanitizer,
    private i18nService: I18nService
  ) {}

  ngOnInit() {
    this.orderSubscription = this.orderService.order.subscribe(order => {
      if (order) {
        this.order = order;
        this.XPOCustomerCarephoneNumber = this.isLandingPage ? XPOConstants.XPO_SUPPORT_CONTACT : this.phonePipe.transform(this.order.xpoPhoneNumber, 0);
      } else {
        this.XPOCustomerCarephoneNumber = XPOConstants.XPO_SUPPORT_CONTACT;
      }
      this.supportEmail = this.appSettings.domainName === 'XPO' ? XPOConstants.SUPPORT_EMAIL_XPO : XPOConstants.SUPPORT_EMAIL_RXO;
      this.supportEmailSubject = this.isLandingPage ? ((this.i18nService.localeID === XPOConstants.I18N_FR) ?
        XPOConstants.SUPPORT_EMAIL_SUBJECT_LANDING_PAGE_FRENCH : XPOConstants.SUPPORT_EMAIL_SUBJECT_LANDING_PAGE).replace('[DOMAIN_NAME]', this.appSettings.domainName) :
        this.appSettings.getMessage('SUPPORT_EMAIL_SUBJECT').replace('[DOMAIN_NAME]', this.appSettings.domainName) + this.order?.salesOrderId;
    });
  }

  public displayContacts() {
    this.showContact = !this.showContact;
  }

  public closeDialog() {
    const elem: HTMLElement = document.getElementById('contact-support');
    elem.setAttribute('style', 'display:none;');
  }

  public openCallDialog() {      
    window.location.href = 'tel:' + (this.isLandingPage ? XPOConstants.XPO_SUPPORT_CONTACT : this.order.xpoPhoneNumber);
  }

  public checkVisibility() {
    let val = ''
    if (this.isLandingPage) {
      val = 'block';
    }
    else {
      val = 'none';
    }
    return this.sanitization.bypassSecurityTrustStyle(`--display-value : ${val}`);
  }

  ngOnDestroy() {
    this.orderSubscription.unsubscribe();
  }

}

export class XPOConstants {
    public static readonly NAME_REGEX = '[a-zA-Z]+';
    public static readonly EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/;
    public static readonly MESSAGE_REGEX = '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$';
    public static readonly PHONE_REGEX = '^[0-9]{10}$';
    public static readonly PHONE_MASK = '(999) 999-9999';
    public static readonly DATE_REGEX = '^([0]?[1-9]|[1][0-2])[./-]([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0-9]{4}|[0-9]{2})$';
    public static readonly JOB_NUMBER_REGEX = '^[A-Za-z0-9]*$';
    public static readonly RESCHEDULE_DATE_SPLIT_REGEX = /[^0-9]/;
    public static readonly CONDITIONS_URL = 'http://www.xpo.com/sms';
    public static readonly PRIVACY_URL = 'http://www.xpo.com/privacy-policy';
    public static readonly CONDITIONS_URL_RXO = 'http://www.rxo.com/sms';
    public static readonly PRIVACY_URL_RXO = 'http://www.rxo.com/privacy-policy';
    public static readonly AERIS_WEATHER_PI_URL = 'https://api.aerisapi.com/batch?';
    public static readonly I18N_FILE_NAME = 'messageSettings';
    public static readonly ENGLISH = 'English';
    public static readonly FRENCH = 'French';
    public static readonly I18N_EN = 'en-US';
    public static readonly I18N_FR = 'fr-CA';
    public static readonly LOCALE_EN = 'en';
    public static readonly LOCALE_FR = 'fr';
    public static readonly I18N_COOKIES_KEY = 'i18nLanguage';
    public static readonly SUPPORT_EMAIL_XPO = 'lastmileorders@xpo.com';
    public static readonly SUPPORT_EMAIL_RXO = 'lastmileorders@rxo.com';
    public static readonly XPO_SUPPORT_CONTACT = '(800) 883-4593';
    public static readonly SUPPORT_EMAIL_SUBJECT_LANDING_PAGE = "[DOMAIN_NAME] Customer Order Tracking";
    public static readonly SUPPORT_EMAIL_SUBJECT_LANDING_PAGE_FRENCH = "Suivi des commandes client [DOMAIN_NAME]";
    public static readonly SEARCH_COMPONENT_SIZE = 387;
    public static readonly ERROR_COMPONENT_SIZE = 350;
    public static readonly CONTACT_COMPONENT_SIZE = 116;
    public static readonly MARGIN_LARGE = 30;
    public static readonly MARGIN_SMALL = 10;
    public static readonly MOBILE_SIZE_LARGE = 700;
    public static readonly XPO_URL = 'https://www.xpo.com/';
    public static readonly RXO_URL = 'https://www.rxo.com/';

    


    public static readonly ERROR_MESSAGE = {
        PAGE_NOT_FOUND_ICON: 'search',
        INTERNAL_SERVER_ERROR_ICON: 'storage',
        CONNECTION_ERROR_ICON: 'cloud_off',
        FORBIDDEN_ICON: 'block'
    };
}

<div class="main-div search-dialog" id="addToCalendar" [ngClass]="appSettings.isNewDomain ? 'rxo-style' : 'xpo-style'">
  <div class="sub-main-div">
    <div class="close-div">
      <i class="material-icons close" (click)="closeDialog($event)">&#xE5CD;</i>
    </div>
    <div class="dialog-main">
      <mat-card id="order-search" class="detail-card" *ngIf="!isShowUsersCalendar || isCalendarAdded">
        <div class="main-margin">
          <mat-card-header class="title-card">
            <mat-card-title class="title-size" i18n="@@AddToCalendarModule-AddToCalendarComponent-AddCalendar">Add Calendar</mat-card-title>
            <h2 *ngIf="!isCalendarAdded" class="add-calendar-sub-heading">
              <a class="download-link" (click)="downloadCalendar()">
                <span i18n="@@AddToCalendarModule-AddToCalendarComponent-Download">Download</span>
              </a>
              <span i18n="@@AddToCalendarModule-AddToCalendarComponent-SyncWithCalendar">or Sync with your Calendar</span>
            </h2>
            <mat-card-subtitle class="title-sub">{{headerTextAddTocalendar}}</mat-card-subtitle>
          </mat-card-header>
        </div>
      </mat-card>
      <div *ngIf="!isCalendarAdded">
        <mat-card id="order-search" class="detail-card" *ngIf="!isShowUsersCalendar">
          <div class="main-margin">
            <div class="calendar-option">
              <form #tetsForm="ngForm">
                <section>
                  <mat-radio-group class="radio-btn-grp">
                    <mat-radio-button class="radio-btn" target="selected" value="google" (change)="onCalendarOptionSelected(GOOGLE_PROVIDER_ID)"
                      required i18n="@@AddToCalendarModule-AddToCalendarComponent-GoogleCalendar">Google Calendar</mat-radio-button>
                    <mat-radio-button class="radio-btn" target="selected" value="outlook" (change)="onCalendarOptionSelected(OUTLOOK_PROVIDER_ID)"
                      *ngIf="isOutlookEnabled()" required i18n="@@AddToCalendarModule-AddToCalendarComponent-OutlookCalendar">Outlook Calendar</mat-radio-button>
                  </mat-radio-group>
                  <mat-checkbox target="checked" [disabled]="!isCalendarSelected" (change)="onTermsAndConditionsAccepted($event)" required>
                    <span>{{addToCalendarConditionText}}</span>
                    <br />
                    <a class="termsAndConditions-link" (click)="openUrl(privacyUrl)">
                      <span i18n="@@AddToCalendarModule-AddToCalendarComponent-PrivacyNotice">privacy notice</span>
                    </a>
                    <span i18n="@@AddToCalendarModule-AddToCalendarComponent-And"> and </span>
                    <a class="termsAndConditions-link" (click)="openUrl(conditionUrl)">
                      <span i18n="@@AddToCalendarModule-AddToCalendarComponent-ConditionsOfUse">conditions of use</span>
                    </a>.
                  </mat-checkbox>
                  <div class="calendar-form-button-row">
                    <button mat-raised-button class="calendar-form-btn-black btn-top-space" (click)="onSubmitCalendarRequest($event)" [disabled]="!isTermsAndConditionsAccepted"
                      i18n="@@AddToCalendarModule-AddToCalendarComponent-Submit">SUBMIT</button>
                  </div>
                </section>
              </form>
            </div>
          </div>
        </mat-card>
        <mat-card id="order-search" class="detail-card" *ngIf="isShowUsersCalendar">
          <div>
            <mat-card-header class="title-card">
              <mat-card-title class="title-size" i18n="@@AddToCalendarModule-AddToCalendarComponent-MyCalendar">My Calendar</mat-card-title>
            </mat-card-header>
            <app-calendar-header [(view)]="view" [(viewDate)]="viewDate" [(locale)]="locale"></app-calendar-header>

            <div [ngSwitch]="view" class="calendar-events">
              <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="calendarEvents" [hourSegments]="1" [eventWidth]="100"
                [dayStartHour]="6" [dayEndHour]="22">
              </mwl-calendar-day-view>
            </div>
          </div>
          <div class="reschedule-form-button-row">
            <button mat-raised-button *ngIf="orders[0].eligibleForReschedule" class="calendar-form-btn-black" (click)="openRescheduleDialog($event)"
              i18n="@@AddToCalendarModule-AddToCalendarComponent-Reschedule">Schedule</button>
            <button mat-raised-button class="calendar-form-btn-black calendar-button-space" (click)="addToCalendar($event)" i18n="@@AddToCalendarModule-AddToCalendarComponent-Sync">Sync</button>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <app-confirmation *ngIf="isCalendarAdded" [confirmationText]="confirmationAddToCalendar"></app-confirmation>
  <app-footer *ngIf="menuService.isMobileDevice()"></app-footer>
</div>
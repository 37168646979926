<div class="wrapper" [ngClass]="appSettings.isNewDomain?  'rxo-style':'xpo-style'">
  <mat-sidenav-container class="tracker-sidenav-container" [ngClass]="isMobile ? 'show-mobile' : 'show-desktop'"
    *ngIf="order && !menuService.isLandingPage" (backdropClick)="toggleDraw()">

    <div class="tracker-scrolling-content-left tracker-side-menu-desktop desktop-menu" *ngIf="!isMobile">
      <mat-list>
        <mat-list-item *ngFor="let menus of MenuHolder; let i=index;" (click)="onMenuNavigation(i, menus.name)"
          [ngClass]="{'nav-active': i == selectedItem}">
          <span class="material-icons">
            <img src={{menus.icons}}>
          </span>
          <p class="{{menus.name == 'Schedule' ? 'menu-content-name-bold' : 'menu-content-name'}}">{{menus.name}}</p>
        </mat-list-item>
      </mat-list>
    </div>

    <mat-sidenav #sidenav class="sidenav-custom" [style.display]="isMobile ? 'block' : 'none'">
      <div class="tracker-scrolling-content-left">
        <mat-list>
          <mat-list-item *ngFor="let menus of MenuHolder; let i=index;"
            (click)="onMenuNavigation(i, menus.name); sidenav.toggle()" [ngClass]="{'nav-active': i == selectedItem}">
            <span class="material-icons">
              <img class="menu-icon" src={{menus.icons}}>
            </span>
            <span
              class="{{menus.name == 'Schedule' ? 'menu-content-name-bold' : 'menu-content-name'}}">{{menus.name}}</span>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-sidenav>
    <div class="tracker-scrolling-content" [style.margin-left]="!isMobile ? '58px' : '0px'">
      <div class="tracking-header" *ngIf="isMobile">
        <button type="button" mat-button (click)="sidenav.toggle(); toggleDraw()" *ngIf="!expanded">
          <img class="ham-icons" src="./assets/icons/icon-hamburger-closed{{rxoStr}}.svg">
        </button>
        <button type="button" mat-button (click)="sidenav.toggle(); toggleDraw()" *ngIf="expanded">
          <img class="close-icons" src="./assets/icons/icon-hamburger-open{{rxoStr}}.svg">
        </button>
        <div class="xpo-i18n-header">
          <app-language-selector [orderId]="order.orderId"></app-language-selector>
        </div>
        <hr *ngIf="!appSettings.isNewDomain">
        <div class="xpo-logo-header">
          <app-header></app-header>
        </div>

      </div>
      <div class="multiAppointment-banner multiAppointment-banner-mobile" *ngIf="showMultiAppointmentBanner && isMobile">
        <span *ngIf="order.status !== 'DL'" i18n="@@layoutComponent-multiAppointmentBanner-deliveryIncomplete">
          Professional installation will be scheduled once delivery of the order has been completed.
        </span>
        <div *ngIf="order.status === 'DL' && order.multiStopAssociatedWorkOrder">
          <a *ngIf="order.multiStopAssociatedWorkOrder.schedulingStatusId === 3 || order.multiStopAssociatedWorkOrder.schedulingStatusId === 4"
          i18n="@@layoutComponent-multiAppointmentBanner-serviceScheduled" (click)="openAssociatedWorkOrder()" class="multiAppointment-banner-link">
            We see that your professional installation is scheduled for {{order.multiStopAssociatedWorkOrder.scheduledDate | datex: 'MM/DD/YYYY'}}. Click here to track details on installation.
          </a>
          <a *ngIf="order.multiStopAssociatedWorkOrder.schedulingStatusId === 2" i18n="@@layoutComponent-multiAppointmentBanner-serviceReadyForSchedule"
          (click)="openScheduleAssociatedWorkOrder()" class="multiAppointment-banner-link">
            You are now ready to schedule installation of your product. Click here to schedule your installation.
          </a>
          <span *ngIf="order.multiStopAssociatedWorkOrder.schedulingStatusId === 1" i18n="@@layoutComponent-multiAppointmentBanner-serviceNotReadyForSchedule">
            We will contact you to schedule your installation.
          </span>
        </div>
      </div>
      <div class="main-content" [ngClass]="isMobile ? 'mobile-main-content' : ''">
        <div class="landing-left" *ngIf="!isMobile">
          <app-track-order #trackOrderComponent></app-track-order>
        </div>
        <div class="mobile-map-main" *ngIf="isMobile && !hideMap">
          <app-track-order-map></app-track-order-map>
        </div>

        <router-outlet></router-outlet>

        <div class="landing-right" *ngIf="!isMobile">
          <div class="tracking-header">
            <div class="multiAppointment-banner" *ngIf="showMultiAppointmentBanner">
              <span *ngIf="order.status !== 'DL'" i18n="@@layoutComponent-multiAppointmentBanner-deliveryIncomplete">
                Professional installation will be scheduled once delivery of the order has been completed.
              </span>
              <div *ngIf="order.status === 'DL' && order.multiStopAssociatedWorkOrder">
                <a *ngIf="order.multiStopAssociatedWorkOrder.schedulingStatusId === 3 || order.multiStopAssociatedWorkOrder.schedulingStatusId === 4"
                i18n="@@layoutComponent-multiAppointmentBanner-serviceScheduled" (click)="openAssociatedWorkOrder()" class="multiAppointment-banner-link">
                  We see that your professional installation is scheduled for {{order.multiStopAssociatedWorkOrder.scheduledDate | datex: 'MM/DD/YYYY'}}. Click here to track details on installation.
                </a>
                <a *ngIf="order.multiStopAssociatedWorkOrder.schedulingStatusId === 2" i18n="@@layoutComponent-multiAppointmentBanner-serviceReadyForSchedule"
                (click)="openScheduleAssociatedWorkOrder()" class="multiAppointment-banner-link">
                  You are now ready to schedule installation of your product. Click here to schedule your installation.
                </a>
                <span *ngIf="order.multiStopAssociatedWorkOrder.schedulingStatusId === 1" i18n="@@layoutComponent-multiAppointmentBanner-serviceNotReadyForSchedule">
                  We will contact you to schedule your installation.
                </span>
              </div>
            </div>
            <div class="xpo-i18n-header" [ngClass]="{'homeClass': appSettings.isNewDomain &&(selectedItemName===appSettings.getMessage('HOME')||isHome())}">
              <app-language-selector [orderId]="order.orderId"></app-language-selector>
            </div>
            <hr *ngIf="!appSettings.isNewDomain">
            <div class="xpo-logo-header">
              <app-header></app-header>
            </div>
          </div>
          <app-track-order-map></app-track-order-map>
        </div>
        <div class="mobile-track-main" *ngIf="isMobile">
          <app-track-order #trackOrderComponentMobile [hidden]="!menuService.isHomeSelected"></app-track-order>
        </div>

      </div>
    </div>
  </mat-sidenav-container>

  <div class="landing-right" *ngIf="menuService.isLandingPage">
    <div class="tracking-header">
      <div class="xpo-logo-header xpo-landing-header">
        <app-header></app-header>
      </div>
    </div>
    <div class="mobile-map-landing">
      <app-track-order-map></app-track-order-map>
      <app-contact-support id="contact-support" *ngIf="!errorResponse" class="contact-landing-page" [isLandingPage]="true"></app-contact-support>
    </div>
  </div>
  <app-contact-support *ngIf="errorResponse" [style]="calculateTopPositionForContactComponent()" class="contact-landing-page size-fix" [isLandingPage]="true"></app-contact-support>
  <app-back-to-top></app-back-to-top>
</div>
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { Order } from '../../shared';
import { MenuService } from '../../core/layout/menu.service';
import { AppSettings } from '../../core/app-settings';

@Injectable()
export class AddToCalendarService {
    private icalendar: string[] = [];
    private calendarFileName = '';

    constructor(private menuService: MenuService, private appSettings: AppSettings) { }

    public addToCalendar(orders: Order[], event: string) {
        this.icalendar = this.getIcsCalendarForAddition(orders);
        this.icalendar.join('\n');
        this.downloadCalendar();
    }

    private getIcsCalendarForAddition(orders: Order[]): string[] {
        if (orders[0].billTo.name) {
            this.calendarFileName = this.getIcsFileName(orders[0].billTo.name + ' Appointment', false);
        } else {
            this.calendarFileName = this.getIcsFileName('Delivery' + ' Appointment', false);
        }
        const calendarData: string[] = [];
        if (this.menuService.isSafariBrowser()) {
            /*
            ** Dont change the formatting below. This will affect the ics behaviour
            */
            calendarData.push(
                `BEGIN:VCALENDAR
VERSION:2.0
CLASS:PUBLIC`);
            for (const order of orders) {
                // API logic pending
                const calendarString = `
BEGIN:VEVENT
METHOD:REQUEST
DESCRIPTION:${order.billTo.name} ${this.appSettings.getMessage('APPOINTMENT_XPO_ORDER_ID').replace('[DOMAIN_NAME]', this.appSettings.domainName)} ${order.trackingNumber}
DTSTART:${this.getTimeWindow(order.deliveryServiceWindowStartTime)}
DTEND:${this.getTimeWindow(order.deliveryServiceWindowEndTime)}
SUMMARY:${order.billTo.name} ${this.appSettings.getMessage('APPOINTMENT_XPO_ORDER_ID').replace('[DOMAIN_NAME]', this.appSettings.domainName)} ${order.trackingNumber}
TRANSP:TRANSPARENT
SEQUENCE:1
END:VEVENT`;
                calendarData.push(calendarString);
            }
            calendarData.push(`
UID:xpolastmile
DTSTAMP:${this.getTimeCreated()}
PRODID:xpolastmile
END:VCALENDAR`);
            return calendarData;
        } else {
            /*
            ** Dont change the formatting below. This will affect the ics behaviour
            */
            calendarData.push(
                `BEGIN:VCALENDAR
VERSION:2.0
PRODID:xpolastmile`);
            for (const order of orders) {
                // API logic pending
                const calendarString = `
BEGIN:VEVENT
METHOD:REQUEST
DTSTAMP:${this.getTimeCreated()}
SEQUENCE:1
UID:xpolastmile
DTSTART:${this.getTimeWindow(order.deliveryServiceWindowStartTime)}
DTEND:${this.getTimeWindow(order.deliveryServiceWindowEndTime)}
SUMMARY:${order.billTo.name} ${this.appSettings.getMessage('APPOINTMENT_XPO_ORDER_ID').replace('[DOMAIN_NAME]', this.appSettings.domainName)} ${order.trackingNumber}
DESCRIPTION:${order.billTo.name} ${this.appSettings.getMessage('APPOINTMENT_XPO_ORDER_ID').replace('[DOMAIN_NAME]', this.appSettings.domainName)} ${order.trackingNumber}
TRANSP:OPAQUE
END:VEVENT`;
                calendarData.push(calendarString);
            }
            calendarData.push(`
END:VCALENDAR`);
            return calendarData;

        }

    }

    private getIcsFileName(title, isExtensionNeeded) {
        if (isExtensionNeeded) {
            return `${title.replace(/[^\w ]+/g, '')}.ics`;
        }
        return `${title.replace(/[^\w ]+/g, '')}`;
    }

    private getTimeCreated() {
        return moment().format('YYYYMMDDTHHmmss');
    }

    private downloadCalendar() {
        const fileName = this.getIcsFileName(this.calendarFileName, true),
            icsBlob = this.getIcsBlob(this.icalendar); // file saver format (name, dataUrl, data)
        saveAs(icsBlob, fileName);
    }

    private getIcsBlob(icsData) {
        return new Blob(icsData, {
            type: 'text/calendar'
        });
    }
    private getTimeWindow(deliveryWindow) {
        return moment(deliveryWindow).format('YYYYMMDDTHHmmss');
    }
}

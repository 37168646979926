<div class="page-error-dialog">
  <p class="page-error-content">{{errMessage}}</p>
  <mat-dialog-content>
    <mat-icon class="material-icons page-error">{{errIcon}}</mat-icon>
    <h2 [ngClass]="connectionError === true ? 'connection-error' : 'page-not-found'">{{errHeading}}</h2>
  </mat-dialog-content>
  <mat-dialog-actions class="page-error-action" *ngIf="!order && !showOkay">
    <button mat-button [mat-dialog-close]="true" class="page-error-btn" (click)="search()" i18n="@@SharedModule-PageErrorComponent-Search">SEARCH</button>
  </mat-dialog-actions>
  <mat-dialog-actions class="page-error-action" *ngIf="order && !showOkay">
    <button mat-button [mat-dialog-close]="true" class="page-error-btn" (click)="openHome()" i18n="@@SharedModule-PageErrorComponent-Home">HOME</button>
  </mat-dialog-actions>
  <mat-dialog-actions class="page-error-action" *ngIf="showOkay">
    <button mat-button (click)="closeDialog()" class="page-error-btn" i18n="@@SharedModule-PageErrorComponent-Ok">OK</button>
  </mat-dialog-actions>
</div>
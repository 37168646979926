import { Component } from '@angular/core';
import { AppSettings } from './core/app-settings';
import { FreeDraggingDirective } from './shared/draggable.directive';
import { ChatService } from './shared/services/chat.service';
import { GTMHelperService } from './shared/services/gtm-helper.service';

@Component({
  selector: 'app-root',
  template: `<app-track-spinner></app-track-spinner><app-layout></app-layout>`
})
export class AppComponent {
  private draggableDirective: FreeDraggingDirective;
  alreadyCalled = false;

  favIcon: HTMLLinkElement = document.querySelector('#appFavicon');

  constructor(gtmHelperService: GTMHelperService,private chatService: ChatService, private appSettings: AppSettings) {
    this.favIcon.href = this.appSettings.isNewDomain ? 'RXO_favicon.ico' : 'favicon.ico?v=2';
    this.draggableDirective = new FreeDraggingDirective(null, document);
    try {
      gtmHelperService.initGTMScript();
  } catch (e) {
      console.log('error occured while initialized GTM script ' + e)
  }
  }

  ngAfterViewChecked() {
    const chatContainer = document.querySelector('#chat-div-wrap') as HTMLIFrameElement;
    
    if (chatContainer && !this.alreadyCalled) {
      chatContainer.insertAdjacentHTML('afterbegin','<div class="child-div"></div>')
      this.alreadyCalled = true;
      this.draggableDirective.setElement(chatContainer);
      this.draggableDirective.initDrag();
    }
  }
}

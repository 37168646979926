import { Component, OnInit } from '@angular/core';
import { AppSettings } from '../../core/app-settings';

@Component({
  selector: 'app-track-another-order-tab',
  templateUrl: './track-another-order-tab.component.html',
  styleUrls: [ './track-another-order-tab.component.scss' ]
})
export class TrackAnotherOrderTabComponent implements OnInit {

  public trackingLabel: string;

  constructor(private appSettings: AppSettings) { }

  ngOnInit() {
    this.trackingLabel = this.appSettings
    .getMessage('TRACKING')
    .replace('[DOMAIN_NAME]', this.appSettings.domainName);
  }

}

<div *ngIf="order.events.length > 0" id="track-events" class="dialog-main" style="overflow: hidden;">
  <mat-card class="detail-card no-scroll">
    <mat-card-subtitle class="sub-title-card thick" style="cursor: pointer"
      (click)="displayTrackingEvents()">
      <span i18n="@@TrackOrderModule-TrackingEventsComponent-TrackingEvents">Tracking Events </span>
      <i class="arrow" [ngClass]="{'up': showTrackingEvent, 'down': !showTrackingEvent}" style="float:right;"></i>
    </mat-card-subtitle>
    <div class="content-card" *ngIf="showTrackingEvent">
      <div id="etaHeader"
        *ngIf="checkETAHeaderStatus">
        <label>{{estimatedHubLabel}}</label> <span>{{order.expectedHubReceiptDate | date:'EEE, MMM d'}}</span>
      </div>
      <div class="tracking-content-div" *ngFor="let item of order.events; let i=index">
        <!--<div *ngIf="localeFR" class="item-time">{{item.date | datex: 'DD MMM'}} {{item.date |datex:'h:m A'}}</div>
        <div *ngIf="!localeFR" class="item-time">{{item.date | datex: 'MMM DD'}} {{item.date |datex:'h:m A'}}</div> -->
        <div *ngIf="localeFR" class="item-time">{{item.date}}</div>
        <div *ngIf="!localeFR" class="item-time">{{item.date}}</div>
        <div class="tracking-message">
          <div class="item-content"
            [ngStyle]="item.name == 'DELIVERED' && {'color': '#00c853','font-size': '12px !important','font-weight': bold}">
            {{item.name}}</div>
          <span [ngStyle]="(item.city == '' || !item.city == null) ? {'display': 'none'} : {'display': 'block'}"
            class="item-content">{{item.city}}</span>
        </div>
      </div>
    </div>
  </mat-card>
</div>


import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorHandlerService {
  private httpErrorSubject: Subject<number> = new Subject();

  constructor() { }

  get httpErrorCode(): Observable<number> {
    return this.httpErrorSubject.asObservable();
  }
  // Handles error
  public handleError(error: Response | any): Observable<Error> {
    this.httpErrorSubject.next(error);
    return observableThrowError(new Error(error.status));
  }

}

import { TruckLocation } from '../model/weather-alert';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap ,  catchError } from 'rxjs/operators';
import { WeatherAlert } from '../../shared';
import { AppSettings } from '../../core/app-settings';

@Injectable()
export class WeatherAlertService {
  private static readonly AERIS_WEATHER_PI_URL = 'https://api.aerisapi.com/batch?';

  constructor(private http: HttpClient) { }

  public fetchWeatherAlert(truckLocations: TruckLocation[]): Promise<any> {
    let params: string;
    truckLocations.forEach(element => {
      if (params == null || params.length === 0) {
        params = '/observations?p=' + element.lat + ',' + element.long;
      } else {
        params = params + ',/observations?p=' + element.lat + ',' + element.long;
      }
    });
    const url = WeatherAlertService.AERIS_WEATHER_PI_URL + 'client_id=' + AppSettings.config.AERIS_CLIENT_ID +
      '&client_secret=' + AppSettings.config.AERIS_CLIENT_SECRET + '&bcsi_scan_64741547a7fe261b=0&requests=' + params;
    return new Promise((resolve, reject) => {
      this.http.get<WeatherAlert>(url)
        .toPromise().then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '../../loader/loader';
import { Order, DatexPipe, GoogleAnalyticsConstants } from '../../shared';
import { MenuService } from '../../core/layout/menu.service';
import { AppSettings } from '../../core/app-settings';

/**
 * Component responisble for displaying the Product details
 *
 */
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent {
  @Input() order;
  @Input() numberOfStopsAway;
  @Input() welcomeDeliveryText;
  @Input() showExceptionImg;
  @Input() canDisplayStops;
  constructor(
    private spinner: LoaderService,
    private router: Router,
    public menuService: MenuService,
    private appSettings: AppSettings
  ) {

  }

  public openItemUrl(itemUrl: string) {
    if (itemUrl) {
      window.open(itemUrl, '_blank');
    }
  }

  public openOrderDetails() {
    var IsOrderDetail = this.router.url.includes("order-details");
    if (!IsOrderDetail) {
      this.spinner.showLoader();
      this.appSettings.eventTrack({
        action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
        properties: {
          category: GoogleAnalyticsConstants.EVENT_CATEGORY.ORDER_DETAILS,
          label: GoogleAnalyticsConstants.EVENT_CATEGORY.ORDER_DETAILS
        }
      });
      this.router.navigate(['order-details'], { queryParams: { orderid: this.order.orderId } });
      this.hideProductDetailsIfMobile();
    }
  }

  private hideProductDetailsIfMobile() {
    if (this.menuService.isMobileDevice()) {
      this.menuService.isHomeSelected = false;
      this.menuService.segmentRefreshNeeded = false;
      this.menuService.ismapRefreshNeeded = false;
    }
  }

}

import { Injectable } from "@angular/core";
import { AppSettings } from "../../core/app-settings";

// Model for Google Tag Manager Config
export class GtmSettings {
    auth:string;
    id: string;
    preview: string;
  }
declare var document: any;

/**
 * Helper service for google tag manager. 
 */

@Injectable()
export class GTMHelperService {
   private gtmSettings :GtmSettings;

    constructor(private appSettings: AppSettings) {
       this.gtmSettings = appSettings['gtmSettings'];
    }

    /**
     * Initializs the Google tag manager script and inserts it into body tag. Using tag manager environment
     * feature. ID, gtm_auth, gtm_preview values vary from environment to environment.
     */
    public initGTMScript() {
        if(!document) {
            return;
        }     
        try {   
            if(!this.gtmSettings) {
                console.log('setting default config  -INT');
                this.gtmSettings = AppSettings.config['gtmSettings'];
            }

            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://www.googletagmanager.com/gtm.js?id=' + this.gtmSettings.id;
            script.src += "&gtm_auth=" + this.gtmSettings.auth; 
            script.src += "&gtm_preview="+ this.gtmSettings.preview + "&gtm_cookies_win=x";

            document.head.prepend(script);
            console.log('Script loaded');
            
        } catch(e) {
            console.log('Could not insert GTM script');
        }
    }
}
<div class="search-dialog" id="search-dialog" [ngClass]="this.searchHandler === true ? 'main-div' : 'main-div-search-dialog'">
  <div class="close-div" *ngIf="order">
    <i class="material-icons close" (click)="closeDialog($event)">&#xE5CD;</i>
  </div>
  <div class="close-div" *ngIf="!order">
    <i class="material-icons close"></i>
  </div>
  <div class="dialog-main" [ngClass]="appSettings.isNewDomain ? 'rxo-style' : 'xpo-style'">
    <mat-card id="order-search" class="detail-card">
      <div class="main-margin">
        <mat-card-header class="title-card">
          <mat-card-title class="title-size" i18n="@@TrackAnotherOrderModule-SearchComponent-TrackAnotherOrder">Track A Shipment</mat-card-title>
          <mat-card-subtitle class="sub-title-card-small" i18n="@@TrackAnotherOrderModule-SearchComponent-FindOrder">Select an option below.</mat-card-subtitle>
        </mat-card-header>
        <div id="track-tab">
          <app-track-another-order-tab></app-track-another-order-tab>
        </div>
      </div>
    </mat-card>
  </div>
  <app-footer *ngIf="menuService.isMobileDevice()"></app-footer>
</div>

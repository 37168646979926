
import {map} from 'rxjs/operators';
import { Injectable, Inject, LOCALE_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../shared';
import { CookieService } from 'ngx-cookie-service';

// angular-google-maps
import { I18nService } from '../shared/services/i18n.service';
import { XPOConstants } from '../shared/constants/xpo-constants';
import { UnSecureHttp } from '../shared/services/unsecure-http.service';

/**
 * @name app-settings.ts
 * @export
 * @class AppSettings
 * @description This is the AppSettings.
 * @author
 */
@Injectable()
export class AppSettings {
  public static config: any;
  private _messageConfig: any;
  private userPrefLanguage: string;
  private locale: string;

  // For Rebranding
  private renderer2: Renderer2;
  private _domainName: string;
  private readonly _currentDomainName: string = 'xpo';
  private readonly _newDomainName: string = 'rxo';
  private readonly _hostName = window.location.hostname;

  public dataLayer = (<any>window).dataLayer;

  public googleMapStyle: Array<any> = [
    {
      'elementType': 'geometry',
      'stylers':
        [
          { 'saturation': -45 },
          { 'lightness': 40 },
          { 'visibility': 'simplified' },
          { 'weight': 1.5 }
        ]
    },

    {
      'elementType': 'labels',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'administrative.land_parcel',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'administrative.neighborhood',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'road.local',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'road.local',
      'elementType': 'geometry.stroke',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    }
  ];


  constructor(private http: UnSecureHttp, private i18nService: I18nService, @Inject(LOCALE_ID) locale: string, private rendererFactory: RendererFactory2) {
    this.locale = XPOConstants.I18N_FR.includes(locale) ? XPOConstants.I18N_FR : XPOConstants.I18N_EN;
    this.renderer2 = this.rendererFactory.createRenderer(null, null);
    this.setApplicationBasedOnDomainName();
  }

  get domainName(): string {
    return this._domainName;

  }

  init() {
    this.loadMessage();
    const promise = new Promise((resolve, reject) => {
      this.http.get<Config>('./assets/appSettings.json?v=13').pipe(
        map(res => res))
        .toPromise()
        .then(
          config => { // Success
            AppSettings.config = config;
            //setting the API URL from WEB-APP hostname, localhost takes from appsetting.json
            if(window.location.hostname && window.location.hostname.includes('com')){
              AppSettings.config.apiUrl = `https://${window.location.hostname}/trackapi/`.replace('track.','clm.');
            }
            resolve(true);
          },
          error => { // error
            reject(error);
          }
        );
    });
    return promise;
  }

  private setApplicationBasedOnDomainName(): void {
    // Fetching and setting domain Name
    if(this._hostName){
      this._domainName = this._hostName.endsWith(`${this._currentDomainName}.com`) ? this._currentDomainName.toUpperCase() : this._newDomainName.toUpperCase();
      
    // Adding CSS class based on domain
    this.renderer2.addClass(document.body, `${this.domainName.toLowerCase()}-internal-style`);
    }
  }

  get isNewDomain(): boolean {
    return this.domainName === this._newDomainName.toUpperCase();
  }

  public loadMessage() {
    return new Promise((resolve, reject) => {
      const url = './assets/' + this.i18nService.loadI18nJson(this.locale) + '.json?v=13';
      this.http.get(url).pipe(
        map(response => response))
        .toPromise()
        .then(
          data => {
            if (data) {
              this._messageConfig = data;
              resolve(true);
            }
          },
          error => { // error
            reject(error);
          }
        );
    });
  }

  public getMessage(key: any) {
    return this._messageConfig[ key ] !== 'undefined' ? this._messageConfig[ key ] : key;
  }
  //fn to track GTM events
  public eventTrack(evntObj) {
    if(this.dataLayer)
      this.dataLayer.push({
        'event': evntObj.action,
        ...evntObj.properties
      });
  }

}

export function configAppSettings(appSettings: AppSettings) { return () => appSettings.init(); }

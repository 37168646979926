import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { OrderService, Order, GoogleAnalyticsConstants, PhonePipe, XPOConstants } from '../../shared';
import { MatDialog } from '@angular/material/dialog';
import { MenuService } from '../../core/layout/menu.service';
import { AppSettings } from '../../core/app-settings';
import { I18nService } from '../../shared/services/i18n.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TrackOrderProgressComponent } from '../track-order-progress/track-order-progress.component';
import { OrderCategory } from '../../shared/model/order-category.enum';

/**
 * Component responisble for displaying the main components of Tracking landing page.
 *
 */
@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.scss']
})
export class TrackOrderComponent implements OnInit, OnDestroy {
  destinationCoordinates: Array<object>;
  hideStopLocation = false;
  private orderSubscription: Subscription;
  private ordersOfSubscription: Subscription;
  public pageCount = 0;
  public indexSegment = 0;
  public order: Order;
  public orders: Order[];
  public clickedIndex = 0;
  public arrowDisableLeft = false;
  public arrowDisableRight = true;
  public localeFR = false;
  public subHeadingOrder;
  public ordersHolder: any = [];
  public ofText;
  public enableCISegments = false;
  public numberOfStopsAway: string;
  public welcomeDeliveryText: string;
  public showExceptionImg : boolean;
  public canDisplayStops = false;
  private stopsCount: number;
  public subCIpageIndex = 0;
  public displayTimewindowMessage = false;
  @ViewChild('trackOrderProgressComponent', { static: true }) trackOrderProgressComponent: TrackOrderProgressComponent;
  public XPOCustomerCarephoneNumber = '';
  public supportEmail: string;
  public supportEmailSubject: string;
  public trackingLabel: string;

  constructor(
    private orderService: OrderService,
    public dialog: MatDialog,
    public menuService: MenuService,
    public appSettings: AppSettings,
    private i18nService: I18nService,
    private router: Router,
    private phonePipe: PhonePipe
  ) { }

  ngOnInit() {
    this.trackingLabel = this.appSettings.getMessage('TRACKING').replace('[DOMAIN_NAME]', this.appSettings.domainName);

    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.TRACKING_LANDING, label: GoogleAnalyticsConstants.EVENT_CATEGORY.TRACKING_LANDING
      }
    });

    this.localeFR = this.i18nService.getLocale();
    this.orderSubscription = this.orderService.order.subscribe(order => {
      // TODO: Set properly

      if (order) {
        this.order = order;
        this.order.events.sort(function (a, b) {
          return (<any>new Date(b.date) - <any>new Date(a.date));
        });
        this.ofText = this.appSettings.getMessage('OF');
        if (this.menuService.isMultiOrder) {
          this.subHeadingOrder = this.appSettings.getMessage('ORDER');
        } else {
          this.subHeadingOrder = this.appSettings.getMessage('APPOINTMENT');
        }
        this.menuService.isSMSFooterNeeded(this.order.currentStatus);
        this.destinationCoordinates = this.order.consignee ? [{ lat: this.order.consignee.latitude, lng: this.order.consignee.longitude }] : null;

        this.welcomeDeliveryText = this.getWelcomeSubText(
          this.order.currentStatus,
          this.order.billTo,
          this.order.salesOrderCategoryId,
          this.order.etaStatus,
          this.order.eligibleForReschedule && this.order.orderScheduledDate == null
        );
        if (this.order.numberOfStops && (this.order.currentStatus === 'IT' || this.order.currentStatus === 'ONBOARD')) {
          this.stopsCount = Number(this.order.numberOfStops);
          if (this.stopsCount >= 0) {
            this.canDisplayStops = true;
            this.numberOfStopsAway = this.getStopsAway(this.stopsCount);
          } else {
            this.canDisplayStops = false;
          }
        } else {
          this.canDisplayStops = false;
        }
        this.XPOCustomerCarephoneNumber = this.phonePipe.transform(this.order.xpoPhoneNumber, 0);
      }
      this.supportEmail = this.appSettings.domainName === 'XPO' ? XPOConstants.SUPPORT_EMAIL_XPO : XPOConstants.SUPPORT_EMAIL_RXO;
      this.supportEmailSubject =
        this.appSettings.getMessage('SUPPORT_EMAIL_SUBJECT').replace('[DOMAIN_NAME]', this.appSettings.domainName) + this.order.salesOrderId;
    });

    this.ordersOfSubscription = this.orderService.orders.subscribe(orders => {
      if (orders) {
        if (this.menuService.segmentRefreshNeeded) {
          this.indexSegment = 0;

          //this.menuService.multiOrderCIpageIndex = 0;//XLMHL-60179 Commented for
          this.arrowDisableLeft = false;
          this.arrowDisableRight = false;
        }
        if (orders.length > 1) {
          this.pageCount = orders.length;
          this.ordersHolder = orders[0];
          this.orders = orders;
        } else {
          this.menuService.isMultiOrder = false;
          this.ordersHolder = orders[0];
          this.pageCount = this.ordersHolder.length;
          this.orders = this.ordersHolder;
        }
      }
      if (this.pageCount > 1) {
        // debugger;
        this.arrowDisableRight = false;
        this.enableCISegments = true;
        this.DisplayDefaultOrder();
      } else {
        this.enableCISegments = false;
      }
      if (this.indexSegment === this.pageCount - 1) {
        this.arrowDisableRight = true;
      }
    });
  }

  get isDeliveryGuidelinesEnabled() {
    return (
      !!this.order?.deliveryGuidelineUrl?.length &&
      ![TrackOrderProgressComponent.STATUS_DELIVERED, TrackOrderProgressComponent.STATUS_CANCELLED].includes(this.order?.currentStatus)
    );
  }

  get deliveryGuidelinesUrlText() {
    switch (this.order.salesOrderCategoryId) {
      case OrderCategory.Delivery:
        return this.appSettings.getMessage('DELIVERY_GUIDELINE_TEXT_DELIVERY');
      case OrderCategory.ServiceOnly:
        return this.appSettings.getMessage('DELIVERY_GUIDELINE_TEXT_SERVICE');
      case OrderCategory.Return:
        return this.appSettings.getMessage('DELIVERY_GUIDELINE_TEXT_RETURN');
    }
  }

  openDeliveryUrl() {
    window.open(this.order.deliveryGuidelineUrl, '_blank');
  }

  private DisplayDefaultOrder() {
    if (!this.menuService.isMultiOrder) {
      for (const value of this.orders) {
        {
          if (
            value.exceptions != null &&
            value.exceptions.find(ex => ex.exceptionTypeId === 1 && ex.exceptionCode.toUpperCase() === 'CANT-PERFORM-STOP')
          ) {
            const clicks = this.orders.findIndex(x => x.mergedFromWoId.includes(value.workOrderId));
            for (let i = 1; i <= clicks; i++) {
              this.onRightClick();
            }
          }
          break;
        }
      }
    }
  }

  public onPageChange(page) {
    this.menuService.paramTrigger = false;
    if (this.menuService.isMultiOrder) {
      this.orderService.setOrder(this.orders[page][0]);
      if (this.menuService.multiOrderCIpageIndex != 0) {
        this.orderService.setOrder(this.orders[page][this.menuService.multiOrderCIpageIndex]);
      }
    } else {
      this.orderService.setOrder(this.orders[page]);
    }
  }

  public showOnly(markerType) {
    this.hideStopLocation = markerType !== this.appSettings.getMessage('ONTIME');
  }

  private getTemplateHtml() {
    switch (this.clickedIndex) {
      case 0:
        return '';

      case 1:
        return '';
    }
  }
  public getStatusName(shortCode) {
    if (shortCode === 'OP') {
      return this.appSettings.getMessage('ORDER_PLACED');
    } else if (shortCode === 'IT') {
      return this.appSettings.getMessage('IN_TRANSIT');
    } else if (shortCode === 'SH') {
      return this.appSettings.getMessage('SHIPPED');
    } else if (shortCode === 'DL') {
      return this.appSettings.getMessage('DELIVERED');
    } else if (shortCode === 'CN') {
      return this.appSettings.getMessage('CANCELLED');
    }
    return shortCode;
  }

  private rightArrowClick() {
    if (this.indexSegment >= 1) {
      this.arrowDisableLeft = true;
    }
    if (this.indexSegment === this.pageCount - 1) {
      this.arrowDisableRight = true;
    }
  }

  private leftArrowClick() {
    if (this.indexSegment === 1) {
      this.arrowDisableLeft = false;
      this.arrowDisableRight = false;
    } else {
      this.arrowDisableLeft = true;
      this.arrowDisableRight = false;
    }
  }
  public onRightClick() {
    if (this.indexSegment < this.pageCount - 1) {
      this.indexSegment++;
      this.menuService.ismapRefreshNeeded = true;
      this.menuService.pageIndex = this.indexSegment;
      this.onPageChange(this.indexSegment);
      if (!this.menuService.isMultiOrder) {
        this.menuService.multiOrderCIpageIndex = 0;
      }
      this.menuService.onNavigateCloseDailog(this.order.orderId);
      this.rightArrowClick();
    }
  }

  public onLeftClick() {
    if (this.indexSegment > 0) {
      this.leftArrowClick();
      this.indexSegment--;
      this.menuService.ismapRefreshNeeded = true;
      this.menuService.pageIndex = this.indexSegment;
      this.onPageChange(this.indexSegment);
      if (!this.menuService.isMultiOrder) {
        this.menuService.multiOrderCIpageIndex = 0;
      }
      this.menuService.onNavigateCloseDailog(this.order.orderId);
    }
  }

  public getStopsAway(stops) {
    if (stops > 1) {
      return stops + ' ' + this.appSettings.getMessage('STOPS_AWAY');
    } else if (stops === 1) {
      return stops + ' ' + this.appSettings.getMessage('STOP_AWAY');
    } else if (stops === 0) {
      return this.appSettings.getMessage('YOUR_ARE_THE_NEXT_STOP');
    } else {
      return '';
    }
  }

  public getWelcomeSubText(orderStatus, billTo, orderCategoryId, etaStatus,reScheduleEligible) {
    let deliveryText = '';
    const isAllItemRefused =
      this.order?.items?.length > 0 &&
      this.order.items.filter(
        it => it.exceptions != null && it.exceptions.find(ex => ex.exceptionTypeId === 2 && ex.exceptionCode.toUpperCase() === 'CANT-PU/DEL-ITEM')
      ).length === this.order.items.length;

    if (
      (this.order.exceptions != null || isAllItemRefused) &&
      (this.order.salesOrderCategoryId === 1 || this.order.salesOrderCategoryId === 2 || this.order.salesOrderCategoryId == 4)
    ) {
      const stopLevelExceptionIndex = this.order.exceptions
        ? this.order.exceptions.find(ex => ex.exceptionTypeId === 1 && ex.exceptionCode.toUpperCase() === 'CANT-PERFORM-STOP')
        : 0;

      if (stopLevelExceptionIndex && this.order.currentStatus == 'DL') {
        this.showExceptionImg = true;
        const stopException = this.order.exceptions.find(ex => ex.exceptionTypeId === 1 && ex.exceptionCode.toUpperCase() === 'CANT-PERFORM-STOP');
        return `${stopException?.exceptionDescription} - 
        ${stopException?.exceptionReasonDescription} : ${stopException?.exceptionWorkFlowDescription}`;
      } else if (isAllItemRefused && this.order.currentStatus == 'DL') {
        this.showExceptionImg = false;
        return deliveryText;
      } else {
        this.showExceptionImg = false;
      }
    } else {
      this.showExceptionImg = false;
    }
    if (billTo && billTo.name && orderStatus !== 'IS' && orderStatus !== 'EXP' && orderStatus !== 'PS' && orderStatus !== 'RPS') {
      switch (orderCategoryId) {
        case 2:
          deliveryText = `${this.appSettings.getMessage('YOUR_PICKUP_FROM')} ${billTo.name}`;
          break;
        case 4:
          deliveryText = `${this.appSettings.getMessage('YOUR_SERVICE_FROM')} ${billTo.name}`;
          break;
        default:
          deliveryText = `${this.appSettings.getMessage('YOUR_DELIVERY_FROM')} ${billTo.name}`;
          break;
      }

      if (orderCategoryId == 8) {
        deliveryText = this.appSettings.getMessage('YOUR_ORDER_FROM') + '' + billTo.name;
        if (orderStatus == 'OP') {
          return deliveryText + ' ' + this.appSettings.getMessage('HAS_BEEN_PLACED');
        } else if (orderStatus == 'SH') {
          return deliveryText + ' ' + this.appSettings.getMessage('IS_READY_FOR_PICKUP');
        } else if (orderStatus == 'DL') {
          deliveryText = this.appSettings.getMessage('YOUR_PICKUP_FROM') + ' ' + billTo.name;
          return deliveryText + ' ' + this.appSettings.getMessage('YOUR_PICKUP_COMPLETED');
        }
      }

      if (etaStatus === 'RUL') {
        return this.appSettings.getMessage('YOUR_ORDER_FROM') + ' ' + billTo.name + ' ' + this.appSettings.getMessage('YOUR_ORDER_DELAY');
      }

      if (orderCategoryId === 2 && (orderStatus === 'IT' || orderStatus === 'DL')) {
        return deliveryText + ' ' + this.appSettings.getMessage('YOUR_PICKUP_COMPLETED');
      }
      if (orderCategoryId === 4 && orderStatus === 'DL') {
        return deliveryText + ' ' + this.appSettings.getMessage('YOUR_PICKUP_COMPLETED');
      }
      if (orderCategoryId === 2 && orderStatus === 'OP'&& !reScheduleEligible) {
        return deliveryText + ' ' + this.appSettings.getMessage('IS_SCHEDULED');
      } else if (orderCategoryId === 2 && orderStatus === 'OP' && reScheduleEligible) {
        return deliveryText + ' ' + this.appSettings.getMessage('IS_SCHEDULABLE');
      } else if (orderStatus === 'DL') {
        return deliveryText + this.appSettings.getMessage('IS_DELIVERED');
      } else if (orderStatus === 'CN') {
        return deliveryText + this.appSettings.getMessage('IS_CANCELLED');
      } else {
        return deliveryText + this.appSettings.getMessage('IS_ON_ITS_WAY');
      }
    }
    return '';
  }

  public multiOrderNavigateRight() {
    this.trackOrderProgressComponent.onRightClick(false);
  }

  public multiOrderNavigateLeft() {
    this.trackOrderProgressComponent.onLeftClick(false);
  }

  public openCallDialog() {
    window.location.href = 'tel:' + this.order.xpoPhoneNumber;
  }

  ngOnDestroy() {
    this.orderSubscription.unsubscribe();
    this.ordersOfSubscription.unsubscribe();
  }

  getHeight() {
    const currentHeight = this.menuService.productDetailsViewHeight?.height || '0px';
    const calculatedHeight = this.isDeliveryGuidelinesEnabled ? Number(currentHeight.replace('px', '')) - 60 + 'px' : currentHeight;
    return calculatedHeight;
  }
}

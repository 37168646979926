import { Injectable, HostListener } from '@angular/core';
import { XPOConstants } from '../../shared';

@Injectable()
export class SharedService {
    private inHours;
    private inMinutues;
    private time;
    constructor() { }
    public isMobileDevice(): boolean {
        if (window.innerWidth <= 1024) {
            return true;
        }
        return false;
    }
    public adjustTimezone(dates: string) {        
        const DateSplitter = dates.split(XPOConstants.RESCHEDULE_DATE_SPLIT_REGEX);
        const Year = DateSplitter[ 0 ];
        const Month = DateSplitter[ 1 ];
        const rescheduleDate = DateSplitter[ 2 ];
        var calculatedMonth: any = Number(Month) - Number(1);
        const availableDate = new Date(Number(Year),calculatedMonth,Number(rescheduleDate));        
        return availableDate;
    }

    public convertToTime(timeString) {
        if (timeString.length === 3) {
            this.inHours = +timeString.substr(0, 1);
            this.inMinutues = timeString.substr(1, 3);
        } else {
            this.inHours = +timeString.substr(0, 2);
            this.inMinutues = timeString.substr(2, 3);
        }
        this.time = this.inHours % 12 || 12;
        if (this.time <= 9) {
            this.time = '0' + this.time;
        }
        const meridian = (this.inHours < 12 || this.inHours === 24) ? ' AM ' : ' PM ';
        return this.time + ':' + this.inMinutues + meridian;
    }

}

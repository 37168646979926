
import {throwError as observableThrowError,  Observable } from 'rxjs';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { tap, timeout } from 'rxjs/operators';
import { ErrorHandlerService } from '../../../shared/services/errorHandler.service';
import { Injectable } from "@angular/core";

@Injectable()
export class TimeOutInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
      timeout(3000000),
      tap(event => { }, err => { // timeout of 30000 ms
        if (err instanceof HttpErrorResponse) {
          observableThrowError(err);
        }
      })
      );

  }
}


<form novalidate>
  <mat-card-content>
    <div class="content-card top-space">
      <mat-form-field class="search-full-width" id="delNum">
        <input type="tel" matInput placeholder="Phone Number" i18n-placeholder="@@TrackAnotherOrderModule-SearchComponent-Placeholder-DeliveryPhoneNumber"
          tabindex="-1" showMaskTyped="true" mask={{phoneNumberMask}} [(ngModel)]="deliveryPhoneNum" [formControl]="deliveryFormControl" name="delivery"
          required>
        <mat-hint>(XXX) XXX-XXXX</mat-hint>
        <mat-error *ngIf=" deliveryFormControl.hasError('pattern')" i18n="@@TrackAnotherOrderModule-SearchComponent-EnterValidPhoneNumber">
          Please enter a valid Phone Number
        </mat-error>
        <mat-error *ngIf=" deliveryFormControl.hasError('required')">
          <span i18n="@@TrackAnotherOrderModule-SearchComponent-DeliveryPhoneNumberIs">Delivery Phone Number is </span>
          <strong i18n="@@TrackAnotherOrderModule-SearchComponent-Required">required</strong>
        </mat-error>
      </mat-form-field>

        <div class="search-full-width top-space">
          <app-calendar [showIcon]="true" [inline]="openCalendarWidget" tabindex="-1" placeholder="Delivery Date *" i18n-placeholder="@@TrackAnotherOrderModule-SearchComponent-Placeholder-DeliveryDate"
            [showOtherMonths]=false (onSelect)="onSelect($event)" (onMonthChange)="onMonthChange($event)" [value]="deliveryDate"
            [showOnFocus]="iscalendarFocused" dateFormat="mm/dd/yy" readonlyInput="readonlyInput" (click)="onCalendarClick($event)"
            required></app-calendar>
        </div>

      <div class="align-btn">
        <button mat-raised-button class="search-btn-black top-space" tabindex="-1" [ngClass]="{'border-btn':isDeliveryDateFormValid()}"
          [disabled]="!isDeliveryDateFormValid()" id="deliveryDateSearch" (click)="searchByDeliveryDate($event)" i18n="@@TrackAnotherOrderModule-SearchComponent-Track">TRACK</button>
      </div>
    </div>
  </mat-card-content>
</form>

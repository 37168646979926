import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderService, XPOConstants, GoogleAnalyticsConstants } from 'app/shared';
import { LoaderService } from 'app/loader/loader';
import { MenuService } from 'app/core/layout/menu.service';
import { Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppSettings } from 'app/core/app-settings';

@Component({
  selector: 'app-track-xpo-delivery-number',
  templateUrl: './track-xpo-delivery-number.component.html',
  styleUrls: [ './track-xpo-delivery-number.component.scss' ]
})
export class TrackXpoDeliveryNumberComponent implements OnInit {
  public referencePhoneFormControl;
  public deliveryFormControl;
  public deliveryPhoneNum = '';
  public phoneNumberMask = XPOConstants.PHONE_MASK;
  public openCalendarWidget = false;
  public isSelectedDate = false;
  public iscalendarFocused = false;
  public deliveryDate: Date;

  constructor(
    public dialog: MatDialog,
    private orderService: OrderService,
    private spinner: LoaderService,
    public menuService: MenuService,
    private datePipe: DatePipe,
    private appSettings: AppSettings
  ) {
    this.referencePhoneFormControl = new FormControl('', [ Validators.required, Validators.pattern(XPOConstants.PHONE_REGEX) ]);
    this.deliveryFormControl = new FormControl('', [ Validators.required, Validators.pattern(XPOConstants.PHONE_REGEX) ]);
  }
  ngOnInit() { }

  public onSelect(event) {
    this.isSelectedDate = true;
    this.deliveryDate = event;
  }
  public onCalendarClick(event) {
    this.openCalendarWidget = !this.openCalendarWidget;
    this.iscalendarFocused = false;
    let contactComponent = document.getElementById('contact-support');
    if(this.menuService.isMobileDevice() && this.menuService.isLandingPage && contactComponent) {
      if(this.openCalendarWidget) {
        contactComponent.style.zIndex = '-1';
      }
      else {
        contactComponent.style.zIndex = '10000';
      }
    }
    
  }
  public onMonthChange(event) {
    this.openCalendarWidget = false;
  }
  public isDeliveryDateFormValid() {
    return (this.deliveryDate && this.deliveryFormControl.valid);
  }
  public searchByDeliveryDate(event) {
    this.menuService.isUIClick=false;
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.DELIVERY_DATE_SEARCH,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.SEARCH_ORDER,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.SEARCH_ORDER
      }
    });
    this.dialog.closeAll();
    this.spinner.showLoader();
    if (this.deliveryDate && this.deliveryPhoneNum && this.menuService.isNumberValid(this.deliveryPhoneNum, XPOConstants.PHONE_REGEX)) {
      this.menuService.ismapRefreshNeeded = true;
      this.menuService.segmentRefreshNeeded = true;
      this.menuService.pageIndex = 0;
      this.menuService.multiOrderCIpageIndex = 0;
      this.menuService.needToUpdateCache = true;
      this.menuService.isFromSearch = true;
      const dateToString = this.deliveryDate.toString();
      const expectedDateFormat = this.datePipe.transform(this.deliveryDate, 'yyyy-MM-dd');
      const deliveryDateToString = expectedDateFormat.toString();
      this.orderService.searchByDeliveryDate(deliveryDateToString, this.deliveryPhoneNum.replace(/[\(\)\-\s]+/g, ''))
        .subscribe(() => {
          this.spinner.hideLoader();
          this.menuService.closeDialog(event);
        });
    }
    if (event) { event.preventDefault(); }
  }

}

import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NO_ERRORS_SCHEMA } from '@angular/core';

// Core Lib Components
import { AppSettings, configAppSettings } from './app-settings';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { LayoutComponent } from './layout/layout.component';
import { SharedModule } from '../shared/shared.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MenuService } from '../core/layout/menu.service';
import { TrackOrderModule } from '../track-order/track-order.module';
import { ScrollToTopModule } from '../scroll-to-top/scroll-to-top';
import { ErrorHandlerService } from '../shared';
import { CookieService } from 'ngx-cookie-service';
import { SearchContainerComponent } from '../track-another-order/search-container/search-container.component';
import { UnSecureHttp ,} from '../shared/services/unsecure-http.service';
import { UnSecureHttpFactory} from '../shared/services/custom-http.service';

import { HttpBackend } from '@angular/common/http';
import { GTMHelperService } from '../shared/services/gtm-helper.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        MatSidenavModule,
        TrackOrderModule,
        ScrollToTopModule
    ],
    exports: [
        LayoutComponent
    ],
    declarations: [
        LayoutComponent
    ],
    schemas: [NO_ERRORS_SCHEMA],
    providers: [
        AppSettings,
        CookieService,
        MenuService,
        ErrorHandlerService,
        { provide: APP_INITIALIZER, useFactory: configAppSettings, deps: [AppSettings], multi: true },
        {
            provide: UnSecureHttp,
            useFactory: UnSecureHttpFactory,
            deps: [HttpBackend]
        },
        GTMHelperService
    ]
})

export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

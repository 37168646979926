import { Injectable } from '@angular/core';

@Injectable()
export class ChatService {

  get icPatronChat(): any {
    return (window as any).icPatronChat;
  }

  constructor() { }

  init(salesOrderId?:string) {
    // reset the Chat script if order api is called again
    if( (window as any).icPatronChat) {
        (window as any).icPatronChat = undefined;
    }

    let iCchat = document.createElement('script');
    iCchat.src = 'https://home-c9.incontact.com/inContact/ChatClient/js/embed.min.js';
    iCchat.type = 'text/javascript';
    iCchat.id = 'chat';
    iCchat.onload = () => {
      this.setChatOptions(salesOrderId);
    };
    document.body.appendChild(iCchat);
  }

  setChatOptions(salesOrderId?: string) {
    this.icPatronChat.init(this.getChatOptions({params: [salesOrderId]}));
  }

  private getChatOptions(options?) {
    return Object.assign(
      {serverHost: 'https://home-c9.incontact.com',
      bus_no: 4593852,
      poc: '1aee9a17-1e3a-4592-9adc-64b19d2cdae8',
      }, options
    );
  }
}

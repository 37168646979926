import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

import { Order } from '../../../shared/model/order';
import { OrderService } from '../../../shared/services/order.service';
import { I18nService } from '../../../shared/services/i18n.service';
import { Subscription } from 'rxjs';
import { AppSettings } from '../../../core/app-settings';

@Component({
  selector: 'app-tracking-events',
  templateUrl: './tracking-events.component.html',
  styleUrls: ['./tracking-events.component.scss']
})
export class TrackingEventsComponent implements OnInit, OnDestroy {
  public order: Order;
  public localeFR = false;
  private orderSubscription: Subscription;
  public showTrackingEvent: boolean = false;
  public estimatedHubLabel: string;
  constructor(
    private orderService: OrderService, private i18nService: I18nService, private appSettings: AppSettings,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.orderSubscription = this.orderService.order.subscribe(order => {
      if (order) {
        this.order = order;
        this.order.events.sort(function (a, b) {
          return (<any>new Date(b.date) - <any>new Date(a.date));
        });
      }

    });
    this.appSettings.eventTrack({
      action: 'trackingEventsLoad', properties: { category: 'trackingEvents', label: 'trackingEvents' }
    });
    this.localeFR = this.i18nService.getLocale();
    this.estimatedHubLabel = this.appSettings.getMessage('ORDER_ETA');
    this.cdr.detectChanges();
  }

  public displayTrackingEvents() {
    this.showTrackingEvent = !this.showTrackingEvent;
  }

  public get checkETAHeaderStatus(): boolean {
    return this.order.expectedHubReceiptDate && (this.order.currentStatus !== 'RPS' &&
      this.order.currentStatus !== 'SH' && this.order.currentStatus !== 'IT' && this.order.currentStatus !== 'DL');
  }
  public closeDialog() {
    const elem: HTMLElement = document.getElementById('track-events');
    elem.setAttribute('style', 'display:none;');
  }

  ngOnDestroy() {
    this.orderSubscription.unsubscribe();
  }

}

<div class="track-main-div" [ngClass]="{'mozilla-track-div':(menuService.isFirefoxBrowser() && menuService.isMobileDevice())}">
  <div class="child-div"  [ngClass]="appSettings.isNewDomain ? 'rxo-style' : 'xpo-style'">
    <div class="tracking-events-widget" *ngIf="menuService.isMobileDevice()">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title i18n="@@TrackOrderModule-TrackOrderComponent-ContactUs">
            Contact Us
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="contact-content-card">
          <div class="contact-row call-data">
            <img class="icon" src='./assets/icons/icon-call.svg'>
            <a (click)="openCallDialog()" class="contact-text">{{XPOCustomerCarephoneNumber}}</a>
          </div>
          <div class="contact-row">
            <img class="icon" src='./assets/icons/icon-email.svg'>
            <a class="link-text" href="mailto:{{supportEmail}}?subject={{supportEmailSubject}}">{{supportEmail}}</a>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel  *ngIf="order.events.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title i18n="@@TrackOrderModule-TrackOrderComponent-TrackingEvents">
            Tracking Events
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-card">
          <div class="tracking-content-div" *ngFor="let item of order.events; let i=index">
            <!--<div *ngIf="localeFR" class="item-time">{{item.date | datex: 'DD MMM'}} {{item.date |datex:'hh:mm A'}}</div>
            <div *ngIf="!localeFR" class="item-time">{{item.date | datex: 'MMM DD'}} {{item.date |datex:'hh:mm A'}}</div>-->
            <div class="item-time">{{item.date}}</div>
            <div class="tracking-message">
              <div class="item-content" [ngStyle]="item.name == 'DELIVERED' && {'color': '#00c853','font-size': '12px !important','font-weight': bold}">{{getStatusName(item.name)}}</div>
              <span [ngStyle]="(item.city == '' || !item.city == null) ? {'display': 'none'} : {'display': 'block'}" class="item-content">{{item.city}}</span>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <mat-card class="track-order-card">
      <div class="delivery-guidelines-container" *ngIf="isDeliveryGuidelinesEnabled && menuService.isMobileDevice()">
        <span class="delivery-guidelines-text"
          ><u (click)="openDeliveryUrl()">{{ deliveryGuidelinesUrlText }}</u></span
        >
      </div>
      <div class="carousal-div">
        <div class="page-div">
          <div class="nav-button" *ngIf="enableCISegments">
            <i class="material-icons carousel-backward-btn " [ngStyle]="arrowDisableLeft == false ? {'color': 'grey'} : {'color': 'black'}"
              (click)="onLeftClick()">&#xE314;</i>
            <i class="material-icons carousel-forward-btn" [ngStyle]="arrowDisableRight == true ? {'color': 'grey'} : {'color': 'black'}"
              (click)="onRightClick()">&#xE315;</i>
          </div>
          <div class="tracking-number-selected">
            <div>
              <div>
                <b>{{trackingLabel}}</b>
              </div>
              <div>{{order.trackingNumber}}</div>
            </div>
            <div i18n="@@TrackOrderModule-TrackOrderComponent-ReferenceNumber">
              <b>Retailer Tracking # </b>
            </div>
            <div>{{order.referenceId}}</div>
          </div>
          <div class="page-text" *ngIf="enableCISegments">{{subHeadingOrder}} {{(indexSegment + 1)}} {{ofText}} {{pageCount}}</div>
        </div>
        <app-track-order-progress #trackOrderProgressComponent [segmentIndex]="indexSegment"></app-track-order-progress>
      </div>

      <div class="delivery-guidelines-container added-space" *ngIf="isDeliveryGuidelinesEnabled && !menuService.isMobileDevice()">
        <span class="delivery-guidelines-text"
          ><a [href]="order.deliveryGuidelineUrl" target="_blank">{{deliveryGuidelinesUrlText}}</a></span
        >
      </div>
      
      <div [ngStyle]="{'height': getHeight()}" class="product-div">
        <app-product-details [ngClass]="{'app-product-details-width-full': !menuService.isMobileDevice()}" [order]="order" [numberOfStopsAway]="numberOfStopsAway"
          [welcomeDeliveryText]="welcomeDeliveryText" [canDisplayStops]="canDisplayStops" [showExceptionImg]="showExceptionImg"></app-product-details>
      </div>
    </mat-card>
    <div class="footer">
      <app-footer></app-footer>
    </div>
  </div>
</div>

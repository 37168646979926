import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { DomHandler } from '../calendar-datepicker/domhandler';
import { CalendarComponent } from '../calendar-datepicker/calendar.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  exports: [
    CalendarComponent
  ],
  declarations: [ CalendarComponent ],
  providers: [
    DomHandler
  ],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class CalendarDatepickerModule { }

export class Survey {
    constructor(
        public answerId: number,
        public answerDesc: string,
        public answerTypeId: number,
        public answerTypeName: string,
        public options: Option[],
        public questionId: number,
        public questionDesc: string,
        public isSurveyAlreadyTaken: boolean
    ) { }
}

export class Option {
    constructor(
        public answerItemId: number,
        public answerItemValueId: number,
        public answerItemName: string,
    ) { }
}

export class SurveyAnswer {
    constructor(
        public questionId: number,
        public questionDescription: string,
        public answer: string[],
        public answerItemId: number[],
        public answerTypeName: string,
        public other: string,
        public answerDescription: string
    ) { }
}

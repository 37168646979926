<div class="Testing" [ngStyle]="menuService.googleMapStyle" #googleMap></div>

<div id="map-events" *ngIf="showWidget">
  <div class="tracking-traffic-alerts">
    <app-contact-support *ngIf="!this.menuService.isLandingPage"></app-contact-support>
    <app-tracking-events *ngIf="!this.menuService.isLandingPage"></app-tracking-events>
    <div *ngIf="(order.currentStatus === 'IT') && (eligibleToShowTrafficWeatherAlerts) && (validated) && (trafficAlertItems.length > 0 || weatherAlertList.length > 0) && (isToggleChecked)"
      class="traffic-events">
      <mat-card class="detail-card" *ngFor="let trafficItem of (trafficAlertItems ? trafficAlertItems.slice(0,2): [])" angulartics2On="show"
        angularticsEvent="trafficAlerts" angularticsCategory="trafficAlerts" [angularticsProperties]="{label: 'trafficAlerts'}">
        <mat-card-subtitle class="sub-title-card thick" i18n="@@TrackOrderModule-TrackOrderMapComponent-TrafficAlert">
          Traffic Alert
          <i class="material-icons close-event" (click)="onCloseTrafficAlert(trafficItem)">&#xE5CD;</i>
        </mat-card-subtitle>
        <div class="content-card">
          <div class="weather-content-div">
            <img src="./assets/icons/weather-alert-red.svg" width="13px" height="26px" class="red-icon" />
            <div class="weather-message">
              <span class="time">{{trafficItem.lastModified | datex: 'hh:mm A'}}</span>
              <br/>
              <span class="item-content">{{trafficItem.description}}</span>
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card class="detail-card" *ngFor="let weatherItem of (weatherAlertList ? weatherAlertList.slice(0,1): [])">
        <mat-card-subtitle class="sub-title-card thick" i18n="@@TrackOrderModule-TrackOrderMapComponent-WeatherAlert">
          Weather Alert
          <i class="material-icons close-event" (click)="onCloseWeatherAlert(weatherItem)">&#xE5CD;</i>
        </mat-card-subtitle>
        <div class="content-card">
          <div class="weather-content-div">
            <img src="./assets/icons/weather-alert-red.svg" width="13px" height="26px" class="red-icon" />
            <img src="{{weatherItem.icon}}" class="weather-icon" />
            <div class="weather-message">
              <span class="time">{{weatherItem.lastModified | datex: 'hh:mm A'}}</span>
              <br/>
              <span>{{weatherItem.weather}} in </span>
              <br/>
              <span>[{{weatherItem.place}}]</span>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>

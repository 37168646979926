<div class="tracking-footer" id="xpo-footer" *ngIf="order">

    <div class="xpo-customercare-email" *ngIf="!order.smsOptInStatus && menuService.isSMSFooterNeeded(order.currentStatus)">
        <form novalidate class="search-form">
            <div class="inline-form">
                <a class="sms-subscription-row" (click)="onClickAlertSubscription($event, true)">
                    <span class="phone-icon">
                        <mat-icon>notifications_off</mat-icon>
          </span>
                    <span class="footer-signup-text" i18n="@@FooterModule-FooterComponent-TurnOnNotifications">Turn on text delivery notifications.</span>
                </a>
            </div>
        </form>
    </div>

    <div class="xpo-customercare-email" *ngIf="order.smsOptInStatus && menuService.isSMSFooterNeeded(order.currentStatus)">
        <div *ngIf="orderService.flagValue" class="inline-form">
            <a class="sms-subscription-row" (click)="onClickAlertSubscription($event, false)">
                <span class="phone-icon">
                    <mat-icon>notifications_active</mat-icon> 
        </span>
                <span class="footer-signup-text" i18n="@@FooterModule-FooterComponent-TurnOffNotifications">Turn off text delivery notifications.</span>
            </a>
        </div>
    </div>
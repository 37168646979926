import { Component, OnInit, ElementRef, NgZone } from '@angular/core';
import { ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarEvent } from 'angular-calendar';
import {
  setHours, setMinutes, addHours,
  startOfDay, endOfDay, subDays,
  addDays, endOfMonth, isSameDay, isSameMonth
} from 'date-fns';
import { colors } from '../calendar-utils/colors';
import { Subscription } from 'rxjs';
import {
  Order, OrderService, AddToCalendarService,
  GoogleCalendarEvent,
  DatexPipe, XPOConstants, GoogleAnalyticsConstants
} from '../shared';
import { MenuService } from '../core/layout/menu.service';
import { DatePipe } from '@angular/common';
import { LoaderService } from '../loader/loader';
import * as moment from 'moment';
import { GoogleLoginProvider } from '../calendar/providers/google-login-provider';
import { OutlookLoginProvider } from '../calendar/providers/outlook-login-provider';
import { AuthService } from '../calendar/services/auth.service';
import { AppSettings } from '../core/app-settings';
import { I18nService } from '../shared/services/i18n.service';
import { SharedService } from '../shared/services/shared.service';

@Component({
  selector: 'app-add-to-calendar',
  templateUrl: './add-to-calendar.component.html',
  styleUrls: [ './add-to-calendar.component.scss' ],
  providers: [ DatePipe, DatexPipe ]
})
export class AddToCalendarComponent implements OnInit, OnDestroy {
  public readonly GOOGLE_PROVIDER_ID = GoogleLoginProvider.PROVIDER_ID;
  public readonly OUTLOOK_PROVIDER_ID = OutlookLoginProvider.PROVIDER_ID;
  private orderSubscription: Subscription;
  public orders: Order[];
  public orderItemCollection: Order[] = [];
  public isShowUsersCalendar = false;
  public selectedCalendarProviderId: string;
  public isCalendarSelected = false;
  public isTermsAndConditionsAccepted = false;
  public isCalendarAdded = false;
  public calendarBusyEvents: any[];
  public orderScheduledStartTime: number;
  public orderScheduledEndTime: number;
  public itemDescription: string;
  public view = 'day';
  public viewDate: Date;
  public locale: string;
  public calendarEvents: CalendarEvent[];
  private calendarAddEvents: CalendarEvent[];
  public headerTextAddTocalendar = '';
  public startTime: number;
  public endTime: number;
  public privacyUrl: string;
  public conditionUrl: string;
  public busyCalendarEvents: any[];
  public freeCalendarEvents: any[];
  private ordersHolder: any = [];
  public confirmationAddToCalendar;
  public addToCalendarConditionText: string;


  constructor(
    private addToCalendarService: AddToCalendarService,
    private orderService: OrderService,
    public menuService: MenuService,
    private sharedService: SharedService,
    private i18nService: I18nService,
    private router: Router,
    private element: ElementRef,
    private spinner: LoaderService,
    private datePipe: DatePipe,
    private datexPipe: DatexPipe,
    private ngZone: NgZone,
    public appSettings: AppSettings,
    private authService: AuthService
  ) { }
  ngOnInit() {
    this.privacyUrl = this.appSettings.isNewDomain ? XPOConstants.PRIVACY_URL_RXO : XPOConstants.PRIVACY_URL;
    this.conditionUrl = this.appSettings.isNewDomain ? XPOConstants.PRIVACY_URL_RXO : XPOConstants.PRIVACY_URL;
    this.addToCalendarConditionText = this.appSettings.domainName === "XPO" ? this.appSettings
      .getMessage('AGREE_CONDITION_TEXT_XPO').replace('[DOMAIN_NAME]', this.appSettings.domainName) : this.appSettings
        .getMessage('AGREE_CONDITION_TEXT_RXO').replace('[DOMAIN_NAME]', this.appSettings.domainName);

    this.spinner.hideLoader();
    this.headerTextAddTocalendar = this.appSettings.getMessage('PLEASE_SELECT_A_CALENDAR');
    this.orderSubscription = this.orderService.orders.subscribe(orders => {
      if (orders) {
        if (this.menuService.isMultiOrder) {
          this.ordersHolder = orders[ this.menuService.pageIndex ];
        } else {
          this.ordersHolder = orders[ 0 ];
        }
        this.orders = this.ordersHolder;
      }
    });
    this.calendarEvents = [];
    this.calendarAddEvents = [];
    this.busyCalendarEvents = [];
    this.freeCalendarEvents = [];
    this.orders.forEach(order => {
      if (order.deliveryServiceWindowStartTime && order.deliveryServiceWindowEndTime && order.canAddToCalendar) {
        this.orderItemCollection.push(order);
      }
    });
  }

  public onCalendarOptionSelected(selectedCalendarValue) {
    this.selectedCalendarProviderId = selectedCalendarValue;
    this.isCalendarSelected = true;
  }
  public openUrl(urlToShow: string) {
    if (urlToShow) { window.open(urlToShow, '_blank'); }
  }
  public onTermsAndConditionsAccepted(event) {
    this.isTermsAndConditionsAccepted = event.checked;
  }

  public onSubmitCalendarRequest(event) {
    this.spinner.showLoader();
    this.authorizeAccount(event, this.selectedCalendarProviderId);
    if (event) { event.preventDefault(); }
  }

  private authorizeAccount(event, providerId) {
    this.authService.openSession(providerId).then((resolve) => {
      this.loadCalendarData(event, providerId);
    }, (reject) => {
      this.hideLoader(event);
    }).catch((error) => {
      this.hideLoader(event);
    });
  }

  private loadCalendarData(event, providerId) {
    this.authService.getCalendarFreeBusyData(providerId, this.orders).then((resolve) => {
      if (this.selectedCalendarProviderId === this.GOOGLE_PROVIDER_ID) {
        this.readGoogleFreeBusyData(resolve, event);
      } else if (this.selectedCalendarProviderId === this.OUTLOOK_PROVIDER_ID) {
        this.readOutlookFreeBusyData(resolve, event);
      }
    }, (reject) => {
      this.hideLoader(event);
    }).catch((error) => {
      this.hideLoader(event);
    });
  }

  private readOutlookFreeBusyData(response, event) {
    const startTimeArrray = [];
    const endTimeArray = [];
    response.meetingTimeSuggestions.sort((meetingTimeSuggestion1, meetingTimeSuggestion2) => {
      if (meetingTimeSuggestion1.meetingTimeSlot.start.dateTime < meetingTimeSuggestion2.meetingTimeSlot.start.dateTime) {
        return -1;
      } else if (meetingTimeSuggestion1.meetingTimeSlot.start.dateTime > meetingTimeSuggestion2.meetingTimeSlot.start.dateTime) {
        return 1;
      } else {
        return 0;
      }
    });

    response.meetingTimeSuggestions.forEach((meetingTimeSuggestion) => {
      startTimeArrray.push(meetingTimeSuggestion.meetingTimeSlot.start.dateTime);
      endTimeArray.push(meetingTimeSuggestion.meetingTimeSlot.end.dateTime);
    });
    const busyData = [];
    for (let i = 0; i < startTimeArrray.length; i++) {
      if ((i !== startTimeArrray.length - 1) &&
        (endTimeArray[ i ] !== startTimeArrray[ i + 1 ])) {
        const momentStart = moment.utc(endTimeArray[ i ]);
        const start = moment(momentStart).local();
        const momentEnd = moment.utc(startTimeArrray[ i + 1 ]);
        const end = moment(momentEnd).local();
        busyData.push({
          'start': start.toISOString(),
          'end': end.toISOString()
        });
      }
    }
    this.calendarBusyEvents = busyData;
    this.setUpUsersCalendar();
    this.hideLoader(event);
  }

  private readGoogleFreeBusyData(response, event) {
    const responseData = response.result.calendars[ 'primary' ];
    if (responseData) {
      this.calendarBusyEvents = responseData.busy;
      this.setUpUsersCalendar();
    }
    this.hideLoader(event);
  }

  private setUpUsersCalendar() {
    this.isShowUsersCalendar = true;
    this.populateOrderEventsDataForCalendar();
    this.populateBusyEventsDataForCalendar();
  }

  private hideLoader(event) {
    this.ngZone.run(() => {
      if (event) {
        event.preventDefault();
      }
      this.spinner.hideLoader();
    });
  }

  private populateOrderEventsDataForCalendar() {
    for (const order of this.orderItemCollection) {
      if (order && order.events) {
        const viewCalDate = this.sharedService.adjustTimezone(order.deliveryServiceWindowStartTime);
        this.viewDate = viewCalDate;
        this.locale = this.i18nService.localeID;
        const startTime = this.timeToDecimal(this.datexPipe.transform(order.deliveryServiceWindowStartTime, 'H:mm A'));
        const endTime = this.timeToDecimal(this.datexPipe.transform(order.deliveryServiceWindowEndTime, 'H:mm A'));
        const myEvent: CalendarEvent = {
          title: `${order.billTo.name} ${this.appSettings.getMessage('APPOINTMENT_XPO_ORDER_ID').replace('[DOMAIN_NAME]', this.appSettings.domainName)} ${order.trackingNumber}`,
          start: addHours(startOfDay(viewCalDate), startTime),
          end: addHours(startOfDay(viewCalDate), endTime),
          color: colors.blue
        };
        if (!this.isEventExists(myEvent.start, myEvent.end, this.freeCalendarEvents)) {
          this.freeCalendarEvents.push(myEvent);
          this.calendarEvents.push(myEvent);
        }
        this.calendarAddEvents.push(myEvent);
      }
    }
  }

  private populateBusyEventsDataForCalendar() {
    if (this.calendarBusyEvents) {
      for (let i = 0; i < this.calendarBusyEvents.length; i++) {
        const startDate = this.calendarBusyEvents[ i ].start;
        const endDate = this.calendarBusyEvents[ i ].end;
        const startTime = this.timeToDecimal(this.datexPipe.transform(startDate, 'H:mm A'));
        const endTime = this.timeToDecimal(this.datexPipe.transform(endDate, 'H:mm A'));
        const googleEvent = this.appSettings.getMessage('BUSY');
        const viewCalDate = this.sharedService.adjustTimezone(this.calendarBusyEvents[ i ].start);
        const myGoogleEvent: CalendarEvent = {
          title: googleEvent,
          start: addHours(startOfDay(viewCalDate), startTime),
          end: addHours(startOfDay(viewCalDate), endTime),
          color: colors.red
        };
        if (!this.isEventExists(myGoogleEvent.start, myGoogleEvent.end, this.busyCalendarEvents)) {
          this.busyCalendarEvents.push(myGoogleEvent);
          this.calendarEvents.push(myGoogleEvent);
        }
      }
    }
  }
  private isEventExists(startDate, endDate, eventsArray): boolean {
    if (eventsArray) {
      for (const event of eventsArray) {
        if (event.start.getTime() === startDate.getTime() && event.end.getTime() === endDate.getTime()) {
          return true;
        }
      }
    }
    return false;
  }

  public openRescheduleDialog(event) {
    this.closeDialog(event);
    this.router.navigate([ 'reschedule' ], { queryParams: { orderid: this.orders[ 0 ].orderId } });
    this.hideProductDetailsIfMobile(false);
  }
  public closeDialog(event) {
    this.menuService.hideMenu();
    if (event) { event.preventDefault(); }
  }
  private hideProductDetailsIfMobile(status: boolean) {
    if (this.menuService.isMobileDevice()) {
      this.menuService.isHomeSelected = status;
      this.menuService.segmentRefreshNeeded = false;
      this.menuService.ismapRefreshNeeded = false;
    }
  }

  public addToCalendar(event) {
    this.spinner.showLoader();
    let index = 0;
    let calendarAddEventObjects = [];
    if (this.selectedCalendarProviderId === this.GOOGLE_PROVIDER_ID) {
      calendarAddEventObjects = this.createEventObjectForGoogleCalendarInsertion();
    } else if (this.selectedCalendarProviderId === this.OUTLOOK_PROVIDER_ID) {
      calendarAddEventObjects = this.createEventObjectForOutlookCalendarInsertion();
    }

    calendarAddEventObjects.forEach((calendarAddEventObject) => {
      this.authService.addEventToCalendar(this.selectedCalendarProviderId, calendarAddEventObject).then((resolve) => {
        index++;
        if (index === calendarAddEventObjects.length) {
          this.confirmationAddToCalendar = this.appSettings.getMessage('EVENT_ADDED');
          this.headerTextAddTocalendar = this.appSettings.getMessage('THANK_YOU');
          this.isCalendarAdded = true;
          this.hideLoader(event);
        }
      }, (reject) => {
        this.hideLoader(event);
      }).catch((error) => {
        this.hideLoader(event);
      });
    });
  }

  private createEventObjectForGoogleCalendarInsertion() {
    const googleCalendarAddEventObjects = [];
    this.calendarAddEvents.forEach(googleCalendarAddEvent => {
      const googleCalendarEvent = {
        summary: googleCalendarAddEvent.title,
        start: {
          dateTime: googleCalendarAddEvent.start.toISOString()
        },
        end: {
          dateTime: googleCalendarAddEvent.end.toISOString()
        },
        reminders: {
          useDefault: false,
          overrides: [
            { method: 'email', 'minutes': 24 * 60 },
            { method: 'popup', 'minutes': 10 }
          ]
        }
      };
      googleCalendarAddEventObjects.push(googleCalendarEvent);
    });
    return googleCalendarAddEventObjects;
  }

  private createEventObjectForOutlookCalendarInsertion() {
    const outlookCalendarAddEventObjects = [];
    this.calendarAddEvents.forEach(outlookCalendarAddEvent => {
      const outlookCalendarEvent = {
        'Subject': outlookCalendarAddEvent.title,
        'Start': {
          'DateTime': outlookCalendarAddEvent.start.toISOString(),
          'TimeZone': 'Pacific Standard Time'
        },
        'End': {
          'DateTime': outlookCalendarAddEvent.end.toISOString(),
          'TimeZone': 'Pacific Standard Time'
        }
      };
      outlookCalendarAddEventObjects.push(outlookCalendarEvent);
    });

    return outlookCalendarAddEventObjects;
  }


  public downloadCalendar() {
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.DOWNLOAD,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.DOWNLOAD_XPO_EVENT,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.DOWNLOAD_XPO_EVENT
      }
    });
    if (this.orderItemCollection.length > 0) {

      this.addToCalendarService.addToCalendar(this.orderItemCollection, 'add');
    }
  }

  public isOutlookEnabled() {
    if (!AppSettings.config.outlookEnable) {
      return false;
    }
    if (this.menuService.isIEBrowser() && !AppSettings.config.outlookIEOnline) {
      return false;
    }
    return true;
  }
  private timeToDecimal(scheduledTime) {
    scheduledTime = scheduledTime.split(':');
    return parseInt(scheduledTime[ 0 ], 10) * 1 + parseInt(scheduledTime[ 1 ], 10) / 60;
  }
  ngOnDestroy() {
    this.orderSubscription.unsubscribe();
  }
}

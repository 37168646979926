import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { APP_BASE_HREF, Location } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';

// Application Modules
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { CalendarUnifiedModule } from './calendar/calendar-unified.module';
import { LoaderModule } from './loader/loader';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { SetHeaderInterceptor } from './shared/services/interceptors/setHeaderInterceptor';
import { TimeOutInterceptor } from './shared/services/interceptors/timeOutInterceptor';
import { I18nService } from './shared/services/i18n.service';
import { environment } from '../environments/environment';
import { OrderService } from './shared';
import { AuthServiceConfig } from './calendar/services/auth.service';
import { provideConfig } from './add-to-calendar/add-to-calendar.module';
import { AuthenticateService } from './shared/services/authenticate.service';
import { FormsModule } from '@angular/forms';
import { ImageService } from './shared';
import { ChatService } from './shared/services/chat.service';
//import { ContactUsComponent } from './contact-us/contact-us.component';

@NgModule({
    declarations: [
        AppComponent //,
        // ContactUsComponent
    ],
    imports: [
        BrowserModule,
        CoreModule,
        LoaderModule,
        FormsModule,
        BrowserAnimationsModule,
        CalendarUnifiedModule,
        HttpClientModule,
        HttpClientJsonpModule,
        AppRoutingModule
    ],
    providers: [
        I18nService,
        OrderService,
        ImageService,
        AuthenticateService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SetHeaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TimeOutInterceptor,
            multi: true
        },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
        {
            provide: APP_BASE_HREF,
            useValue: environment.base
        },
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
        },
        ChatService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {
  constructor() { }
}

/// <reference types="@types/googlemaps" />
import { Injectable, NgZone } from '@angular/core';
import { XpoGoogleMapLoader } from './google-map-api-loader';
import { XpoGoogleMapConfig } from '../model/google-map-config';
import { OrderService, GoogleAnalyticsConstants } from '../../shared';
import {
  Traffic, TrafficService, TrafficAlertItem,
  Weather, WeatherAlertService, WeatherAlert,
  Location, TruckLocation,
  Resource, Respons
} from '../../shared';
import { AppSettings } from '../../core/app-settings';
import { MenuService } from 'app/core/layout/menu.service';

/**
 * Class responisble for creating map, marker, infowindow using Google Map API
 */
@Injectable()
export class XpoGoogleMapApi {
  private static readonly AERIS_API_URL = 'https://maps.aerisapi.com';
  private static readonly AERIS_OVERLAY_FEATURE = 'radar';
  public orderService: OrderService;
  protected map: any;
  protected RIGHT_TOP: any;
  private trafficWeatherControl: HTMLElement;
  private trafficLayer: any;
  private weatherLayer: any;

  public trafficAlertAPICalled = false;
  public trafficData: Traffic;
  public trafficAlertItems: TrafficAlertItem[] = [];


  public weatherData: Weather;
  public weatherAlertResponses: Respons[] = [];
  public weatherAlertList: WeatherAlert[] = [];
  public truckLocations: TruckLocation[] = [];
  public weatherAlertAPICalled = false;
  private MAX_LIMIT = 25;
  public startIndex = 0;
  public endIndex = 0;
  private filterWeatherAlertsOptions = ['blizzard.png', 'blowingsnow.png', 'drizzle.png', 'drizzlef.png', 'fdrizzle.png',
    'flurries.png', 'flurriesw.png', 'fog.png', 'freezingrain.png', 'freezingrainn.png', 'rain.png', 'rainn.png', 'rainandsnow.png',
    'raintosnow.png', 'rainw.png', 'showers.png', 'showersw.png', 'sleet.png', 'sleetsnow.png', 'snow.png', 'snowshowers.png',
    'snowshowersw.png', 'snowtorain.png', 'snoww.png', 'tstorm.png', 'tstormsw.png', 'tstormw.png', 'wintrymix.png'];

  public isTruckPopUpMobileShowing = false;

  constructor(
    private mapLoader: XpoGoogleMapLoader,
    private trafficService: TrafficService,
    private weatherAlertService: WeatherAlertService,
    private ngZone: NgZone,
    public appSettings: AppSettings,
    public menuService: MenuService,
  ) { }

  // TODO Create an interface that has all the map options
  createMap(htmlElement: HTMLElement, options: any, googleMapApiKey: string, aerisWeatherApiKey: string, localeID: string): Promise<void> {
    return this.mapLoader.loadGoogleMapScript(googleMapApiKey, localeID).then(() => {
      if (!options.styles) {
        options.styles = XpoGoogleMapConfig.MAP_STYLE;
      }
      this.map = new google.maps.Map(htmlElement, options);
      this.RIGHT_TOP = google.maps.ControlPosition.RIGHT_TOP;
      // Adding traffic control only if the options have traffic property as true
      if (options.traffic && !this.menuService.isLandingPage) {
        if (!this.trafficWeatherControl) {
          // Creating the custom control div
          this.createCustomControl();
        }
        // Creating the traffic control
        this.createTrafficControl();
        this.trafficLayer = this.createTrafficLayer();
      }
      // Adding weather control, only if the options have weather property as true
      if (options.weather && !this.menuService.isLandingPage) {
        if (!this.trafficWeatherControl) {
          // Creating the custom control div
          this.createCustomControl();
        }
        // Creating the weather control
        this.createWeatherControl();
        this.weatherLayer = this.createWeatherLayer(aerisWeatherApiKey);
      }
      return;
    });
  }

  // TODO Create an interface that has all the marker options
  createMarker(markerOptions: any, addToMap: boolean): any {
    if (addToMap) {
      markerOptions.map = this.map;
    }
    return new google.maps.Marker(markerOptions);
  }

  // Method responsible to create a google map point
  createPoint(x: number, y: number) {
    return new google.maps.Point(x, y);
  }

  // Method responsible to create a google map size object
  createSize(width: number, height: number) {
    return new google.maps.Size(width, height);
  }

  // Method responsible to find the orientation of the marker based on the origin and destination
  findHeading(position1: any, position2: any) {
    return google.maps.geometry.spherical.computeHeading(position1, position2);
  }

  // Method responsible to set the map bounds based on the markers
  setMapBoundsBasedOnMarkers(markers: any[]) {
    const bounds = new google.maps.LatLngBounds();
    for (const marker of markers) {
      bounds.extend(marker.getPosition());
    }
    this.map.fitBounds(bounds);
  }

  // Method responsible for creating the google maps traffic layer
  createTrafficLayer() {
    return new google.maps.TrafficLayer();
  }

  // Method responsible for creating the aeris radar weather layer
  createWeatherLayer(aerisApiKey: string) {
    return new google.maps.ImageMapType({
      getTileUrl: (coord, zoom) => {
        const weatherApiUrl = `${XpoGoogleMapApi.AERIS_API_URL}/${aerisApiKey}/${XpoGoogleMapApi.AERIS_OVERLAY_FEATURE}/`;
        return [weatherApiUrl, zoom, '/', coord.x, '/', coord.y, '/current.png'].join('');
      },
      tileSize: this.createSize(256, 256)
    });
  }

  // Method responsible for creating the custom control div for traffic and weather
  private createCustomControl() {
    this.trafficWeatherControl = document.createElement('div');
    this.trafficWeatherControl.style.marginRight = '55px'; // Custom Alignment to match the wireframe
    this.trafficWeatherControl.style.marginBottom = '-65px'; // Custom Alignment to match the wireframe
    this.trafficWeatherControl.style.padding = '5px 10px';
    this.trafficWeatherControl.style.border = '1px solid #C3C1BE';
    this.trafficWeatherControl.style.backgroundColor = XpoGoogleMapConfig.CONTROL_BACKGROUND_COLOR;

    // Positioning the custom control in the map
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(this.trafficWeatherControl);
  }

  // Method responsible for creating the custom control div for traffic
  private createTrafficControl() {
    const trafficControl = document.createElement('div');
    trafficControl.style.cursor = 'pointer';
    trafficControl.style.display = 'inline-block';
    this.trafficWeatherControl.appendChild(trafficControl);
    if (trafficControl.previousSibling) {
      trafficControl.style.marginLeft = '10px';
    }

    // Traffic Control Icon
    const trafficControlIcon = document.createElement('div');
    trafficControlIcon.style.backgroundImage = 'url(assets/icons/traffic-map-disabled.svg)';
    trafficControlIcon.style.width = '14px';
    trafficControlIcon.style.height = '12px';
    trafficControlIcon.style.margin = 'auto';
    trafficControl.appendChild(trafficControlIcon);

    // Traffic Control Text
    const trafficControlText = document.createElement('div');
    trafficControlText.innerHTML = this.appSettings.getMessage('CONTROL_TRAFFIC');
    trafficControlText.style.fontSize = '8px';
    trafficControlText.style.color = XpoGoogleMapConfig.CONTROL_COLOR_DISABLED;
    trafficControlText.style.marginTop = '3px';
    trafficControlText.style.userSelect = 'none';
    trafficControlText.style.webkitUserSelect = 'none';
    trafficControl.appendChild(trafficControlText);

    // Traffic Control click listener
    trafficControl.addEventListener('click', () => {
      this.enableOrDisableTrafficLayer(trafficControlIcon, trafficControlText);
    });
  }

  // Method called when the traffic toggle button is turned on/off
  private enableOrDisableTrafficLayer(trafficControlIcon: HTMLDivElement, trafficControlText: HTMLDivElement) {
    if (this.trafficLayer.getMap()) {
      this.appSettings.eventTrack({
        action: GoogleAnalyticsConstants.EVENT_ACTION.DISABLE,
        properties: {
          category: GoogleAnalyticsConstants.EVENT_CATEGORY.TRAFFIC_LAYER,
          label: GoogleAnalyticsConstants.EVENT_CATEGORY.TRAFFIC_LAYER
        }
      });
      trafficControlIcon.style.backgroundImage = 'url(assets/icons/traffic-map-disabled.svg)';
      trafficControlText.style.color = XpoGoogleMapConfig.CONTROL_COLOR_DISABLED;
      this.trafficLayer.setMap(null);
    } else {
      this.appSettings.eventTrack({
        action: GoogleAnalyticsConstants.EVENT_ACTION.ENABLE,
        properties: {
          category: GoogleAnalyticsConstants.EVENT_CATEGORY.TRAFFIC_LAYER,
          label: GoogleAnalyticsConstants.EVENT_CATEGORY.TRAFFIC_LAYER
        }
      });
      trafficControlIcon.style.backgroundImage = 'url(assets/icons/traffic-map-enabled.svg)';
      trafficControlText.style.color = XpoGoogleMapConfig.CONTROL_COLOR_ENABLED;
      this.trafficLayer.setMap(this.map);
    }
  }

  // Method responsible for creating the custom control div for weather
  private createWeatherControl() {
    const weatherControl = document.createElement('div');
    weatherControl.style.cursor = 'pointer';
    weatherControl.style.display = 'inline-block';
    this.trafficWeatherControl.appendChild(weatherControl);
    if (weatherControl.previousSibling) {
      weatherControl.style.marginLeft = '10px';
    }

    // Weather Control Icon
    const weatherControlIcon = document.createElement('div');
    weatherControlIcon.style.backgroundImage = 'url(assets/icons/weather-map-disabled.svg)';
    weatherControlIcon.style.width = '16px';
    weatherControlIcon.style.height = '14px';
    weatherControlIcon.style.margin = 'auto';
    weatherControl.appendChild(weatherControlIcon);

    // Weather Control Text
    const weatherControlText = document.createElement('div');
    weatherControlText.innerHTML = this.appSettings.getMessage('CONTROL_WEATHER');
    weatherControlText.style.fontSize = '8px';
    weatherControlText.style.color = XpoGoogleMapConfig.CONTROL_COLOR_DISABLED;
    weatherControlText.style.marginTop = '1px';
    weatherControlText.style.userSelect = 'none';
    weatherControlText.style.webkitUserSelect = 'none';
    weatherControl.appendChild(weatherControlText);

    // Weather Control click listener
    weatherControl.addEventListener('click', () => {
      this.enableOrDisableWeatherLayer(weatherControlIcon, weatherControlText);
    });
  }

  // Method called when the weather toggle button is turned on/off
  private enableOrDisableWeatherLayer(weatherControlIcon: HTMLDivElement, weatherControlText: HTMLDivElement) {
    if (this.map.overlayMapTypes.length > 0) {
      this.appSettings.eventTrack({
        action: GoogleAnalyticsConstants.EVENT_ACTION.DISABLE,
        properties: {
          category: GoogleAnalyticsConstants.EVENT_CATEGORY.WEATHER_LAYER,
          label: GoogleAnalyticsConstants.EVENT_CATEGORY.WEATHER_LAYER
        }
      });
      weatherControlIcon.style.backgroundImage = 'url(assets/icons/weather-map-disabled.svg)';
      weatherControlText.style.color = XpoGoogleMapConfig.CONTROL_COLOR_DISABLED;
      this.map.overlayMapTypes.pop();
    } else {
      this.appSettings.eventTrack({
        action: GoogleAnalyticsConstants.EVENT_ACTION.ENABLE,
        properties: {
          category: GoogleAnalyticsConstants.EVENT_CATEGORY.WEATHER_LAYER,
          label: GoogleAnalyticsConstants.EVENT_CATEGORY.WEATHER_LAYER
        }
      });
      weatherControlIcon.style.backgroundImage = 'url(assets/icons/weather-map-enabled.svg)';
      weatherControlText.style.color = XpoGoogleMapConfig.CONTROL_COLOR_ENABLED;
      this.map.overlayMapTypes.push(this.weatherLayer);
      // Setting the overlay opacity as 0.5
      this.map.overlayMapTypes.getAt(0).setOpacity(0.5);
    }
  }

  // Method responsible for drawing the route based on the origin and destination
  drawRoute(origin, destination, waypts: any[], markers, isMiddleMileApplicable?: boolean) {
    const directionsService = new google.maps.DirectionsService;
    const lineSymbol = {
      path: isMiddleMileApplicable ? 'M 0,-1 0,1' : '',
      strokeOpacity: 1,
      scale: 2.5
    };
    const directionsDisplay = new google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor:  XpoGoogleMapConfig.ROUTE_STROKE_COLOR,
        strokeWeight: XpoGoogleMapConfig.ROUTE_STROKE_WEIGHT,
        strokeOpacity: isMiddleMileApplicable ? 0 : 1,
        icons: isMiddleMileApplicable ? [{
          icon: lineSymbol,
          offset: '0',
          repeat: '20px'
        }] : [],
        map: this.map
      },
      suppressMarkers: true
    });
    directionsDisplay.setMap(this.map);


    directionsService.route({
      origin: origin,
      destination: destination,
      waypoints: waypts,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING
    }, (response, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
        if (!this.trafficAlertAPICalled) {
          this.trafficAlertAPICalled = true;
          this.showTrafficAlert(false);
        }
        let markerOrigin = markers.find(marker => (Object.keys(marker)[0].startsWith('truck')));
        if (markerOrigin) {          
          markerOrigin['truck1'].setPosition(response.routes[0].legs[0].start_location);
        }
      } else {
        console.log('Directions request failed: ' + status);
      }
    });
    const markerDest = new google.maps.Marker();
    markerDest.setMap(this.map);
    markerDest.setIcon('assets/icons/location-pin-destination2x.svg');
    markerDest.setPosition(destination);
  }

  // Method responsible for creating a custom infowindow
  createCustomInfoWindow() {
    return new (XpoCustomInfoWindow())();
  }

  async showTrafficAlert(takeFromMapBounds: boolean) {
    let northEastLat = 0;
    let northEastLng = 0;
    let southWestLat = 0;
    let southWestLng = 0;

    const bounds = this.map.getBounds();
    if (takeFromMapBounds) {
      northEastLat = bounds.getNorthEast().lat();
      northEastLng = bounds.getNorthEast().lng();
      southWestLat = bounds.getSouthWest().lat();
      southWestLng = bounds.getSouthWest().lng();
    } else {
      const detailsForTrafficAlerts: Location[] = this.getTruckLocationAndNextStop();
      if (detailsForTrafficAlerts && detailsForTrafficAlerts.length > 1) {
        northEastLat = detailsForTrafficAlerts[1].latitude;
        northEastLng = detailsForTrafficAlerts[0].longitude;
        southWestLat = detailsForTrafficAlerts[0].latitude;
        southWestLng = detailsForTrafficAlerts[1].longitude;
      }
    }
    await this.trafficService.getTrafficAlerts(northEastLat, northEastLng, southWestLat, southWestLng).then(
      data => {
        this.trafficData = data;
        this.trafficAlertAPICalled = false;
        this.handleTrafficResponse();
      }).catch(error => {
        this.trafficAlertAPICalled = false;
      });
  }

  handleTrafficResponse() {
    let trafficAlert: TrafficAlertItem;
    if (this.trafficData != null && this.trafficData.resourceSets != null &&
      this.trafficData.resourceSets.length > 0 && this.trafficData.resourceSets[0].resources != null &&
      this.trafficData.resourceSets[0].resources.length > 0) {
      this.appSettings.eventTrack({
        action: GoogleAnalyticsConstants.EVENT_ACTION.SHOW,
        properties: {
          category: GoogleAnalyticsConstants.EVENT_CATEGORY.TRAFFIC_ALERTS,
          label: GoogleAnalyticsConstants.EVENT_CATEGORY.TRAFFIC_ALERTS
        }
      });
      this.trafficAlertItems = [];
      this.trafficData.resourceSets[0].resources.sort((a: Resource, b: Resource) => {
        if (a.lastModified > b.lastModified) {
          return -1;
        } else if (a.lastModified < b.lastModified) {
          return 1;
        } else {
          return 0;
        }
      });
      let index = 0;
      this.trafficData.resourceSets[0].resources.forEach((element) => {
        if (element.roadClosed && element.type) {
          trafficAlert = new TrafficAlertItem(element.description,
            element.roadClosed, this.getDisplayTrafficTime(element.lastModified), index);
          index = index + 1;
          this.ngZone.run(() => {
            this.trafficAlertItems.push(trafficAlert);
          });
        }
      });
      this.orderService.trafficNotification = [];
      this.orderService.trafficNotification = this.trafficAlertItems;
      this.pushWeatherTrafficAlertControl();
    }
  }

  onCloseTrafficAlert(trafficItem: TrafficAlertItem) {
    this.trafficAlertItems.splice(trafficItem.index, 1);
    this.trafficAlertItems.forEach((item, index) => {
      this.trafficAlertItems[index].index = index;
    });
  }

  getTruckLocationAndNextStop(): Location[] { return []; }

  showWeatherAlert() {
    let subtrucks: TruckLocation[] = [];
    this.weatherAlertResponses = [];
    if (this.truckLocations.length < 2) {
      subtrucks = this.truckLocations;
      this.weatherAlertService.fetchWeatherAlert(subtrucks).then(data => {
        this.weatherData = data;
        if (this.weatherAlertResponses.length > 0) {
          this.weatherData.response.responses.forEach(element => {
            this.weatherAlertResponses.push(element);
          });
        } else {
          this.weatherAlertResponses = this.weatherData.response.responses;
        }
        if (this.truckLocations.length < 2) {
          this.setWeatherAlert();
        } else {
          this.showWeatherAlert();
        }
        this.weatherAlertAPICalled = false;
      }).catch(error => {
        this.weatherAlertAPICalled = false;
      });
    }
  }

  setWeatherAlert() {
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.SHOW,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.WEATHER_ALERTS,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.WEATHER_ALERTS
      }
    });

    if (this.weatherAlertResponses.length > 0) {
      this.weatherAlertResponses.sort((a: any, b: any) => {
        if (a.response.ob.timestamp > b.response.ob.timestamp) {
          return -1;
        } else if (a.response.ob.timestamp < b.response.ob.timestamp) {
          return 1;
        } else {
          return 0;
        }
      });

      let index = 0;
      this.weatherAlertList = [];
      this.weatherAlertResponses.forEach(element => {
        if (this.filterWeatherAlertsOptions.includes(element.response.ob.icon) &&
          !this.isInWeatherAlertList(element.response.place.name)) {
          this.ngZone.run(() => {
            this.weatherAlertList.push(
              new WeatherAlert(
                element.response.place.name,
                element.response.ob.tempC,
                element.response.ob.tempF,
                element.response.ob.humidity,
                element.response.ob.weather,
                'https://www.aerisweather.com/img/docs/' + element.response.ob.icon,
                element.response.ob.isDay,
                this.getDisplayWeatherTime(element.response.ob.timestamp),
                index));
            index = index + 1;
          });
        }
      });
      this.orderService.weatherNotification = [];
      this.orderService.weatherNotification = this.weatherAlertList;
      this.pushWeatherTrafficAlertControl();
    }
  }

  private isInWeatherAlertList(cityName: string): boolean {
    let isExist = false;
    this.weatherAlertList.forEach(element => {
      if (element.place === cityName) {
        isExist = true;
      }
    });
    return isExist;
  }

  pushWeatherTrafficAlertControl() {
    const trafficAlert = document.getElementById('map-events');
    this.map.controls[this.RIGHT_TOP].push(trafficAlert);
  }

  onCloseWeatherAlert(weatherItem: WeatherAlert) {
    this.weatherAlertList.splice(weatherItem.index, 1);
    this.weatherAlertList.forEach((item, index) => {
      this.weatherAlertList[index].index = index;
    });
  }

  getDisplayWeatherTime(lastModified: any): any {
    const date = new Date(lastModified);
    return date;
  }

  getDisplayTrafficTime(lastModified: any): any {
    const date = new Date(parseInt(lastModified.substr(6), 10));
    return date;
  }
  /**
 * Function responsible for calculating distance between truck and home
 */
  distance(truckLatitude, truckLongitude, homeLatitude, homeLongitude, unit) {
    const radlat1 = Math.PI * truckLatitude / 180;
    const radlat2 = Math.PI * homeLatitude / 180;
    const theta = truckLongitude - homeLongitude;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist) {
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') { dist = dist * 1.609344; }
      if (unit === 'N') { dist = dist * 0.8684; }
    }
    return dist;
  }
}

/**
 * Function responsible for creating a custom info window by extending google.maps.OverlayView
 * Getting google not defined error when tried to create the below function as a class
 */
function XpoCustomInfoWindow() {
  function CustomInfoWindow() {
    this.container = document.createElement('div');
    this.container.style.backgroundColor = '#FFFFFF';
    this.container.style.borderRadius = '4px';
    this.container.style.boxShadow = '0 2px 4px 0 rgba(0,0,0,0.2)';
    this.container.style.color = '#232323';
    this.container.style.overflow = 'hidden';
    this.container.style.position = 'absolute';
    this.container.style.padding = '10px 10px';

    this.closeBtn = document.createElement('div');
    this.closeBtn.style.backgroundImage = 'url(assets/icons/close.svg)';
    this.closeBtn.style.width = '12px';
    this.closeBtn.style.height = '12px';
    this.closeBtn.style.cssFloat = 'right';
    this.closeBtn.style.cursor = 'pointer';
    this.closeBtn.setAttribute('id', 'close-btn-popup');
    this.container.appendChild(this.closeBtn);

    this.content = document.createElement('div');
    this.container.appendChild(this.content);

    this.layer = null;
    this.marker = null;
    this.position = null;
  }

  CustomInfoWindow.prototype = new google.maps.OverlayView();

  CustomInfoWindow.prototype.onAdd = function () {
    this.layer = this.getPanes().floatPane;
    this.layer.appendChild(this.container);
    this.closeBtn.addEventListener('click', function () {
      this.close();
    }.bind(this), false);
    // Ensuring the custom info window is visible fully in the map
    setTimeout(this.panToView.bind(this), 200);
  };

  CustomInfoWindow.prototype.draw = function () {
    this.position = this.getProjection().fromLatLngToDivPixel(this.marker.getPosition());
    this.container.style.top = this.position.y - (this.container.offsetHeight / 2) + 'px';
    this.container.style.left = this.position.x - this.container.offsetWidth - 30 + 'px';
  };

  // CustomInfoWindow.prototype.panToView = function () {
  //   const map = this.getMap();
  //   const x = this.position.x - 130;
  //   const y = this.position.y;
  //   const point = new google.maps.Point(x, y);
  //   const latLng = this.getProjection().fromDivPixelToLatLng(point);
  //   this.map.panTo(latLng);
  // };

  // CustomInfoWindow.prototype.onRemove = function () {
  //   this.layer.removeChild(this.container);
  // };

  // CustomInfoWindow.prototype.setContent = function (content) {
  //   this.content.innerHTML = content;
  // };

  // CustomInfoWindow.prototype.open = function (map, marker) {
  //   this.marker = marker;
  //   this.setMap(map);
  // };

  // CustomInfoWindow.prototype.close = function () {
  //   this.setMap(null);
  // };

  return CustomInfoWindow;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from '../loader/loader.service';
import { TrackSpinnerComponent } from '../loader/track-spinner/track-spinner.component';

@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ],
    exports: [
        TrackSpinnerComponent
    ],
    declarations: [TrackSpinnerComponent],
    providers: [
        LoaderService
    ]
})
export class LoaderModule { }

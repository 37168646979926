import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Order } from 'app/shared';


@Injectable()
export class MenuService {
  public status: Subject<number> = new Subject();
  public menuStatus: Subject<number> = new Subject();
  private _active = 1;
  private _displaySelected = false;
  private _menuItem;
  public isTimerTriggered = false;
  public segmentRefreshNeeded = false;
  public ismapRefreshNeeded = false;
  public isUserValidating = false;
  public isHomeSelected = false;
  public pageIndex = 0;
  public googleMapStyle = { height: '' };
  public productDetailsViewHeight = { height: '87px' };
  private orderStatus = '';
  public userDefaultlanguage;
  public isSearchDialogRequired = false;
  public isMultiOrder = false;
  public multiOrderCIpageIndex = 0;
  public paramTrigger = true;
  public isIndexValueNeedUpdate = false;
  public needToUpdateCache = false;
  public isFromSearch = false;
  public isLandingPage = false;
  public isUIClick =false;
  public soId:string='';
  //public soNavigated:boolean = false;
  //public isLeftNavigation =false;
  constructor(private router: Router) { }

  public get active(): number {
    return this._active;
  }
  public set active(v: number) {
    this._active = v;
    this.status.next(v);
  }

  public get displaySelected(): any {
    return this._displaySelected;
  }
  public set displaySelected(r: any) {
    this._displaySelected = r;
    this.menuStatus.next(r);
  }

  public closeDialog(event) {
    this.hideMenu();
    if (event) { event.preventDefault(); }
  }

  public isMobileDevice(): boolean {
    if (window.innerWidth <= 1024) {
      this.googleMapStyle.height = '100%';
      this.productDetailsViewHeight.height = '';
      if (this.router.url.split('?')[ 0 ] === '/') {
        this.isHomeSelected = true;
      } else {
        this.isHomeSelected = false;
      }
      return true;
    }
    if (this.orderStatus === 'IS' || this.orderStatus === 'EXP') {
      this.productDetailsViewHeight.height = (window.innerHeight - 360) + 'px';
    } else {
      this.productDetailsViewHeight.height = (window.innerHeight - 440) + 'px';
    }
    this.googleMapStyle.height = (window.innerHeight - 55) + 'px'; // 55 is header height
    return false;
  }

  public isFirefoxBrowser(): boolean {
    if (/Firefox/.test(navigator.userAgent)) {
      return true;
    }
    return false;
  }

  public isAndroidDevice(): boolean {
    if (/Android/.test(navigator.userAgent)) {
      return true;
    }
    return false;
  }

  public isIosDevice(): boolean {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      return true;
    }
    return false;
  }

  public isIosDeviceSafariBrowser(): boolean {
    if (/Safari/.test(navigator.userAgent) && /iPad|iPhone|iPod/.test(navigator.userAgent)) {
      return true;
    }
    return false;
  }

  public isSafariBrowser(): boolean {
    if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
      return true;
    }
    if (/Safari/.test(navigator.userAgent) && /iPad|iPhone|iPod/.test(navigator.userAgent)) {
      return true;
    }
    return false;
  }

  public isIpadSafariBrowser(): boolean {
    if ((navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent && !navigator.userAgent.match('CriOS'))
      && /iPad/.test(navigator.userAgent)) {
      return true;
    }
    return false;
  }
  public isIEBrowser() {
    const userAgent = navigator.userAgent;
    const msie = userAgent.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older
      return true;
    }

    const trident = userAgent.indexOf('Trident/');
    if (trident > 0) {
      // IE 11
      return true;
    }

    const edge = userAgent.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+)
      return true;
    }
    return false;
  }
  public hideMenu(): void {
    this.isHomeSelected = true;
    this.active = 0;
  }

  public hideCloakView() {
    const cloakView: HTMLCollectionOf<Element> = document.getElementsByClassName('cdk-overlay-container');
    if (cloakView.length > 0) {
      cloakView[ 0 ].setAttribute('style', 'display: none;');
    }
  }
  public showCloakView() {
    const cloakView: HTMLCollectionOf<Element> = document.getElementsByClassName('cdk-overlay-container');
    if (cloakView.length > 0) {
      cloakView[ 0 ].setAttribute('style', 'display: block;');
    }
  }

  public isSMSFooterNeeded(orderStatus: string) {
    this.orderStatus = orderStatus;
    if (orderStatus === 'IS' || orderStatus === 'EXP') {
      return false;
    }
    return true;
  }

  public onNavigateCloseDailog(order) {
    if (this.router.url.split('?')[ 0 ] === ('/' || 'fr/')) {
      this.router.navigate([ '' ], { queryParams: { orderid: order } });
      this.hideMenu();
    } else {
      if (this.router.url.split('?')[ 0 ].startsWith('fr/')) {
        this.router.navigate([ '' ], { queryParams: { orderid: order } });
        this.hideMenu();
      } else {
        this.router.navigate([ '' ], { queryParams: { orderid: order } });
        this.hideMenu();
      }

    }
  }

  public isNumberValid(num, regex) {
    const regEx = new RegExp(regex);
    return (regEx.test(num));
  }

  public updateCache(oldArr, newArr) {
    for (let i = 0; i < oldArr.length; i++) {
      for (let i2 = 0; i2 < oldArr[ i ].length; i2++) {
        for (let j = 0; j < newArr.length; j++) {
          for (let j2 = 0; j2 < newArr[ j ].length; j2++) {
            if (oldArr[ i ][ i2 ].trackingNumber === newArr[ j ][ j2 ].trackingNumber) {
              oldArr[ i ] = newArr[ j ];
            }
          }
        }
      }
    }
    return oldArr;
  }

}


import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthServiceConfig, AuthService } from './services/auth.service';

export function configFactory(config: AuthServiceConfig) {
  return config;
}

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    AuthService
  ]
})

export class CalendarUnifiedModule {
  public static initialize(config: AuthServiceConfig): ModuleWithProviders<CalendarUnifiedModule> {
    return {
      ngModule: CalendarUnifiedModule,
      providers: [
        AuthService,
        {
          provide: AuthServiceConfig,
          useValue: config
        }
      ]
    };
  }
}

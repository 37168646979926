import { BaseLoginProvider } from './base-login-provider';
import * as hello from 'hellojs/dist/hello.all.js';
import * as MicrosoftGraphClient from '@microsoft/microsoft-graph-client';

export class OutlookLoginProvider extends BaseLoginProvider {
  public static readonly PROVIDER_ID: string = 'OUTLOOK';
  public static readonly SCOPES = 'User.Read Calendars.Read Calendars.ReadWrite Calendars.Read.Shared';

  constructor(private clientId: string) { super(); }

  public initialize(): Promise<any> {
    return new Promise((resolve, reject) => {
      hello.init({
        msft: {
          id: this.clientId,
          oauth: {
            version: 2,
            auth: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize'
          },
          scope_delim: ' ',
          form: false
        },
      },
        { redirect_uri: '/' }
      );
    });
  }

  public openSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      hello('msft').login({ scope: OutlookLoginProvider.SCOPES }).then(
        (res) => {
          resolve(true);
        },
        e => {
          reject(e.error.message);
        });
    });
  }

  public closeSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      hello('msft').logout().then(
        () => {
          resolve(true);
        },
        e => {
          reject(e.error.message);
        }
      );
    });
  }

  public addEventToCalendar(calendarAddEventObject): Promise<any> {
    return new Promise((resolve, reject) => {
      const client = this.getClient();
      client
        .api('/me/events')
        .post(calendarAddEventObject, (err, res) => {
          if (err) {
            reject();
          } else {
            resolve(true);
          }
        });
    });
  }

  public getCalendarFreeBusyData(orders): Promise<any> {
    return new Promise((resolve, reject) => {
      const client = this.getClient();
      client
        .api('/me')
        .get((err, res) => {
          if (err) {
            reject();
          } else {
            this.readOutlookFreeBusyData(client, res, orders, resolve, reject);
          }
        });
    });
  }

  private getAccessToken() {
    const msft = hello('msft').getAuthResponse();
    const accessToken = msft.access_token;
    return accessToken;
  }

  private getClient(): MicrosoftGraphClient.Client {
    return MicrosoftGraphClient.Client.init({
      authProvider: (done) => {
        done(null, this.getAccessToken());
      }
    });
  }

  private readOutlookFreeBusyData(client, response, orders, resolve, reject) {
    const timeMin = (new Date(orders[ 0 ].orderBookedDate)).toISOString();
    const scheduledDate = new Date(this.getOrderScheduledDate(orders));
    scheduledDate.setDate(scheduledDate.getDate() + 20);
    const timeMax = scheduledDate.toISOString();

    const requestBody = {
      'Attendees': [
        {
          'Type': 'Required',
          'EmailAddress': {
            'Name': response.givenName,
            'Address': response.mail
          }
        }
      ],
      'TimeConstraint': {
        'ActivityDomain': 'Unrestricted',
        'Timeslots': [
          {
            'Start': {
              'DateTime': timeMin,
              'TimeZone': 'Pacific Standard Time'
            },
            'End': {
              'DateTime': timeMax,
              'TimeZone': 'Pacific Standard Time'
            }
          }
        ]
      },
      'MeetingDuration': 'PT30M',
      'MaxCandidates': 10000
    };

    client
      .api('/me/findmeetingtimes')
      .post(requestBody, (err, res) => {
        if (err) {
          reject();
        } else {
          resolve(res);
        }
      });
  }

  private getOrderScheduledDate(orders) {
    if (orders) {
      const orderScheduledDates = [];
      for (const order of orders) {
        if (order.orderBookedDate) {
          orderScheduledDates.push(new Date(order.orderScheduledDate).getTime());
        }
      }
      const maxDate = Math.max.apply(Math, orderScheduledDates);
      return new Date(maxDate).toISOString();
    }
    return '';
  }
}

export class XpoGoogleMapConfig {
    // Map Custom Style
    static readonly MAP_STYLE: any[] = [
        {
            'elementType': 'labels',
            'stylers': [
              {
                'weight': 1
              }
            ]
          },
          {
            'elementType': 'labels.text',
            'stylers': [
              {
                'color': '#576152'
              }
            ]
          },
          {
            'elementType': 'labels.text.stroke',
            'stylers': [
              {
                'visibility': 'off'
              }
            ]
          },
        {
            'featureType': 'administrative',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#444444'
                }
            ]
        },
        {
            'featureType': 'administrative.country',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'visibility': 'on'
                },
                {
                    'color': '#858585'
                }
            ]
        },
        {
            'featureType': 'administrative.country',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#5d939f'
                }
            ]
        },
        {
            'featureType': 'administrative.province',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#5d939f'
                }
            ]
        },
        {
            'featureType': 'administrative.locality',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#5d939f'
                }
            ]
        },
        {
            'featureType': 'administrative.neighborhood',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#5d939f'
                }
            ]
        },
        {
            'featureType': 'landscape.man_made',
            'stylers': [
              {
                'color': '#f7f2ef'
              }
            ]
          },
          {
            'featureType': 'landscape.natural.landcover',
            'stylers': [
              {
                'lightness': 25
              },
              {
                'visibility': 'on'
              }
            ]
          },
        {
            'featureType': 'poi',
            'elementType': 'all',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'poi.park',
            'elementType': 'geometry',
            'stylers': [
                {
                    'gamma': '5.54'
                },
                {
                    'lightness': '-67'
                },
                {
                    'saturation': '14'
                },
                {
                    'visibility': 'simplified'
                },
                {
                    'color': '#dcf2cd'
                }
            ]
        },
        {
            'featureType': 'poi.park',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#dcf2cd'
                }
            ]
        },
        {
            'featureType': 'road',
            'elementType': 'all',
            'stylers': [
                {
                    'saturation': -100
                },
                {
                    'lightness': 45
                }
            ]
        },
        {
            'featureType': 'road',
            'elementType': 'geometry.fill',
            'stylers': [
              {
                'color': '#ffffff'
              }
            ]
          },
          {
            'featureType': 'road.highway',
            'elementType': 'geometry.stroke',
            'stylers': [
              {
                'color': '#e0ded9'
              }
            ]
          },
          {
            'featureType': 'road.local',
            'elementType': 'labels',
            'stylers': [
              {
                'visibility': 'off'
              }
            ]
          },
        {
            'featureType': 'road.arterial',
            'elementType': 'labels.icon',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'transit',
            'elementType': 'all',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'water',
            'elementType': 'all',
            'stylers': [
                {
                    'color': '#aae1f2'
                },
                {
                    'visibility': 'on'
                }
            ]
        }
    ];
    static readonly CONTROL_COLOR_DISABLED: string = '#DDDDDD';
    static readonly CONTROL_COLOR_ENABLED: string = '#000000';
    static readonly CONTROL_BACKGROUND_COLOR: string = '#FFFFFF';
    static readonly ROUTE_STROKE_COLOR: string = '#858585';
    static readonly ROUTE_STROKE_WEIGHT: number = 4;
}

<form novalidate>
  <mat-card-content>
    <div class="content-card top-space">
      <mat-form-field class="search-full-width" id="trackingNum">
        <input  maxlength="50" matInput [placeholder]="trackingNumberLabel" 
          tabindex="-1" [(ngModel)]="xpoTrackNum" [formControl]="trackingFormControl" name="trackingNum" required>
        <mat-hint>{{trackingNumberHint}}</mat-hint>
        <mat-error *ngIf="trackingFormControl.hasError('pattern')">
          {{trackingNumberInvalidMessage}}
        </mat-error>
        <mat-error *ngIf="trackingFormControl.hasError('required')">
          <span>{{trackingNumberEmptyMessage}}  </span>
          <strong i18n="@@TrackAnotherOrderModule-SearchComponent-Required">required</strong>
        </mat-error>
      </mat-form-field>
      <div class="align-btn">
        <button mat-raised-button class="search-btn-black top-space" [ngClass]="{'border-btn':isTrackingFormValid()}" [disabled]="!isTrackingFormValid()"
          id="xpoSearch" (click)="xpoTrackingSearch($event)" i18n="@@TrackAnotherOrderModule-SearchComponent-Track">TRACK</button>
      </div>
    </div>
  </mat-card-content>
</form>

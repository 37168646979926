import { Component, ChangeDetectorRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { LoaderService } from '../../loader/loader.service';

@Component({
  selector: 'app-track-spinner',
  templateUrl: './track-spinner.component.html',
  styleUrls: [ './track-spinner.component.scss' ]
})
export class TrackSpinnerComponent implements AfterViewInit, AfterViewChecked {
  public active: boolean;
  public constructor(
    spinner: LoaderService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    spinner.status.subscribe((status: boolean) => {
      this.active = status;
    });
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }
  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

}

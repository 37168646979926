<div class="product-details" *ngIf="order">
  <mat-card class="product-detail-card">
    <div class="details-welcome-div"> 
      <div class="row-card exception-div">
        <img id="itemExceptionImg" *ngIf="showExceptionImg" src="./assets/icons/icon-exception.png" />
        <span class="welcome-sub-text">{{ welcomeDeliveryText }}</span>
      </div>
      <span class="stops-away" *ngIf="canDisplayStops">
        <img src='./assets/icons/pin.svg'> {{numberOfStopsAway}}
      </span>
    </div>
    <div class="product-detail-card-content" *ngIf="order.items?.length">
      <mat-card-title id="product-details">
        <span class="span-icon">
          <img src='./assets/icons/icon-box.svg'>
        </span>
        <span class="title-card" i18n="@@TrackOrderModule-ProductDetailsComponent-Items">ITEMS</span>
      </mat-card-title>
      <mat-card-content class="content-overflow">
        <div class="content-card" *ngFor="let item of order.items; let i=index;">
          <div class="row-card">

            <div id="item-label-{{i}}" class="key-card-item" (click)="openItemUrl(item.itemUrl)" [ngClass]="{'hyperlink-text':item.itemUrl, 'itemExceptionDescription':item.isException}">
              {{item.description}}
              <span class="value-card-item" [ngClass]="{'hyperlink-text':item.itemUrl, 'itemExceptionDescription':item.isException}">
                ({{item.quantity}})
              </span>
              <img *ngIf="item.isException" id="itemExceptionImg" (click)="openOrderDetails()" src='./assets/icons/icon-exception.png'>
            </div>

          </div>
        </div>
      </mat-card-content>
    </div>
    <div class="product-detail-card-content" *ngIf="order.services?.length">
      <mat-card-title id="product-details" class="title-card">
        <span class="span-icon">
          <img src='./assets/icons/icon-services.svg'>
        </span>
        <span class="title-card" i18n="@@TrackOrderModule-ProductDetailsComponent-Services">SERVICES</span>
      </mat-card-title>
      <mat-card-content class="content-overflow">
        <div class="content-card" *ngFor="let service of order.services; let i=index">
          <div class="row-card">
            <div id="item-label-{{i}}" class="key-card-item">{{service.description}}
            </div>
          </div>
        </div>
      </mat-card-content>
    </div>
  </mat-card>
</div>
<div class="calender-header">
  <div class="btn btn-prev" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)">
    &#8249;
  </div>
  <!-- <div
          class="btn btn-today"
          mwlCalendarToday
          [(viewDate)]="viewDate"
          (viewDateChange)="viewDateChange.next(viewDate)">
          Today
        </div> -->
  <div class="btn btn-next" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)">
    &#8250;
  </div>
  <div class="calender-date">
    <h4>{{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}</h4>
  </div>
</div>

import {throwError as observableThrowError,  Observable ,  Subject ,  BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError ,  tap } from 'rxjs/operators';
import { AppSettings } from '../../core/app-settings';
import { Order, SurveyAnswer, VerificationOption, Survey, Profile, TimeSlotResponse } from '../../shared';
import { ErrorHandlerService } from '../../shared/services/errorHandler.service';
import { MenuService } from '../../core/layout/menu.service';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { ChatService } from './chat.service';

@Injectable()
export class OrderService {

  private orderSubject: BehaviorSubject<Order> = new BehaviorSubject(null);
  private ordersSubject: BehaviorSubject<Order[]> = new BehaviorSubject(new Array<Order>());
  private mapSubject: BehaviorSubject<Order[]> = new BehaviorSubject(new Array<Order>());
  private httpErrorSubject: Subject<number> = new Subject();
  public orderId = null;
  public flagValue = false;
  public weatherNotification: any = [];
  public trafficNotification: any = [];
  public verificationOptionSelected = '';
  public orderValue: any = [];
  public orderRefresh: boolean = false;
  constructor(
    private http: HttpClient,
    private menuService: MenuService,
    private errorHandlerService: ErrorHandlerService,
    private appSettings: AppSettings,
    private chatService: ChatService
  ) {
    this.ordersSubject.subscribe(orders => {
      if (orders) {
        if (orders.length > 1) {
          this.menuService.isMultiOrder = true;
          this.orderSubject.next(orders.length ? orders[this.menuService.pageIndex][this.menuService.multiOrderCIpageIndex] : null);
        } else {
          this.orderSubject.next(orders.length ? orders[0][this.menuService.pageIndex] : null);
          this.menuService.isMultiOrder = false;
        }
      }
    });
  }

  get order(): Observable<Order> {
    return this.orderSubject.asObservable();
  }

  get orders(): Observable<Order[]> {
    return this.ordersSubject.asObservable();
  }

  get mapOrder(): Observable<Order[]> {
    return this.mapSubject.asObservable();
  }

  get httpErrorCode(): Observable<number> {
    return this.httpErrorSubject.asObservable();
  }

  public searchOrder(id: string): Observable<any> {
    const url = this.getUri(id);
    return this.http
      .get(url)
      .pipe(
        tap<any>(orders => {
          if (orders && orders.length == 0) {
            this.ordersSubject.next(orders);
            this.activateChat();
            return this.errorHandlerService.handleError('Order Not Found');
          }
          if (orders) {
            if (!this.menuService.needToUpdateCache) {
              orders = this.menuService.updateCache(this.orderValue, orders);
              this.ordersSubject.next(orders);
            } else {
              this.orderValue = orders;
              this.ordersSubject.next(orders);
              this.menuService.needToUpdateCache = false;
            }
          }
          this.activateChat(orders[0][0].salesOrderId);
        }),
        catchError<Order, any>((error: HttpErrorResponse) => {
          return this.errorHandlerService.handleError(error);
        })
      );
  }

  public searchByReferenceNumber(referenceNumber: string, billTo: string): Observable<any> {
    const url = this.getOrderByReferenceNumberUri(referenceNumber, billTo);
    return this.http
      .get(url)
      .pipe(
        tap<any>(orders => {

          if (orders && orders.length == 0) {
            this.ordersSubject.next(orders);
            this.activateChat();
            return this.errorHandlerService.handleError('Order Not Found');
          }

          if (orders) {
            if (this.menuService.needToUpdateCache) {
              this.orderValue = orders;
              this.menuService.needToUpdateCache = false;
            }
            this.ordersSubject.next(orders);
          }
          this.activateChat(orders[0][0].salesOrderId);
        }),
        catchError<Order, any>((error: HttpErrorResponse) => {
          return this.errorHandlerService.handleError(error);
        })
      );
  }

  public searchByCustomerId(searchCriteria: any): Observable<any>{
    const url = this.getTrackByCustomerIdUri(searchCriteria['customerIdentifier'], searchCriteria['phoneNumber'], searchCriteria['customerOrderNumber'], searchCriteria['reference'])
    return this.http
      .get(url)
      .pipe(
        tap<any>(orders => {

          if (orders && orders.length == 0) {
            this.ordersSubject.next(orders);
            this.activateChat();
            return this.errorHandlerService.handleError('Order Not Found');
          }

          if (orders) {
            if (this.menuService.needToUpdateCache) {
              this.orderValue = orders;
              this.menuService.needToUpdateCache = false;
            }
            this.ordersSubject.next(orders);
          }
          this.activateChat(orders[0][0].salesOrderId);
        }),
        catchError<Order[], any>((error: HttpErrorResponse) => {
          return this.errorHandlerService.handleError(error);
        })
      );
  }

  public searchByTrackId(id: string, phone: string): Observable<any> {
    const url = this.getPhoneUri(id, phone);
    return this.http
      .get<Order[]>(url)
      .pipe(
        tap<Order[]>(orders => {

          if (orders && orders.length == 0) {
            this.ordersSubject.next(orders);
            this.activateChat();
            return this.errorHandlerService.handleError('Order Not Found');
          }


          if (orders) {
            if (this.menuService.needToUpdateCache) {
              this.orderValue = orders;
              this.menuService.needToUpdateCache = false;
            }
            this.ordersSubject.next(orders);
          }
          this.activateChat(orders[0][0].salesOrderId);
        }),
        catchError<Order[], any>((error: HttpErrorResponse) => {
          return this.errorHandlerService.handleError(error);
        })
      );
  }

  public searchByDeliveryDate(deliveryDate: string, phone: string): Observable<any> {
    const url = this.getDeliveryDateUri(deliveryDate, phone);
    return this.http
      .get<Order[]>(url)
      .pipe(
        tap<Order[]>(orders => {
          if (orders && orders.length == 0) {
            this.ordersSubject.next(orders);
            this.activateChat();
            return this.errorHandlerService.handleError('Order Not Found');
          }
          if (orders) {
            if (this.menuService.needToUpdateCache) {
              this.orderValue = orders;
              this.menuService.needToUpdateCache = false;
            }
            this.ordersSubject.next(orders);
          }
          this.activateChat(orders[0][0].salesOrderId);
        }),
        catchError<Order[], any>((error: HttpErrorResponse) => {
          return this.errorHandlerService.handleError(error);
        })
      );
  }

  public fetchSurvey(): Observable<any> {
    const order: Order = this.orderSubject.getValue();
    if (!order.orderId.toString().includes(order.workOrderId) && order.workOrderId != null) {
      order.orderId = order.orderId.toString() + '-' + order.workOrderId;
    }

    const url = this.getUri(order.orderId.toString()) + '/survey';
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.handleError(error);
        })
      );
  }
  public sendSurvey(answers): Observable<any> {
    const order: Order = this.orderSubject.getValue();
    let orderId = order.orderId.toString();
    if (!orderId.includes(order.workOrderId) && order.workOrderId != null) {
      orderId = orderId + '-' + order.workOrderId;
    }
    const url = this.getUri(orderId) + '/survey';
    return this.http
      .post(url,
        {
          responses: answers,
          orderId: orderId,
        })
      .pipe(
        tap<any>(response => {
          if (response) {
            if (this.orderValue) {
              response = this.menuService.updateCache(this.orderValue, response);
              return this.ordersSubject.next(response);
            } else {
              return this.ordersSubject.next(response);
            }
          }

        }),
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.handleError(error);
        })
      );
  }

  public updateProfile(email: string, phone: string): Observable<any> {
    const order: Order = this.orderSubject.getValue();
    const url = this.updateprofileUri(order.orderId.toString());
    return this.http
      .put(url,
        {
          email: email,
          phoneNumber: phone,
          orderId: order.orderId,
          workOrderId: order.workOrderId
        })
      .pipe(
        tap<any>(response => {
          if (response) {
            return response;
          }

        }),
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.handleError(error);
        })
      );
  }

  public subscribeAlerts(phone: string): Observable<any> {
    const order: Order = this.orderSubject.getValue();
    const url = this.subscribeAlertsUri(order.orderId) + '?number=' + phone;
    return this.http
      .post(url, {})
      .pipe(
        tap<any>(response => {
          if (response) {
            if (this.orderValue) {
              response = this.menuService.updateCache(this.orderValue, response);
              return this.ordersSubject.next(response);
            } else {
              return this.ordersSubject.next(response);
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.handleError(error);
        })
      );
  }

  public unsubscribeAlerts(): Observable<any> {
    const order: Order = this.orderSubject.getValue();
    const url = this.subscribeAlertsUri(order.orderId) + '?phoneNumber=' + order.consignee.phone;
    return this.http
      .delete(url)
      .pipe(
        tap<any>(response => {
          if (response) {
            if (this.orderValue) {
              response = this.menuService.updateCache(this.orderValue, response);
              return this.ordersSubject.next(response);
            } else {
              return this.ordersSubject.next(response);
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.handleError(error);
        })
      );
  }

  public requestValidationOptions(): Observable<any> {
    const order: Order = this.orderSubject.getValue();
    const url = this.getValidationOptionsUri(order.orderId.toString());
    return this.http
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.handleError(error);
        })
      );
  }

  public requestValidationCode(methodSelected: string): Promise<any> {
    const order: Order = this.orderSubject.getValue();
    const url = this.getValidationRequestUri(order.orderId.toString(), methodSelected);
    return this.http
      .post<any>(url, {})
      .toPromise().then(response => {
        this.verificationOptionSelected = methodSelected;
        return '';
      })
      .catch((error) => {
        this.errorHandlerService.handleError(error);
      });
  }

  public applyValidationCode(verificationCode: string): Promise<any> {
    const order: Order = this.orderSubject.getValue();
    const url = this.getValidationPutRequestUri(order.orderId.toString(), verificationCode);
    return this.http
      .put<any>(url, {})
      .toPromise()
      .then(response => {
        if (response) {
          if (this.orderValue) {
            response = this.menuService.updateCache(this.orderValue, response);
            this.ordersSubject.next(response);
          } else {
            this.ordersSubject.next(response);
          }
          return response;
        }
      })
      .catch(error => { this.errorHandlerService.handleError(error); });
  }

  public fetchSchedule(): Observable<any> {
    const order: Order = this.orderSubject.getValue();
    const woOrder = order.isLMx ? order.workOrderId : '';
    const url = this.getUri(order.orderId.toString()) + '/schedules' + '?workOrderId=' + woOrder;
    return this.http
      .get<TimeSlotResponse>(url)
      .pipe(
        catchError<TimeSlotResponse, any>((error: HttpErrorResponse) => {
          return this.errorHandlerService.handleError(error);
        })
      );
  }

  public sendSchedule(date: string, timeslot: Array<object>): Observable<any> {
    const order: Order = this.orderSubject.getValue();
    const woOrder = order.isLMx ? order.workOrderId : '';
    const url = this.getUri(order.orderId.toString()) + '/schedules' + '?workOrderId=' + woOrder;
    return this.http
      .post(url,
        {
          date: date,
          timeWindows: timeslot
        })
      .pipe(
        tap<any>(response => {
          if (response) {
            this.orderValue = [];
            this.orderValue = response;
            return this.ordersSubject.next(response);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.handleError(error);
        })
      );
  }

  unSubscribeEmail(email: string, optOutCurrent: number, optOutFuture: number): Promise<any> {
    const order: Order = this.orderSubject.getValue();
    const url = this.unsubscribeEmailUri(order.orderId.toString());
    return this.http
      .put<any>(url,
        {
          email: email,
          optOutCurrent: optOutCurrent,
          optOutFuture: optOutFuture
        })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => { this.errorHandlerService.handleError(error); });
  }

  // Constructrs the uri to use for the endpoint
  private getUri(id: string): string {
    return AppSettings.config.apiUrl + 'orders/' + id;
  }
  private getPhoneUri(id: string, phone: string): string {
	return AppSettings.config.apiUrl + 'orders/track/' + id + '?phoneNumber=' + phone;
  }
  private getDeliveryDateUri(deliveryDate: string, phone: string): string {
    return AppSettings.config.apiUrl + 'orders/trackByPhoneNumber/' + '?phoneNumber=' + phone + '&' + 'deliveryDate=' + deliveryDate;
  }
  private updateprofileUri(id: string): string {
    return AppSettings.config.apiUrl + 'orders/' + id + '/profile';
  }
  private subscribeAlertsUri(id: string): string {
    return AppSettings.config.apiUrl + 'orders/' + id + '/alertsubscription';
  }
  private getValidationOptionsUri(id: string): string {
    return AppSettings.config.apiUrl + 'orders/' + id + '/validationmethods';
  }
  private getValidationRequestUri(id: string, method: string): string {
    return AppSettings.config.apiUrl + 'orders/' + id + '/validationrequest?method=' + method;
  }
  private getValidationPutRequestUri(id: string, code: string): string {
    return AppSettings.config.apiUrl + 'orders/' + id + '/validationrequest?code=' + code;
  }
  private unsubscribeEmailUri(id: string): string {
    return AppSettings.config.apiUrl + 'orders/unsubscribeEmail?orderId=' + id;
  }
  private getOrderByReferenceNumberUri(trackingNumber: string, billTo: string): string {
    return `${AppSettings.config.apiUrl}orders/trackbillto/${trackingNumber}?billTo=${billTo}`;
  }
  private getTrackByCustomerIdUri(customerId: string, phoneNumber: string, customerOrderNumber: string, reference: string): string {
    let url = `${AppSettings.config.apiUrl}orders/trackByCustomerIdentifier?customerIdentifier=${customerId}` + `&phoneNumber=${phoneNumber}`;
    if (customerOrderNumber) {
      url += `&customerOrderNumber=${customerOrderNumber}`;
    }
    if (reference){
      url += `&reference=${reference}`;
    }
    return url;
  }

  // Handles error
  private handleError(error: Response | any): Observable<Error> {
    this.httpErrorSubject.next(error);
    return observableThrowError(new Error(error.status));
  }

  public setOrderId(id: string) {
    this.orderId = id;
  }
  public setOrder(order: Order) {
    this.orderSubject.next(order);
  }
  public setMapOrder(order: Order[]) {
    this.mapSubject.next(order);
  }
  public callfooterenabledisable(flag) {
    if (flag === 1) {
      return this.flagValue = true;
    } else {
      return this.flagValue = false;
    }
  }

  private postESignURI(id: string): string {
    return AppSettings.config.apiUrl + 'ContactlessDelivery/' + id;
  }

  private downloadPODURI(id: string, workOrderId: string): string {
    return AppSettings.config.apiUrl + 'ContactlessDelivery/GetDocuments/' + id + '?workorderID=' + workOrderId;
  }

  
  public downloadPOD(data: any): Observable<any> {
    const url = this.downloadPODURI(data.orderId.toString(), data.workOrderId.toString());

    return this.http.post(url, null, { responseType: 'text' });
  }
  public postESign(data: any): Observable<any> {    
    const url = this.postESignURI(data.orderId.toString());       
    return this.http
      .post(url, data)
      .pipe(
        tap<any>(response => {
          if (response) {
            return response;
          }

        }),
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.handleError(error);
        })
      );
  }

  public resizeWidth(windowWidth: number): string {
    var width = "";
    switch (windowWidth) {
      case 1024:
        width = "390";
        break;
      case 768:
        width = "290";
        break;
      case 360:
        width = "260";
        break;
      case 411:
      case 414:
        width = "300";
        break;
      case 840:
      case 812:
        width = "310";
        break;
      case 320:
        width = "230";
        break;
      case 1366:
        width = "330";
        break;
      case 375:
        width = "270";
        break;
      case 736:
      case 731:
        width = "530";
        break;
      case 568:
        width = "410";
        break;
      case 667:
        width = "480";
        break;
      case 640:
        width = "450";
        break;
      default:
        width = "350";
        break;
    }
    return width;
  }

  private activateChat(salesOrderId?: string) {   
    if (this.chatService.icPatronChat) {
      let chatDocument = document.querySelector('.ie-div-position-customer-chat');
      document.body.removeChild(chatDocument);
    }
    this.chatService.init(salesOrderId? salesOrderId : null);
  }

}

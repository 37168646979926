import { Traffic } from '../model/traffic';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AppSettings } from '../../core/app-settings';

@Injectable()
export class TrafficService {
  public BING_API_BASE = 'https://dev.virtualearth.net/REST/v1/Traffic/Incidents/';
  public BING_API_KEY = 'AghMCP5Z1l_-enB0fZTy_3Rpq4Rh_4AnVSDW2AkYKZ0cNAcPsvfxrH3fVpvIemMm';

  constructor(private http: HttpClient) { }

  public getTrafficAlerts(northLat: number, eastLng: number, southLat: number, westLng: number): Promise<any> {
    // South Latitude, West Longitude, North Latitude, East Longitude
    // eslint-disable-next-line max-len
    const apiUrl = `${this.BING_API_BASE}${southLat},${westLng},${northLat},${eastLng}?s=3,4&key=${AppSettings.config.BING_API_KEY}&jsonp=JSONP_CALLBACK`;

    return new Promise((resolve, reject) => {
      this.http.jsonp<Traffic>(apiUrl, '')
        .toPromise().then(response => {
          const data = response;
          resolve(<Traffic> response);
        }).catch(error => {
          const errorData = error;
          reject(error);
        });
    });
  }
}

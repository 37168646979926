<mat-tab-group #tabGroup>
  <mat-tab label="Retailer Reference #" i18n-label="@@TrackAnotherOrderModule-track-another-order-tab-ReferenceNumber">
    <div class="top margin-side-content" *ngIf="tabGroup.selectedIndex === 0">
      <app-track-xpo-reference-number></app-track-xpo-reference-number>
    </div>
  </mat-tab>
  <mat-tab label="Phone #" i18n-label="@@TrackAnotherOrderModule-track-another-order-tab-Phone">
    <div class="top margin-side-content" *ngIf="tabGroup.selectedIndex === 1">
      <app-track-xpo-delivery-number></app-track-xpo-delivery-number>
    </div>
  </mat-tab>
  <mat-tab [label]="trackingLabel">
    <div class="top margin-side-content" *ngIf="tabGroup.selectedIndex === 2">
      <app-track-xpo-number></app-track-xpo-number>
    </div>
  </mat-tab>
</mat-tab-group>

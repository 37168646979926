import { BaseLoginProvider } from './base-login-provider';

declare const gapi: any;

export class GoogleLoginProvider extends BaseLoginProvider {
  public static readonly PROVIDER_ID: string = 'GOOGLE';
  public static readonly SCOPES = 'https://www.googleapis.com/auth/calendar';
  public static readonly DISCOVERY_DOCS = [ 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest' ];
  private auth2: any;

  constructor(private clientId: string) { super(); }

  public initialize(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loadScript(GoogleLoginProvider.PROVIDER_ID,
        '//apis.google.com/js/platform.js',
        () => {
          gapi.load('client:auth2', () => {
            this.auth2 = gapi.auth2.init({
              clientId: this.clientId,
              discoveryDocs: GoogleLoginProvider.DISCOVERY_DOCS,
              scope: GoogleLoginProvider.SCOPES
            });

            this.auth2.then(() => {
              if (this.auth2.isSignedIn.get()) {
                resolve(true);
              }
            });
          });
        });
    });
  }

  public openSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      const promise = this.auth2.signIn();
      promise.then(() => {
        resolve(true);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  public closeSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.auth2.signOut().then((err: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(true);
        }
      });
    });
  }

  public addEventToCalendar(calendarAddEventObject): Promise<any> {
    return new Promise((resolve, reject) => {
      gapi.client.calendar.events
        .insert({
          'calendarId': 'primary',
          'resource': calendarAddEventObject
        }).then((response) => {
          resolve(response);
        }).catch(() => {
          reject();
        });
    });
  }

  public getCalendarFreeBusyData(orders): Promise<any> {
    return new Promise((resolve, reject) => {
      gapi.client.load('calendar', 'v3', () => {
        this.readFreeBusyData(orders, resolve, reject);
      });
    });
  }

  private readFreeBusyData(orders, resolve, reject) {
    const timeMin = (new Date(orders[ 0 ].orderBookedDate)).toISOString();
    const scheduledDate = new Date(this.getOrderScheduledDate(orders));
    scheduledDate.setDate(scheduledDate.getDate() + 20);
    const timeMax = scheduledDate.toISOString();
    gapi.client.calendar.freebusy
      .query({
        'items': [
          {
            'id': 'primary'
          }
        ],
        'timeMin': timeMin,
        'timeMax': timeMax
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject();
      });
  }

  private getOrderScheduledDate(orders) {
    if (orders) {
      const orderScheduledDates = [];
      for (const order of orders) {
        if (order.orderBookedDate) {
          orderScheduledDates.push(new Date(order.orderScheduledDate).getTime());
        }
      }
      const maxDate = Math.max.apply(Math, orderScheduledDates);
      return new Date(maxDate).toISOString();
    }
    return '';
  }
}

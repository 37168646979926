import { Component, OnInit } from '@angular/core';
import { AppSettings } from '../../../core/app-settings';
import { XPOConstants } from '../../constants/xpo-constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements OnInit {

  public logoHyperlink: string;
  public logoSource: string;
  constructor(private appSettings: AppSettings) { 
  }

  ngOnInit() {
    this.setLogo();
  }
  private setLogo(){
    this.logoHyperlink = this.appSettings.domainName === "XPO" ? XPOConstants.XPO_URL : XPOConstants.RXO_URL;
    this.logoSource = this.appSettings.domainName === "XPO" ? "./assets/images/xpo-logo-dark.svg" : "./assets/images/RXO_rgb_k.png";

  }

}

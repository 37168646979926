import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AppSettings } from '../../core/app-settings';
import { Document } from '../../shared';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable()
export class ImageService {
  private documents = new Map<string, Document[]>();
  constructor(private http: HttpClient) {}

  public getDocuments(orderId: string): Observable<Document[]> {
    if (!this.documents[orderId]) {
      const url = this.getUri(orderId);
      return this.http.get<Document[]>(url).pipe(
        tap(docs => {
          this.documents[orderId] = docs;
        })
      );
    }
    return of(this.documents[orderId]);
  }

  public getImages(orderId: string, documentKey: string): Observable<string> {
    const url = this.getImageUri(orderId, documentKey);
    return this.http.get<string>(url, { responseType: 'text' as 'json' });
  }

  private getUri(id: string): string {
    return AppSettings.config.apiUrl + 'trips/' + id + '/documents';
  }
  private getImageUri(id: string, key: string): string {
    return AppSettings.config.apiUrl + 'trips/' + id + '/documents/' + key;
  }
}

import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { TrafficService } from '../shared/services/traffic.service';
import { WeatherAlertService } from '../shared/services/weather-alert.service';
import {
  DatexPipe, KeyValuePipe, PhonePipe,
} from '../shared';
import { PageErrorComponent } from '../shared/common-components/dialog/page-error/page-error.component';
import { HeaderComponent } from './common-components/header/header.component';
import { LanguageSelectorComponent } from './common-components/language-selector/language-selector.component';
import { FormsModule } from '@angular/forms';
import { FreeDraggingDirective } from './draggable.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatDialogModule,
        MatIconModule
    ],
    exports: [
        MatButtonModule,
        MatMenuModule,
        MatDialogModule,
        MatIconModule,
        KeyValuePipe,
        PhonePipe,
        DatexPipe,
        PageErrorComponent,
        HeaderComponent,
        LanguageSelectorComponent,
        FreeDraggingDirective
    ],
    declarations: [KeyValuePipe, PageErrorComponent,
        PhonePipe, DatexPipe, HeaderComponent, LanguageSelectorComponent, FreeDraggingDirective],
    providers: [
        TrafficService, WeatherAlertService
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule { }

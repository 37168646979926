import { Component, OnInit } from '@angular/core';
import { XPOConstants } from '../../shared';
import { Validators, FormControl } from '@angular/forms';
import { LoaderService } from '../../loader/loader';
import { MenuService } from '../../core/layout/menu.service';
import { GoogleAnalyticsConstants } from '../../shared/constants/google-analytics-constants';
import { MatDialog } from '@angular/material/dialog';
import { OrderService } from '../../shared/services/order.service';
import { AppSettings } from '../../core/app-settings';

@Component({
  selector: 'app-track-xpo-number',
  templateUrl: './track-xpo-number.component.html',
  styleUrls: [ './track-xpo-number.component.scss' ]
})
export class TrackXpoNumberComponent implements OnInit {
  public trackingFormControl;
  public xpoTrackNum = '';
  public trackingNumberInvalidMessage: string;
  public trackingNumberLabel: string;
  public trackingNumberEmptyMessage: string;
  public trackingNumberHint: string;

  constructor(
    private spinner: LoaderService,
    public dialog: MatDialog,
    private appSettings: AppSettings,
    private orderService: OrderService,
    public menuService: MenuService
    ) {
    this.trackingFormControl = new FormControl('', [ Validators.required, Validators.pattern(XPOConstants.JOB_NUMBER_REGEX) ]);
  }

  ngOnInit() {
    this.trackingNumberInvalidMessage = this.appSettings
    .getMessage('TRACKING_NUMBER_INVALID')
    .replace('[DOMAIN_NAME]', this.appSettings.domainName);

    this.trackingNumberLabel = this.appSettings
    .getMessage('TRACKING_NUMBER_LABEL')
    .replace('[DOMAIN_NAME]', this.appSettings.domainName);

    this.trackingNumberEmptyMessage = this.appSettings
    .getMessage('TRACKING_NUMBER_EMPTY')
    .replace('[DOMAIN_NAME]', this.appSettings.domainName);

    this.trackingNumberHint = this.appSettings
    .getMessage('TRACKING_NUMBER_HINT')
    .replace('[DOMAIN_NAME]', this.appSettings.domainName);
   }

  public xpoTrackingSearch(event) {
    this.menuService.isUIClick=false;
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.JOB_SEARCH,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.SEARCH_ORDER,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.SEARCH_ORDER
      }
    });
    this.dialog.closeAll();
    this.spinner.showLoader();
    if (this.xpoTrackNum && this.menuService.isNumberValid(this.xpoTrackNum, XPOConstants.JOB_NUMBER_REGEX)) {
      this.menuService.ismapRefreshNeeded = true;
      this.menuService.segmentRefreshNeeded = true;
      this.menuService.paramTrigger = false;
      this.menuService.pageIndex = 0;
      this.menuService.multiOrderCIpageIndex = 0;
      this.menuService.needToUpdateCache = true;
      this.menuService.isFromSearch = true;
      if (this.xpoTrackNum.startsWith('XP')) {
        this.orderService.searchByReferenceNumber(this.xpoTrackNum, '0')
          .subscribe(() => {
            this.spinner.hideLoader();
            this.menuService.closeDialog(event);
          });
      } else {
        this.orderService.orderRefresh = false;
        this.orderService.searchOrder(this.xpoTrackNum)
          .subscribe(() => {
            this.spinner.hideLoader();
            this.menuService.closeDialog(event);
          });
      }
    }
  }

  public isTrackingFormValid() {
    return this.trackingFormControl.valid;
  }

}

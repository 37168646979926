<form novalidate>
  <mat-card-content>
    <div class="content-card top-space">
      <mat-form-field class="search-full-width" id="retNum">
        <input size="100" maxlength="25" matInput placeholder="Retailer's Reference Number" i18n-placeholder="@@TrackAnotherOrderModule-SearchComponent-Placeholder-ReferenceNumber"
          tabindex="-1" [(ngModel)]="retailerReferenceNum" [formControl]="referenceFormControl" name="retailer" required>
        <mat-error *ngIf="referenceFormControl.hasError('required')">
          <span i18n="@@TrackAnotherOrderModule-SearchComponent-RetailerReferenceNumberIs">Retailer's Reference Number is </span>
          <strong i18n="@@TrackAnotherOrderModule-SearchComponent-Required">required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="search-full-width top-space" id="delNum">
        <input type="tel" matInput placeholder="Phone Number" i18n-placeholder="@@TrackAnotherOrderModule-SearchComponent-Placeholder-DeliveryPhoneNumber"
          tabindex="-1" showMaskTyped="true" mask={{phoneNumberMask}} [(ngModel)]="referencePhoneNum" [formControl]="referencePhoneFormControl"
          name="delivery" required>
        <mat-hint>(XXX) XXX-XXXX</mat-hint>
        <mat-error *ngIf="referencePhoneFormControl.hasError('pattern')" i18n="@@TrackAnotherOrderModule-SearchComponent-EnterValidPhoneNumber">
          Please enter a valid Phone Number
        </mat-error>
        <mat-error *ngIf="referencePhoneFormControl.hasError('required')">
          <span i18n="@@TrackAnotherOrderModule-SearchComponent-DeliveryPhoneNumberIs">Delivery Phone Number is </span>
          <strong i18n="@@TrackAnotherOrderModule-SearchComponent-Required">required</strong>
        </mat-error>
      </mat-form-field>
      <div class="align-btn">
        <button mat-raised-button class="search-btn-black top-space" tabindex="-1" [ngClass]="{'border-btn':isReferenceFormValid()}"
          [disabled]="!isReferenceFormValid()" id="retailerSearch" (click)="retailerSearch($event)" i18n="@@TrackAnotherOrderModule-SearchComponent-Track">TRACK</button>
      </div>
    </div>
  </mat-card-content>
</form>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Order } from '../shared';
import { OrderService } from '../shared/services/order.service';
import { LoaderService } from '../loader/loader';
import { MenuService } from '../core/layout/menu.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AppSettings } from 'app/core/app-settings';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: [ './search.component.scss' ]
})
export class SearchComponent implements OnInit, OnDestroy {
    static visible = false;
    public order;
    public searchHandler = false;    
    public isChecked = true;

    private orderSubscription: Subscription;

    constructor(
        public dialog: MatDialog,
        private orderService: OrderService,
        private spinner: LoaderService,
        public menuService: MenuService,
        public appSettings: AppSettings
    ) { }

    ngOnInit() {
        this.spinner.hideLoader();
        this.orderSubscription = this.orderService.order.subscribe(order => {
            if (order !== null) {
                this.order = order;
                this.searchHandler = true;
            }
            if (this.menuService.isTimerTriggered) {
                if (this.menuService.isFromSearch) {
                    this.menuService.segmentRefreshNeeded = true;
                    this.menuService.isFromSearch = false;
                } else {
                    this.menuService.segmentRefreshNeeded = false;
                }
            } else {
                this.menuService.segmentRefreshNeeded = true;
            }
        });
    }
    public closeDialog(event) {
        this.menuService.hideMenu();
        if (event) { event.preventDefault(); }
    }
    ngOnDestroy() {
        this.orderSubscription.unsubscribe();
    }
}

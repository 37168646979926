import { Component, OnInit, AfterViewChecked, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params, NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { Order, OrderService, ErrorHandlerService, GoogleAnalyticsConstants, ImageService, Document } from '../../shared';
import { SearchComponent } from '../../track-another-order/search.component';
import { LoaderService } from '../../loader/loader';
import { MenuService } from '../../core/layout/menu.service';
import { PlatformLocation, Location } from '@angular/common';
import { ScrollToTopService, BackToTopComponent } from '../../scroll-to-top/scroll-to-top';
import { PageErrorComponent } from '../../shared/common-components/dialog/page-error/page-error.component';
import { AppSettings } from '../../core/app-settings';
import { I18nService } from '../../shared/services/i18n.service';
import { XPOConstants } from '../../shared/constants/xpo-constants';
import { SearchContainerComponent } from '../../track-another-order/search-container/search-container.component';
import { TrackOrderComponent } from '../../track-order/track-order/track-order.component';
import { ChatService } from '../../shared/services/chat.service';
import { DomSanitizer } from '@angular/platform-browser';

/**
* Component responisble for displaying the Tracking Order Landing page.
*
*/
@Component({
  selector: 'app-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewChecked {
  public dataLayer = (<any>window).dataLayer;
  public order: Order;
  public orders: Order[];
  public orderError;
  public selectedItem = 0;
  public MenuHolder: Array<any>;
  public dialogRef;
  public expanded;
  public locationPath = '';
  public dialogPageError: MatDialogRef<PageErrorComponent>;
  public dialogSearch: MatDialogRef<SearchComponent>;
  public isMobile: boolean;
  private isCalendarEligible = false;
  public errorResponse: any;
  public selectedValue;
  public searchedOrder;
  private billTo: string;
  private locationURL;
  private documents: Document[];
  public ordersHolder: any = [];
  private margin: number;
  private top_space: number;
  @ViewChild('trackOrderComponent') trackOrderComponent: TrackOrderComponent;
  @ViewChild('trackOrderComponentMobile') trackOrderComponentMobile: TrackOrderComponent;
  public hideMap: boolean;
  public showMultiAppointmentBanner;
  public rxoStr: string;
  public selectedItemName: '';
  constructor(private orderService: OrderService,
    private spinner: LoaderService,
    private changeDetectorRef: ChangeDetectorRef,
    public menuService: MenuService,
    public scrollToTopService: ScrollToTopService,
    private errorHandlerService: ErrorHandlerService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private location: PlatformLocation,
    public appSettings: AppSettings,
    private i18nService: I18nService,
    locationPath: Location,
    private imageService: ImageService,
    public chatService: ChatService,
    private sanitization: DomSanitizer
  ) {
    this.expanded = false;
    this.isMobile = this.menuService.isMobileDevice();
    this.margin = window.innerHeight >= XPOConstants.MOBILE_SIZE_LARGE ? XPOConstants.MARGIN_LARGE : XPOConstants.MARGIN_SMALL;
    this.top_space = window.innerHeight - XPOConstants.SEARCH_COMPONENT_SIZE - XPOConstants.CONTACT_COMPONENT_SIZE - this.margin;
    location.onPopState(() => {
      this.menuService.hideMenu();
    });
    this.locationPath = locationPath.path();
    this.rxoStr = this.appSettings.isNewDomain ? '-rxo' : '';
  }

  ngOnInit() {
    this.chatService.init();
    if (this.locationPath === '' || this.locationPath === '/fr'){
      this.i18nService.getLanguage();
    }
    this.orderService.order.subscribe(order => {
      this.spinner.hideLoader();
      if (this.order !== order) {
        this.order = order;
      }
      if (this.order) {
        this.menuService.isLandingPage = false;
        if(this.displayOrderImage(this.order)) {
            this.imageService.getDocuments(this.order.workOrderId).subscribe((docs) => {
              if(docs) {
                this.setUpMenu(docs);
              }
            })
          }
          else {
            this.setUpMenu();
          }
        this.showMultiAppointmentBanner = this.displayMultiAppointmentBanner(this.order);
      }
    });
    this.orderService.orders.subscribe(orders => {
      if (orders) {
        if (this.menuService.isMultiOrder) {
          this.ordersHolder = orders[ this.menuService.pageIndex ];
        } else {
          this.ordersHolder = orders[ 0 ];
        }
        this.orders = this.ordersHolder;
      }
    });
    this.errorHandlerService.httpErrorCode.subscribe(err => {
      this.errorResponse = err;
      if (this.errorResponse.name === 'TimeoutError') {
        this.errorResponse.status = 408;
      }
      if (this.billTo !== '' && this.errorResponse.status === 404) {
        this.errorResponse.status = 410;
      }
      if (this.errorResponse.status !== 422) {
        if (this.orderService.orderRefresh) {
          return;
        }
        this.spinner.hideLoader();
        this.dialog.open(PageErrorComponent, {
          data: {
            errorCode: this.errorResponse.status,
            errorText: this.errorResponse.statusText,
            errorMessage: this.errorResponse.message
          },
          disableClose: true,
          position: {top : this.isMobile ? (this.top_space/2) + 'px' : ''},
          maxWidth: '100%'
        });
      }
    });

    this.route.queryParams.subscribe(params => {
      this.billTo = '';
      this.hideMap = false;
      if(this.router.url.indexOf('e-sign') !== -1) {
        this.hideMap = true;
      }
      this.spinner.showLoader();
      if (!this.route.component) {
        this.spinner.hideLoader();
        return;
      }
      this.orderService.orderRefresh = false;
      if (params['orderid']) {
        if (this.menuService.paramTrigger) {
          this.menuService.segmentRefreshNeeded = true;
          this.menuService.needToUpdateCache = true;
          this.orderService.setOrderId(params['orderid']);

          this.orderService.searchOrder(params['orderid']).subscribe(() => {
          });
        } else {
          this.spinner.hideLoader();          
        }

      } else if (params['customerReference'] && params['billTo']) {
        this.billTo = params['billTo'];
        this.menuService.needToUpdateCache = true;
        this.orderService.searchByReferenceNumber(params['customerReference'], params['billTo']).subscribe((orderResponse) => {
        });
      } else if (params['customerReference'] && params['companyid']) {
        this.billTo = params['companyid'];
        this.menuService.needToUpdateCache = true;
        this.orderService.searchByReferenceNumber(params['customerReference'], params['companyid']).subscribe((orderResponse) => {
        });
      } else if (params['trackingnumber']) {
        this.menuService.needToUpdateCache = true;
        this.orderService.searchByReferenceNumber(params['trackingnumber'], '0').subscribe((orderResponse) => {
          this.menuService.isLandingPage = false;
        });
      } else if (Object.keys(params).find(key=> key.toLowerCase() === 'customerid' && params[key])) {
        let searchCriteria = {}
        Object.keys(params).forEach(key => {
          switch(key.toLowerCase()){
            case 'customerid': searchCriteria['customerIdentifier'] = params[key]; break;
            case 'phonenumber': searchCriteria['phoneNumber'] = params[key]; break;
            case 'customerordernumber': searchCriteria['customerOrderNumber'] = params[key]; break;
            case 'reference': searchCriteria['reference'] = params[key]; break;
          }
        })
        this.menuService.needToUpdateCache = true;
        this.orderService.searchByCustomerId(searchCriteria).subscribe((orderResponse) => {
          this.menuService.isLandingPage = false;
        });
      } else {
        this.spinner.hideLoader();
        this.menuService.isLandingPage = true;
        this.search();
      }

      if (this.router.url.split('?')[0] === '/') {
        this.menuService.isHomeSelected = true;
      } else {
        this.menuService.isHomeSelected = false;
      }
    });

    this.menuService.status.subscribe((status: number) => {
      if (!this.order) return;
      this.router.navigate([''], { queryParams: { orderid: this.order && this.order.orderId } });
      this.selectedItem = status;
      this.selectedItemName = this.appSettings.getMessage('HOME');
    });

    window.onresize = (e) => {
      this.isMobile = this.menuService.isMobileDevice();
    };
    if (this.locationPath === '' || this.locationPath === '/fr') {
      this.menuService.isLandingPage = true;
      this.search();
    } else {
      this.menuService.isLandingPage = false;
    }
    // When the user scrolls down 20px from the top of the page, show the button
    window.onscroll = () => {
      if (this.isMobile) {
        this.scrollToTopService.enableBackToTop();
      }
    };

  }

  public isHome(){
    const pathname = window.document.location.pathname;
    return pathname === '/' || pathname === '/fr/';
  }

  private setUpMenu(documents? : Document[]) {
    if (this.order) {
      this.MenuHolder = [
        { name: this.appSettings.getMessage('HOME'), icons: './assets/icons/icon-home' + this.rxoStr + '.svg' },
        { name: this.appSettings.getMessage('TRACK_ANOTHER_ORDER'), icons: './assets/icons/icon-search' + this.rxoStr + '.svg' },
        {
          name: this.appSettings.getMessage('ORDER_DETAILS'),
          icons: this.order.isException ? './assets/icons/icon-box-Exception.png' : './assets/icons/icon-box' + this.rxoStr + '.svg'
        },
        { name: this.appSettings.getMessage('PREPARATION_CHECKLIST'), icons: './assets/icons/icon-checklist' + this.rxoStr + '.svg' },
      ];
      if(this.order.isESignAvailable){
        this.MenuHolder.push({ name: this.appSettings.getMessage('ESign'), icons: './assets/icons/signature-icon.png' });
      }
      if(this.order.isPODAvailable && this.order.salesOrderCategoryId == 2){
        this.MenuHolder.push({ name: this.appSettings.getMessage('POD_PICKUP'), icons: './assets/icons/proof-delivery.png' });
      }
      else if(this.order.isPODAvailable){
        this.MenuHolder.push({ name: this.appSettings.getMessage('POD_DELIVERY'), icons: './assets/icons/proof-delivery.png' });
      }
      if (this.order.canAddToCalendar && !this.menuService.isIpadSafariBrowser()) {
        this.MenuHolder.push({ name: this.appSettings.getMessage('ADD_TO_CALENDAR'), icons: './assets/icons/icon-calendar' + this.rxoStr + '.svg' });
      }
      if (this.order.eligibleForReschedule) {
        this.MenuHolder.push({ name: this.appSettings.getMessage('RESCHEDULE'), icons: './assets/icons/icon-reschedule' + this.rxoStr + '.svg' });
      }
      this.MenuHolder.push({ name: this.appSettings.getMessage('CONTACT_US'), icons: './assets/icons/icon-smartphone' + this.rxoStr + '.svg' });

      if (this.menuService.isMobileDevice() && this.order.validated) {
        this.MenuHolder.push({
          name: this.appSettings.getMessage('NOTIFICATIONS'),
          icons: './assets/icons/icon-notification' + this.rxoStr + '.svg'
        });
      }
      this.MenuHolder.push({ name: this.appSettings.getMessage('PROFILE'), icons: './assets/icons/icon-profile' + this.rxoStr + '.svg' });

      if (documents && documents.length) {
        this.MenuHolder.splice(3, 0, {
          name: this.appSettings.getMessage('IMAGES'),
          icons: './assets/icons/icon-image' + this.rxoStr + '.svg'
        });
      }
      if ( this.order.salesOrderCategoryId === 8 && this.order.status === 'SH') {
        this.MenuHolder.splice(3, 0, {
          name: this.appSettings.getMessage('HOURS_OF_OPERATION'),
         icons: './assets/icons/icon-reschedule' + this.rxoStr + '.svg'
        });
      }
    }
  }

  public onMenuNavigation(indexNumber, menuName) {
    this.expanded = false;
    this.selectedItem = indexNumber;
    this.selectedItemName = menuName;
    this.locationURL = this.router.url.split('?')[0].replace(/\//g, '');


    switch (menuName) {
      case this.appSettings.getMessage('HOME'): {
        this.hideMap = false;
        this.openHome();
        break;
      }
      case this.appSettings.getMessage('TRACK_ANOTHER_ORDER'): {
        if (this.locationURL !== 'search') {
          this.hideMap = false;
          this.openSearchDialog();
        }
        break;
      }
      case this.appSettings.getMessage('ORDER_DETAILS'): {
        if (this.locationURL !== 'order-details') {
          this.hideMap = false;
          this.openOrderDetailsDialog();
        }
        break;
      }
      case this.appSettings.getMessage('CONTACT_US'): {
        if (this.locationURL !== 'contact-us') {
          this.hideMap = false;
          this.openContactUsPhone();
        }
        break;
      }

      case this.appSettings.getMessage('PROFILE'): {
        if ((!this.order.validated && this.locationURL !== 'verification') || (this.order.validated && this.locationURL !== 'profile')) {
          this.hideMap = false;
          this.openProfileDialog();
        }
        break;
      }
      case this.appSettings.getMessage('PREPARATION_CHECKLIST'): {
        if (this.locationURL !== 'check-list') {
          this.hideMap = false;
          this.openPreparationCheckList();
        }
        break;
      }
      case this.appSettings.getMessage('RESCHEDULE'): {
        if ((!this.order.validated && this.locationURL !== 'verification') || (this.order.validated && this.locationURL !== 'reschedule')) {
          this.hideMap = false;
          this.openRescheduler();
        }
        break;
      }
      case this.appSettings.getMessage('ADD_TO_CALENDAR'): {
        if ((!this.order.validated && this.locationURL !== 'verification') || (this.order.validated && this.locationURL !== 'add-to-calendar')) {
          this.hideMap = false;
          this.openCalendar();
        }
        break;
      }
      case this.appSettings.getMessage('NOTIFICATIONS'): {
        if (this.locationURL !== 'notification') {
          this.hideMap = false;
          this.openNotification();
        }
        break;
      }
      case this.appSettings.getMessage('ESign'): {
        this.hideMap = true;
        this.openESign();
        break;
      }
      case this.appSettings.getMessage('POD_PICKUP'): {
        this.hideMap = false;
        this.openPOD();
        break;
      }
      case this.appSettings.getMessage('POD_DELIVERY'): {
        this.hideMap = false;
        this.openPOD();
        break;
      }
      case this.appSettings.getMessage('IMAGES'): {
        if ((!this.order.validated && this.locationURL !== 'verification') || (this.order.validated && this.locationURL !== 'images')) {
          this.hideMap = false;
          this.openImages();
        }
        break;
      }
      case this.appSettings.getMessage('HOURS_OF_OPERATION'): {
        if (this.locationURL !== 'hours') {
          this.hideMap = false;
          this.openHoursOfOperationDialog();
        }
        break;
      }
      default: {
        break;
      }
    }
  }


  public toggleDraw() {
    this.expanded = !this.expanded;
  }

  private openHome() {
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.HOME,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.HOME
      }
    });
    this.router.navigate([''], { queryParams: { orderid: this.order && this.order.orderId } });
    this.hideProductDetailsIfMobile(true);
  }

  private openESign() {
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.ESIGN,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.ESIGN
      }
    });
    this.router.navigate(['e-sign'], { queryParams: { orderid: this.order && this.order.orderId } });
    this.hideProductDetailsIfMobile(true);
  }

  private openPOD() {
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.POD,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.POD
      }
    });
    this.router.navigate(['confirmation'], { queryParams: { orderid: this.order && this.order.orderId } });
    this.hideProductDetailsIfMobile(true);
  }

  private openSearchDialog() {
    this.spinner.showLoader();
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.SEARCH_ORDER,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.SEARCH_ORDER
      }
    });
    this.router.navigate(['search'], { queryParams: { orderid: this.order && this.order.orderId } });
    this.hideProductDetailsIfMobile(false);
  }

  private openOrderDetailsDialog() {
    this.spinner.showLoader();
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.ORDER_DETAILS,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.ORDER_DETAILS
      }
    });
    this.router.navigate(['order-details'], { queryParams: { orderid: this.order && this.order.orderId } });
    this.hideProductDetailsIfMobile(false);
  }

  private openEmailDialog() {
    this.spinner.showLoader();
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.CONTACTUS,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.CONTACTUS
      }
    });
    this.router.navigate(['email'], { queryParams: { orderid: this.order && this.order.orderId } });
    this.hideProductDetailsIfMobile(false);
  }

  private openPreparationCheckList() {
    this.spinner.showLoader();
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.PREPARATION_CHECKLIST,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.PREPARATION_CHECKLIST
      }
    });
    this.router.navigate(['check-list'], { queryParams: { orderid: this.order && this.order.orderId } });
    this.hideProductDetailsIfMobile(false);
  }

  private openContactUsPhone() {
    this.spinner.showLoader();
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.CONTACTUS,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.CONTACTUS
      }
    });
    this.router.navigate(['contact-us'], { queryParams: { orderid: this.order && this.order.orderId } });
    this.hideProductDetailsIfMobile(false);
  }

  private openProfileDialog() {
    this.spinner.showLoader();
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.PROFILE,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.PROFILE
      }
    });
    this.router.navigate(['profile'], { queryParams: { orderid: this.order && this.order.orderId } });
    this.hideProductDetailsIfMobile(false);
  }

  private openCalendar() {
    this.spinner.showLoader();
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.MYCALENDAR,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.MYCALENDAR
      }
    });
    this.router.navigate(['add-to-calendar'], { queryParams: { orderid: this.order && this.order.orderId } });
    this.hideProductDetailsIfMobile(false);
  }

  private openRescheduler() {
    this.spinner.showLoader();
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.RESCHEDULE,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.RESCHEDULE
      }
    });
    this.router.navigate(['reschedule'], { queryParams: { orderid: this.order && this.order.orderId } });
    this.hideProductDetailsIfMobile(false);
  }
  private openNotification() {
    this.spinner.showLoader();
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.NOTIFICATIONS,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.NOTIFICATIONS
      }
    });
    this.router.navigate(['notification'], { queryParams: { orderid: this.order && this.order.orderId } });
    this.hideProductDetailsIfMobile(false);
  }

  private openHoursOfOperationDialog() {
    this.spinner.showLoader();
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.HOURS_OF_OPERATION,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.HOURS_OF_OPERATION
      }
    });
    this.router.navigate(['hours'], { queryParams: { orderid: this.order && this.order.orderId } });
    this.hideProductDetailsIfMobile(false);
  }

  private hideProductDetailsIfMobile(status: boolean) {
    if (this.menuService.isMobileDevice()) {
      this.menuService.isHomeSelected = status;
      this.menuService.segmentRefreshNeeded = false;
      this.menuService.ismapRefreshNeeded = false;
    }
  }

  public search() {
    setTimeout(() => {
      this.menuService.isSearchDialogRequired = true;
      this.i18nService.fromSearchDialog = true;
      if(this.menuService.isMobileDevice() && !this.errorResponse){
        this.top_space = window.innerHeight - XPOConstants.CONTACT_COMPONENT_SIZE - XPOConstants.SEARCH_COMPONENT_SIZE - this.margin;
        let top = (this.top_space/2) + 'px';
        this.dialog.open(SearchContainerComponent, { disableClose: true, position: { top: top }, maxWidth: 'none' });
        let contactComponent = document.getElementById('contact-support');
        let contactComponenttop = window.innerHeight - XPOConstants.CONTACT_COMPONENT_SIZE - (this.top_space/2 ) + 'px';
        contactComponent.style.top = contactComponenttop;
      }
      else
      this.dialog.open(SearchContainerComponent, { disableClose: true});
    });
  }

  calculateTopPositionForContactComponent() {
    if (this.errorResponse) {
    this.top_space = window.innerHeight - XPOConstants.CONTACT_COMPONENT_SIZE - XPOConstants.ERROR_COMPONENT_SIZE - this.margin; 
    }
    let top = window.innerHeight - XPOConstants.CONTACT_COMPONENT_SIZE - (this.top_space/2 ) + 'px';
    return this.sanitization.bypassSecurityTrustStyle(`--top-value : ${top}`);
  }

  private openImages() {
    this.spinner.showLoader();
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.IMAGES,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.IMAGES
      }
    });
    this.router.navigate(['images'], { queryParams: { orderid: this.order && this.order.orderId } }).then(() => this.spinner.hideLoader());
    this.hideProductDetailsIfMobile(false);
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
    const chatElement = document.getElementById('icChatButton');
        if(chatElement && this.isMobile && !this.menuService.isLandingPage) {
          chatElement.style.marginBottom = '101px';
          if(this.errorResponse) {
            chatElement.style.marginBottom = '25px';
          }
        }
  }

  private displayOrderImage(order : Order) : boolean {
    return (order.validated && order.displayStopImages && order.isLMx
    && order.salesOrderCategoryId !==8 && order.status === 'DL');
  }

  private displayMultiAppointmentBanner(order: Order) : boolean {
    if (order.isMultiAppointment && order.salesOrderCategoryId === 1 && order.status !== 'CN') {
      if (order.exceptions && order.exceptions.length > 0 && order.exceptions.find(x => x.exceptionTypeId === 1)) {
        return false;
      }
      if (order.status !== 'DL' || (order.multiStopAssociatedWorkOrder && order.multiStopAssociatedWorkOrder.status !== 100 &&
        order.multiStopAssociatedWorkOrder.status !== 255 && order.multiStopAssociatedWorkOrder.operationalWorkFlowId !== 7 &&
        order.multiStopAssociatedWorkOrder.operationalWorkFlowId !== 8 && order.multiStopAssociatedWorkOrder.operationalWorkFlowId !== 9 &&
        order.multiStopAssociatedWorkOrder.operationalWorkFlowId !== 12)) {
          return true;
      }
    }  
    return false;
  }

  public openAssociatedWorkOrder() {  
    if (!this.menuService.isMultiOrder) {
      this.navigateToWorkOrder(this.order.multiStopAssociatedWorkOrder.workOrderId);
    }
    this.openOrderDetailsDialog();
  }

  public openScheduleAssociatedWorkOrder() {
    if (!this.menuService.isMultiOrder) {
      this.navigateToWorkOrder(this.order.multiStopAssociatedWorkOrder.workOrderId);
    } else {
      this.multiOrderNavigateToWorkOrder(this.order.multiStopAssociatedWorkOrder.workOrderId);
    }
    this.openRescheduler();
  }

  private navigateToWorkOrder(workOrderId: string) {
    const serviceOnlyIndex = this.orders.findIndex(x => x.workOrderId === workOrderId);
    if (serviceOnlyIndex > this.menuService.pageIndex) {
      this.navigateRight(serviceOnlyIndex, this.menuService.pageIndex);
    } else {
      this.navigateLeft(serviceOnlyIndex, this.menuService.pageIndex);
    }
  }

  private navigateRight(index: number, offset: number) {
    var clicks = index - offset;
    if (!this.isMobile) {
      for (let i=1;i<=clicks;i++) {
        this.trackOrderComponent.onRightClick();
      }
    } else {
      for (let i=1;i<=clicks;i++) {
        this.trackOrderComponentMobile.onRightClick();
      }
    }
  }

  private navigateLeft(index: number, offset: number) {
    var clicks = offset - index;
    if (!this.isMobile) {
      for (let i=1;i<=clicks;i++) {
        this.trackOrderComponent.onLeftClick();
      }
    } else {
      for (let i=1;i<=clicks;i++) {
        this.trackOrderComponentMobile.onLeftClick();
      }
    }
  }

  private multiOrderNavigateToWorkOrder(workOrderId: string) {
    const serviceOnlyIndex = this.orderService.orderValue[this.menuService.pageIndex].findIndex(workOrderId);
    if (serviceOnlyIndex > this.menuService.multiOrderCIpageIndex) {
      this.multiOrderNavigateRight(serviceOnlyIndex, this.menuService.multiOrderCIpageIndex);
    } else {
      this.multiOrderNavigateLeft(serviceOnlyIndex, this.menuService.multiOrderCIpageIndex);
    }
  }

  private multiOrderNavigateRight(index: number, offset: number) {
    var clicks = index - offset;
    if (!this.isMobile) {
      for (let i=1;i<=clicks;i++) {
        this.trackOrderComponent.multiOrderNavigateRight();
      }
    } else {
      for (let i=1;i<=clicks;i++) {
        this.trackOrderComponentMobile.multiOrderNavigateRight();
      }
    }
  }

  private multiOrderNavigateLeft(index: number, offset: number) {
    var clicks = offset - index;
    if (!this.isMobile) {
      for (let i=1;i<=clicks;i++) {
        this.trackOrderComponent.multiOrderNavigateLeft();
      }
    } else {
      for (let i=1;i<=clicks;i++) {
        this.trackOrderComponentMobile.multiOrderNavigateLeft();
      }
    }
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { MenuService } from '../core/layout/menu.service';
import { LoaderService } from '../loader/loader';
import { Order, OrderService, GoogleAnalyticsConstants } from '../shared';
import { Profile } from '../shared/model/profile';
import { Subscription } from 'rxjs';
import { PhonePipe } from '../shared/pipes/phone';
import { AppSettings } from '../core/app-settings';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.scss' ]
})
export class FooterComponent implements OnInit, OnDestroy {
  public rxoStr: string;
  public order: Order;
  private orderSubscription: Subscription;
  public enableLabel = 1;
  public mask = [ '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ];
  public locationPathURL;
  public XPOCustomerCarephoneNumber: any;
  

  constructor(
    public orderService: OrderService,
    public menuService: MenuService,
    private router: Router,
    private spinner: LoaderService,
    private phonePipe: PhonePipe,
    private appSettings: AppSettings
  ) { 
    this.rxoStr = this.appSettings.isNewDomain ? '-rxo' : '';
  }

  ngOnInit() {
    this.orderSubscription = this.orderService.order.subscribe(order => {
      if (order) {
        this.order = order;
      }
    });
    if (this.order.xpoPhoneNumber) {
     
      const newLocal = this;
      this.XPOCustomerCarephoneNumber=this.phonePipe.transform(this.order.xpoPhoneNumber,0)
      
    }
  }

  
  
  onClickAlertSubscription(event, subscribe: boolean) {
    this.locationPathURL = this.router.url.split('?')[ 0 ].replace(/\//g, '');
    if ((!this.order.validated && this.locationPathURL !== 'verification')
      || (this.order.validated && this.locationPathURL !== 'alerts')) {
      this.spinner.showLoader();
      this.appSettings.eventTrack({
        action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
        properties: {
          category: GoogleAnalyticsConstants.EVENT_CATEGORY.ALERT_SUBSCRIPTION,
          label: GoogleAnalyticsConstants.EVENT_CATEGORY.ALERT_SUBSCRIPTION
        }
      });
      this.router.navigate([ 'alerts' ], { queryParams: { orderid: this.order.orderId } });
      this.hideProductDetailsIfMobile();
      event.preventDefault();
    }
  }

  sendEmail() {
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.CONTACTUS,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.CONTACTUS
      }
    });
    this.router.navigate([ 'email' ], { queryParams: { orderid: this.order.orderId } });
    this.hideProductDetailsIfMobile();
  }
  hideProductDetailsIfMobile() {
    if (this.menuService.isMobileDevice()) {
      this.menuService.isHomeSelected = false;
      this.menuService.segmentRefreshNeeded = false;
      this.menuService.ismapRefreshNeeded = false;
    }
  }

  ngOnDestroy() {
    this.orderSubscription.unsubscribe();
  }
}

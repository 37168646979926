export class GoogleAnalyticsConstants {
    public static readonly EVENT_CATEGORY = {
        HOME: 'home',
        SEARCH_ORDER: 'searchOrder',
        ORDER_DETAILS: 'orderDetails',
        PREPARATION_CHECKLIST: 'preparationCheckList',
        PROFILE: 'profile',
        MYCALENDAR: 'myCalendar',
        RESCHEDULE: 'scheduler',
        NOTIFICATIONS: 'notifications',
        CALL_XPO: 'callXPO',
        CONTACTUS: 'contactUs',
        TRAFFIC_LAYER: 'trafficLayer',
        WEATHER_LAYER: 'weatherLayer',
        TRAFFIC_ALERTS: 'trafficAlerts',
        WEATHER_ALERTS: 'weatherAlerts',
        DOWNLOAD_XPO_EVENT: 'downloadXPOevent',
        ALERT_SUBSCRIPTION: 'alertSubscription',
        USER_AUTHENTICATION: 'userAuthentication',
        TRACKING_LANDING: 'trackingLanding',
        SURVEY: 'survey',
        UNSUBSCRIBE_EMAIL: 'unsubscribeEmailNotification',
        ESIGN: 'e-sign',
        POD: 'proofofdelivery',
        IMAGES: 'images',
        HOURS_OF_OPERATION: 'hoursOfOperation'
    };

    public static readonly EVENT_ACTION = {
        OPEN: 'open',
        CALL: 'call',
        DISABLE: 'disable',
        ENABLE: 'enable',
        SHOW: 'show',
        DOWNLOAD: 'download',
        SUBMIT: 'submit',
        SAVE: 'save',
        ON: 'on',
        OFF: 'off',
        JOB_SEARCH: 'xpoTrackingNumber',
        SEND_CODE: 'sendCode',
        SUBMIT_CODE: 'submitCode',
        RETAILER_SEARCH: 'retailerSearch',
        DELIVERY_DATE_SEARCH: 'deliveryDateSearch'
    };
}

import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { SharedModule } from '../shared/shared.module';
import { FooterModule } from '../footer/footer.module';
import { SearchComponent } from '../track-another-order/search.component';
import { CalendarDatepickerModule } from '../calendar-datepicker/calendar-datepicker.module';
import { TrackXpoNumberComponent } from './track-xpo-number/track-xpo-number.component';
import { TrackXpoReferenceNumberComponent } from './track-xpo-reference-number/track-xpo-reference-number.component';
import { TrackXpoDeliveryNumberComponent } from './track-xpo-delivery-number/track-xpo-delivery-number.component';
import { SearchContainerComponent } from './search-container/search-container.component';
import { TrackAnotherOrderTabComponent } from './track-another-order-tab/track-another-order-tab.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCardModule,
    MatTabsModule,
    MatRadioModule,
    CalendarDatepickerModule,
    SharedModule,
    FooterModule,
    NgxMaskDirective
  ],
  declarations: [
    SearchComponent,
    TrackXpoNumberComponent,
    TrackXpoReferenceNumberComponent,
    TrackXpoDeliveryNumberComponent,
    SearchContainerComponent,
    TrackAnotherOrderTabComponent ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [ DatePipe, provideNgxMask() ]
})
export class TrackAnotherOrderModule { }

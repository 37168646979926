<div [style]="checkVisibility()" id="contact-support" class="dialog-main">
  <mat-card class="detail-card no-scroll">
    <mat-card-subtitle class="sub-title-card thick" style="cursor: pointer"
      (click)="displayContacts()">
      <span i18n="@@TrackOrderModule-ContactSupportComponent-ContactUs">Contact Us</span>
      <i class="arrow" [ngClass]="{'up': showContact, 'down': !showContact}" style="float:right;"></i>
    </mat-card-subtitle>
    <div class="content-card" *ngIf="showContact">
      <div class="row-data">
        <img class="icon" src='./assets/icons/icon-call.svg'>
        <a (click)="openCallDialog()" class="contact-text">{{XPOCustomerCarephoneNumber}}</a>
      </div>
      <div class="row-data">
        <img class="icon" src='./assets/icons/icon-email.svg'>
        <a class="link-text" href="mailto:{{supportEmail}}?subject={{supportEmailSubject}}">{{supportEmail}}</a>
      </div>
    </div>
  </mat-card>
</div>
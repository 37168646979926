import { Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { AppSettings } from 'app/core/app-settings';

@Component({
  selector: 'app-search-container',
  templateUrl: './search-container.component.html',
  styleUrls: [ './search-container.component.scss' ]
})
export class SearchContainerComponent implements OnInit, AfterViewChecked {
  constructor(private changeDetectorRef: ChangeDetectorRef, public appSettings: AppSettings) {
  }
  ngOnInit() { }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }
}


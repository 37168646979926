<div id="search-dialog" class="tab-dialog-container" [ngClass]="appSettings.isNewDomain ? 'rxo-style' : 'xpo-style'">
  <mat-card-header class="title-card">
    <mat-card-title class="title-size" i18n="@@TrackAnotherOrderModule-SearchComponent-TrackAnotherOrder">Track A
      Shipment</mat-card-title>
    <mat-card-subtitle class="sub-title-card-small" i18n="@@TrackAnotherOrderModule-SearchComponent-FindOrder">Select an
      option below.</mat-card-subtitle>
  </mat-card-header>
  <div id='track-dialog'>
    <app-track-another-order-tab></app-track-another-order-tab>
  </div>
</div>
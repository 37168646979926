import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer.component';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhonePipe } from '../shared';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MatInputModule,
        FormsModule,
        MatSlideToggleModule,
        ReactiveFormsModule
    ],
    declarations: [
        FooterComponent,
    ],
    providers: [
        PhonePipe
      ],
    exports: [
        FooterComponent,
    ],

    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ]
})

export class FooterModule { }

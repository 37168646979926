import { Injectable } from '@angular/core';

/**
 * Class responsible for loading the google map script file into the html DOM
 */
@Injectable()
export class XpoGoogleMapLoader {

    private static readonly MAP_API_URL = 'https://maps.googleapis.com/maps/api/js';
    private static readonly SCRIPT_TAG = 'script';
    private static readonly SCRIPT_TYPE = 'text/javascript';
    private static readonly CALLBACK = 'initMap';
    private loadPromise: Promise<void>;

    // Method responsible for loading the google map js file
    public loadGoogleMapScript(apiKey: string, localeID: string): Promise<void> {
        if (this.loadPromise) {
            return this.loadPromise;
        }
        const script = document.createElement(XpoGoogleMapLoader.SCRIPT_TAG);
        script.type = XpoGoogleMapLoader.SCRIPT_TYPE;
        script.async = true;
        script.defer = true;
        script.src = `${XpoGoogleMapLoader.MAP_API_URL}?client=${apiKey}&language=${localeID}&libraries=geometry&callback=${XpoGoogleMapLoader.CALLBACK}`;
        this.loadPromise = new Promise<void>((resolve, reject) => {
            window[ XpoGoogleMapLoader.CALLBACK ] = () => {
                resolve();
            };
            script.onerror = error => {
                reject(error);
            };
        });
        // Appending the google js file to the body tag
        document.body.appendChild(script);
        return this.loadPromise;
    }
}

import { Injectable, NgZone } from '@angular/core';
import * as hello from 'hellojs/dist/hello.all.js';
import * as MicrosoftGraphClient from '@microsoft/microsoft-graph-client';
import { BaseLoginProvider } from '../providers/base-login-provider';


export interface AuthServiceConfigItem {
  id: string,
  provider: BaseLoginProvider
}

export class AuthServiceConfig {
  providers: Map<string, BaseLoginProvider> = new Map<string, BaseLoginProvider>();

  constructor(providers: AuthServiceConfigItem[]) {
    for (let i = 0; i < providers.length; i++) {
      const element = providers[ i ];
      this.providers.set(element.id, element.provider);
    }
  }
}
@Injectable()
export class AuthService {
  private static readonly LOGIN_PROVIDER_NOT_FOUND: string = 'Login provider not found';
  private providers: Map<string, BaseLoginProvider> = new Map<string, BaseLoginProvider>();

  constructor(config: AuthServiceConfig, private zone: NgZone) {
    this.providers = config.providers;

    this.providers.forEach((provider: BaseLoginProvider, key: string) => {
      provider.initialize().then(() => {
      }).catch((err) => {
      });
    });
  }

  public openSession(providerId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const providerObject = this.providers.get(providerId);
      if (providerObject) {
        providerObject.openSession().then(() => {
          resolve(true);
        }).catch(() => {
          reject();
        });
      } else {
        reject(AuthService.LOGIN_PROVIDER_NOT_FOUND);
      }
    });
  }

  public closeSession(providerId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const providerObject = this.providers.get(providerId);
      if (providerObject) {
        providerObject.closeSession().then(() => {
          resolve(true);
        });
      } else {
        reject(AuthService.LOGIN_PROVIDER_NOT_FOUND);
      }
    });
  }

  public getCalendarFreeBusyData(providerId, orders): Promise<any> {
    return new Promise((resolve, reject) => {
      const providerObject = this.providers.get(providerId);
      if (providerObject) {
        providerObject.getCalendarFreeBusyData(orders).then((response) => {
          resolve(response);
        }).catch(() => {
          reject();
        });
      } else {
        reject();
      }
    });
  }
  public addEventToCalendar(providerId, calendarAddEventObject): Promise<any> {
    return new Promise((resolve, reject) => {
      const providerObject = this.providers.get(providerId);
      if (providerObject) {
        providerObject.addEventToCalendar(calendarAddEventObject).then((response) => {
          resolve(response);
        }).catch(() => {
          reject();
        });
      } else {
        reject();
      }
    });
  }
}

<div class="calendar-component" id="calendar">
  <ng-template [ngIf]="true">
    <mat-form-field class="textbox-full-width">
      <input matInput #inputfield placeholder="Calendar" type="text" [attr.id]="inputId" [attr.name]="name" [attr.required]="required"
        [value]="inputFieldValue" (focus)="onInputFocus($event)" (keydown)="onInputKeydown($event)" (blur)="onInputBlur($event)"
        [readonly]="readonlyInput" (input)="onUserInput($event)" [ngStyle]="inputStyle" [class]="inputStyleClass" [placeholder]="placeholder||''"
        [attr.tabindex]="tabindex" [ngClass]="'ui-inputtext ui-widget ui-state-default ui-corner-all'" [formControl]="dateFormControl"
        name="date">
      <i class="material-icons material-calendar-icon" (click)="onButtonClick($event,inputfield)">&#xE916;</i>
      <mat-hint *ngIf="enableHint">MM/DD/YYYY</mat-hint>
      <mat-error *ngIf="dateFormControl.hasError('required')" i18n="@@Calendar-pickerModule-Calendar-DateIsRequired">
        Date is
        <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-template>
  <div #datepicker class="ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all" [ngClass]="{'ui-datepicker-inline':inline,'ui-shadow':!inline,'ui-state-disabled':disabled,'ui-datepicker-timeonly':timeOnly}"
    [ngStyle]="{'display': inline ? 'inline-block' : (overlayVisible ? 'block' : 'none')}" (click)="onDatePickerClick($event)"
    [@overlayState]="inline ? 'visible' : (overlayVisible ? 'visible' : 'hidden')">

    <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all" *ngIf="!timeOnly && (overlayVisible || inline)">
      <ng-content select="p-header"></ng-content>
      <i class="material-icons carousel-backward-btn" (click)="prevMonth($event)">&#xE314;</i>
      <i class="material-icons carousel-forward-btn" (click)="nextMonth($event)">&#xE315;</i>
      <div class="ui-datepicker-title">
        <span class="ui-datepicker-month" *ngIf="!monthNavigator">{{locale.monthNames[currentMonth]}}</span>
        <select class="ui-datepicker-month" *ngIf="monthNavigator" (change)="onMonthDropdownChange($event.target.value)">
          <option [value]="i" *ngFor="let month of locale.monthNames;let i = index" [selected]="i == currentMonth">{{month}}</option>
        </select>
        <select class="ui-datepicker-year" *ngIf="yearNavigator" (change)="onYearDropdownChange($event.target.value)">
          <option [value]="year" *ngFor="let year of yearOptions" [selected]="year == currentYear">{{year}}</option>
        </select>
        <span class="ui-datepicker-year" *ngIf="!yearNavigator">{{currentYear}}</span>
      </div>
    </div>
    <table class="ui-datepicker-calendar" *ngIf="!timeOnly && (overlayVisible || inline)">
      <thead class="thead-color">
        <tr>
          <th scope="col" *ngFor="let weekDay of weekDays;let begin = first; let end = last">
            <span>{{weekDay}}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let week of dates">
          <td *ngFor="let date of week" [ngClass]="{'ui-datepicker-other-month ui-state-disabled':date.otherMonth,
                                'ui-datepicker-current-day':isSelected(date),'ui-datepicker-today':date.today}">
            <a class="ui-state-default" href="#" *ngIf="date.otherMonth ? showOtherMonths : true" [ngClass]="{'ui-state-active':isSelected(date), 'ui-state-highlight':date.today, 'ui-state-disabled':!date.selectable}"
              (click)="onDateSelect($event,date)">
              <ng-container [disabledDates]="disabledDates" *ngIf="!dateTemplate">{{date.day}}</ng-container>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <ng-content select="p-footer"></ng-content>
  </div>
</div>

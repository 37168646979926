import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { I18nService } from '../../shared/services/i18n.service';

@Pipe({
    name: 'datex'
})

export class DatexPipe implements PipeTransform {
   
    constructor( private i18nService:I18nService) {
    }
    transform(value: any, format: string = ''): string {
        // Try and parse the passed value.
        const momentDate = moment(value);
        // If moment didn't understand the value, return it unformatted.
        if (!momentDate.isValid()) { return value; }
        // Otherwise, return the date formatted as requested.
        moment.locale(this.i18nService.localeID);
        return momentDate.format(format);
    }
}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Order, DatexPipe, GoogleAnalyticsConstants } from '../../shared';
import { OrderService } from '../../shared/services/order.service';
import { DatePipe } from '@angular/common';
import { MenuService } from '../../core/layout/menu.service';
import { I18nService } from '../../shared/services/i18n.service';
import { XPOConstants } from '../../shared/constants/xpo-constants';
import { FooterComponent } from '../../footer/footer.component';
import { LoaderService } from '../../loader/loader';
import { AppSettings } from '../../core/app-settings';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { OrderCategory } from '../../shared/model/order-category.enum';

/**
 * Component responisble for the order circular progress bar
 */
@Component({
  selector: 'app-track-order-progress',
  templateUrl: './track-order-progress.component.html',
  styleUrls: ['./track-order-progress.component.scss'],
  providers: [DatePipe, DatexPipe]
})

/* eslint-disable max-len */
export class TrackOrderProgressComponent implements OnInit, OnDestroy {
  private static readonly STATUS_PLACED: string = 'OP';
  private static readonly STATUS_PENDING: string = 'OP';
  private static readonly STATUS_SHIPPED: string = 'SH';
  private static readonly STATUS_TRANSIT: string = 'IT';
  public static readonly STATUS_DELIVERED: string = 'DL';
  public static readonly STATUS_CANCELLED: string = 'CN';
  private static readonly STATUS_EXCEPTION: string = 'EXP';
  private static readonly STATUS_RPS: string = 'RPS';
  private static readonly STATUS_IS: string = 'IS';
  private static readonly STATUS_PS: string = 'PS';
  private static readonly STATUS_LABEL_RUL: string = 'RUL';
  private static readonly STATUS_LABEL_ONT: string = 'ONT';
  private static readonly STATUS_LABEL_ON_BOARD: string = 'ONBOARD';
  private static readonly BELL_ICON_WIDTH: string = '14.63px';
  private static readonly BELL_ICON_HEIGHT: string = '13.36px';
  private static readonly TICK_ICON_SIZE: string = '20.67px';

  public order: Order;
  // @Input() order: Order;
  @Input() segmentIndex: number;
  public orders: any[];
  // unit properties
  public width = 300;
  public height = 300;
  public radius = 110;
  public centerPoint = 150;
  public circleOuterBlurStrokeWidth = 0;
  public circleInnerBlurStrokeWidth = 0;
  public circleStrokeWidth = 1;
  public circleBackgroundStrokeWidth = 20;
  public progressArcStrokeWidth = 12;
  public circularTextSpacing = -1;
  public circularFontSize = 13;
  public ringDistance: number = 360 / 16;
  public arcTextOrderPlaced = 20;
  public arcTextOrderPlacedFOrStatus1AndStatus4 = 30;
  public arcTextOrderPlacedFOrStatus2AndStatus3 = -25;
  public arcTextAtXPO = 75;
  public arcTextAtPICKUP = 75;
  public arcTextOutForDelivery = 40;
  public arcTextDelivered = 100;
  private orderSubscription: Subscription;
  // multi
  public subCIpageIndex = 0;
  public showCISegments = false;
  public indexSegment = 0;
  public arrowDisableLeft = false;
  public arrowDisableRight = true;
  private ciPageIndex = 0;
  public enableMultiCISegements = false;
  public appoinmentsCounter;
  public yDL = 65;
  public xDL = 0;
  //public isUIClick = false;

  // Color Codes
  public blue = '#0091ea';
  public darkGreen: string;
  public black = '#000000';
  public lightGrey = '#bdbdbd';
  public grey = '#9E9E9E';
  public transparentGrey: string;
  public red: string;
  public darkRed = '#b10000';
  public white = '#ffffff';
  public darkOrange: string;
  public lighter = 'lighter';
  public bold = 'bold';

  // Property for drawing the line inside the progress bar circle
  public lines: string[] = [];

  // Order ETA properties
  public takeSurvey = '';
  public schedule = '';
  public allowSchedule = false;
  public isException = false;
  public callHelpdesk = '';
  public callXPOInfo = '';
  public itemProgressStatus = '';
  public dateWindow = '';
  public itemStatusOrTimeWindow = '';
  public invalidStatus1 = '';
  public invalidStatus2 = '';
  public expiredStatus1 = '';
  public expiredStatus2 = '';
  public pendingStatus1 = '';
  public pendingStatus2 = '';
  public willCallOrderStatusMessage1 = '';
  public willCallOrderStatusMessage2 = '';
  public itemPlacementStatus = '';
  public itemStatusOrTimeWindowColor = '';
  public dateWindowColor = '';
  public itemProgressStatusColor = '';
  public itemStatusOrTimeWindowSize = 20;
  public dateWindowSize = 13;
  public itemProgressStatusSize = 13;
  public gradientOffset1 = '0%';
  public gradientOffset2 = '100%';
  public gradientOffset3 = '0%';
  public gradientOffset4 = '100%';
  public gradientColor1 = '';
  public gradientColor2 = '';
  public gradientColor3 = '';
  public gradientColor4 = '';
  public solidColor = '';
  public statusNumber = 2;
  public ringBorderColor = '';
  public segmentGradientId = '';
  public segmentGradientId1 = '';
  public segmentGradientUrl = '';
  public segmentGradientUrl1 = '';
  public orderStatusIcon = '';
  public progressSegmentationIcon = '';
  public progressSegmentationIcon1 = '';
  public progressSegmentationIcon2 = '';
  public progressSegmentationIcon3 = '';
  public progressSegmentationIcon4 = '';
  public progressSegmentationIcon5 = '';
  public exceptionIcon = '';
  public flag = 1;
  public xAxis: number;
  public smsOptStatus = '';
  public iconWidth = '';
  public iconHeight = '';
  public scheduledStatus = '';
  private locationPathURL;
  public isLmx = false;
  public orderStatus1 = '';
  public orderStatus2 = '';
  public orderStatus3 = '';
  public orderStatus4 = '';
  public orderStatus5 = '';
  public displayTimeWindow = false;
  public TimeWindowMessage = '';
  public estimatedHubLabel = '';
  public showDialLabelinRed = false;
  public defaultDisplay = false;
  public woHavingException = '';

  public isRXO: boolean;
  public itemStatusOrTimeWindowPrimary = '';
  public itemStatusOrTimeWindowSecondary = '';
  public yItemStatusOrTimeWindow = 0;
  public yItemStatusOrTimeWindowSecondary = 0;

  private static degreesToRadians(degrees): number {
    return (degrees * Math.PI) / 180;
  }

  constructor(
    private orderService: OrderService,
    private spinner: LoaderService,
    public menuService: MenuService,
    private i18nService: I18nService,
    private datePipe: DatePipe,
    private datexPipe: DatexPipe,
    private router: Router,
    private appSettings: AppSettings,
    private footerComponent: FooterComponent
  ) {
    for (let i = -150; i <= 150; i += this.ringDistance) {
      const transform = `rotate(${i}, ${this.centerPoint}, ${this.centerPoint})`;
      this.lines.push(transform);
    }
  }

  public getOrderCategoryById(id: number) {
    switch (id) {
      case 1:
        return 'Delivery';
      case 2:
        return 'Return';
      case 4:
        return 'Service Only';
      case 8:
        return 'Will Call';
      case 16:
        return 'Sweep';
      case 32:
        return 'RTV - Routable';
      case 64:
        return 'RTV - UnRoutable';
      default:
        break;
    }
  }
  public get scheduleOrderMsgApplicable() {
    if (this.order.isException) {
      return false;
    }
    if (this.order.salesOrderCategoryId === OrderCategory.WillCall) {
      return false;
    }
    if (this.order.salesOrderCategoryId === OrderCategory.Return) {
      return (
        this.order.currentStatus.toUpperCase() === TrackOrderProgressComponent.STATUS_SHIPPED ||
        this.order.currentStatus.toUpperCase() === TrackOrderProgressComponent.STATUS_PLACED
      );
    }
    return (
      this.order.currentStatus.toUpperCase() === TrackOrderProgressComponent.STATUS_TRANSIT ||
      this.order.currentStatus.toUpperCase() === TrackOrderProgressComponent.STATUS_SHIPPED ||
      this.order.currentStatus.toUpperCase() === TrackOrderProgressComponent.STATUS_PLACED
    );
  }

  public OrderCategoryMessages(id) {
    let msg = '';

    if (id === OrderCategory.Delivery) {
      msg = this.appSettings.getMessage('ORDER_CATEGORY_DELIVERY');
    } else if (id === OrderCategory.Return) {
      msg = this.appSettings.getMessage('ORDER_CATEGORY_RETURN_WILLCALL');
    } else if (id === OrderCategory.ServiceOnly) {
      msg = this.appSettings.getMessage('ORDER_CATEGORY_SERVICE');
    } else {
      msg = '';
    }
    return msg;
  }
  ngOnInit() {
    this.isRXO = this.appSettings.isNewDomain;
    this.yItemStatusOrTimeWindow = this.centerPoint - 20;
    this.yItemStatusOrTimeWindowSecondary = this.centerPoint;

    this.darkGreen = this.appSettings.isNewDomain ? '#00F49C' : '#52ae5f';
    this.red = this.appSettings.isNewDomain ? '#C62828' : '#c11a00';
    this.transparentGrey = this.appSettings.isNewDomain ? '#F2F2F2' : '#EEEEEE';
    this.darkOrange = this.appSettings.isNewDomain ? '#FF6D00' : '#e36209';
    this.menuService.isUIClick = false;
    if (this.i18nService.localeID === XPOConstants.I18N_FR) {
      this.arcTextOrderPlaced = -4;
      this.arcTextAtXPO = 70;
      this.arcTextAtPICKUP = 70;
      this.arcTextOutForDelivery = 30;
      this.arcTextDelivered = 110;
    }

    this.orderSubscription = this.orderService.order.subscribe(order => {
      if (order) {
        //debugger;
        if (this.menuService.soId == '') {
          this.menuService.soId = order.orderId;
        } else {
          if (this.menuService.soId != order.orderId) {
            this.menuService.multiOrderCIpageIndex = 0;
            this.menuService.isUIClick = false;
            this.menuService.soId = order.orderId;
          }
        }
        this.order = order;
        this.displayTimeWindow = false;
        this.isException = false;
        if (this.order.isException || (this.order.items != null && this.order.items.filter(x => x.isException === true).length > 0)) {
          this.isException = true;
        }

        if (
          this.isException === false &&
          this.order.deliveryServiceWindowStartTime == null &&
          this.order.orderScheduledDate &&
          (this.order.currentStatus == 'SH' || this.order.currentStatus == 'OP')
        ) {
          this.displayTimeWindow = true;
          switch (this.order.salesOrderCategoryId) {
            case 2:
              this.TimeWindowMessage = this.appSettings.getMessage('TIMEWINDOW_MESSAGE') + this.appSettings.getMessage('RETURN');
              break;
            case 4:
              this.TimeWindowMessage = this.appSettings.getMessage('TIMEWINDOW_MESSAGE') + this.appSettings.getMessage('SERVICE');
              break;
            default:
              this.TimeWindowMessage = this.appSettings.getMessage('TIMEWINDOW_MESSAGE') + this.appSettings.getMessage('DELIVERY');
              break;
          }
        }

        if (this.menuService.isMultiOrder) {
          this.subCIpageIndex = this.orderService.orderValue[this.menuService.pageIndex].length;
          if (this.subCIpageIndex > 1) {
            this.arrowDisableRight = false;
            this.enableMultiCISegements = true;
            //debugger;
            if (
              !this.menuService.isUIClick &&
              this.order.exceptions != null &&
              this.order.exceptions.find(ex => ex.exceptionTypeId === 1 && ex.exceptionCode.toUpperCase() === 'CANT-PERFORM-STOP')
            ) {
              this.woHavingException = this.order.workOrderId;
            }

            if (!this.menuService.isUIClick && !this.order.mergedFromWoId.includes(this.woHavingException) && this.woHavingException != '') {
              this.arrowDisableLeft = false;
              this.onRightClick(false);
            }
            this.appoinmentsCounter =
              this.appSettings.getMessage('APPOINTMENT') +
              ' ' +
              (this.menuService.multiOrderCIpageIndex + 1) +
              ' ' +
              this.appSettings.getMessage('OF') +
              ' ' +
              this.subCIpageIndex;
          } else {
            this.arrowDisableLeft = false;
            this.arrowDisableRight = false;
            this.enableMultiCISegements = false;
          }
        }
        if (this.menuService.multiOrderCIpageIndex === this.subCIpageIndex - 1) {
          this.arrowDisableRight = true;
        }
        this.fetchOrder(this.order);
      }
    });

    this.progressSegmentationIcon1 = './assets/icons/arrow-position-1.svg';
    this.progressSegmentationIcon2 = './assets/icons/arrow-position-2.svg';
    this.progressSegmentationIcon3 = './assets/icons/arrow-position-3.svg';
    this.progressSegmentationIcon4 = './assets/icons/arrow-position-4.svg';
    this.progressSegmentationIcon5 = './assets/icons/rounded-edge.svg';

    this.estimatedHubLabel = this.appSettings.getMessage('ORDER_ETA');

    if (this.enableMultiCISegements) {
      this.yItemStatusOrTimeWindow = this.centerPoint;
      this.yItemStatusOrTimeWindowSecondary = this.centerPoint + 15;
    }
  }
  private resetDial() {
    this.xAxis = this.centerPoint - 5;
    this.invalidStatus1 = '';
    this.invalidStatus2 = '';
    this.expiredStatus1 = '';
    this.expiredStatus2 = '';
    this.pendingStatus1 = '';
    this.pendingStatus2 = '';
    this.willCallOrderStatusMessage1 = '';
    this.willCallOrderStatusMessage2 = '';
    this.itemPlacementStatus = '';
    this.itemStatusOrTimeWindow = '';
    this.dateWindow = '';
    this.itemProgressStatus = '';
    this.callXPOInfo = '';
    this.callHelpdesk = '';
    this.takeSurvey = '';
    this.schedule = '';
    this.allowSchedule = false;
    this.orderStatusIcon = '';
    this.gradientColor1 = '';
    this.gradientColor2 = '';
    this.gradientColor3 = '';
    this.gradientColor4 = '';
    this.solidColor = '';
    this.ringBorderColor = '';
    this.segmentGradientId = '';
    this.segmentGradientId1 = '';
    this.segmentGradientUrl = '';
    this.segmentGradientUrl1 = '';
    this.smsOptStatus = '';
    this.iconWidth = '';
    this.iconHeight = '';
    this.scheduledStatus = '';
    this.itemStatusOrTimeWindowSize = 20;
    this.dateWindowSize = 13;
    this.itemProgressStatusSize = 13;
    this.segmentIndex = 0;
    this.showDialLabelinRed = false;
  }

  private isEmpty(value) {
    if (value) {
      return false;
    }
    return true;
  }
  /**
   * Method responsible for fetching the order
   */
  private fetchOrder(order: Order) {
    this.resetDial();

    // order.salesOrderCategoryId = 8;
    // order.currentStatus = TrackOrderProgressComponent.STATUS_DELIVERED;
    if (order.salesOrderCategoryId === 4) {
      //Service Only orders
      this.circularFontSize = 13;
      this.arcTextOrderPlacedFOrStatus2AndStatus3 = -15;
      this.arcTextOrderPlacedFOrStatus1AndStatus4 = 20;
      this.arcTextOutForDelivery = 56;
      this.arcTextAtPICKUP = 50;
      this.orderStatus1 = this.appSettings.getMessage('ORDER_PLACED');
      this.orderStatus2 = '';
      this.orderStatus3 = this.appSettings.getMessage('SERVICE_SCHEDULED');
      this.orderStatus4 = this.appSettings.getMessage('ENROUTE');
      this.orderStatus5 = this.appSettings.getMessage('SERVICE_ONLY_COMPLETED');
    } else if (order.salesOrderCategoryId === 2) {
      //It is return only
      this.circularFontSize = 13;
      this.arcTextOrderPlacedFOrStatus2AndStatus3 = -15;
      this.arcTextOrderPlacedFOrStatus1AndStatus4 = 20;
      this.arcTextDelivered = this.isRXO ? 65 : 80;
      this.arcTextOutForDelivery = 30;
      this.arcTextOrderPlaced = 8;
      this.orderStatus1 = this.appSettings.getMessage('PICKUP_SCHEDULED');
      this.orderStatus2 = '';
      this.orderStatus3 = this.appSettings.getMessage('ENROUTE');
      this.orderStatus4 = this.appSettings.getMessage('PICKED_UP_FROM_CONSUMER');
      this.orderStatus5 = this.appSettings.getMessage('RETURNED_TO_FACILITY');
    } else if (order.salesOrderCategoryId === 8) {
      this.arcTextOrderPlaced = 0;
      this.circularFontSize = 13;
      this.arcTextOrderPlacedFOrStatus2AndStatus3 = -15;
      this.arcTextOrderPlacedFOrStatus1AndStatus4 = 20;
      this.arcTextAtPICKUP = 125;
      this.arcTextDelivered = 110;
      //Will Call order
      this.orderStatus1 = this.appSettings.getMessage('ORDER_PLACED');
      this.orderStatus2 = '';
      this.orderStatus4 = '';
      this.orderStatus3 = this.appSettings.getMessage('READY_FOR_PICKUP');
      this.orderStatus5 = this.appSettings.getMessage('PICKUP_COMPLETED');
    } else {
      //Default
      this.arcTextOrderPlaced = this.isRXO ? 8 : 20;
      this.arcTextAtPICKUP = 75;
      this.arcTextDelivered = 100;
      this.orderStatus1 = this.appSettings.getMessage('ORDER_PLACED');
      this.orderStatus2 = this.appSettings.getMessage('AT_XPO');
      this.orderStatus3 = this.appSettings.getMessage('AT_PICKUP');
      this.orderStatus4 = this.appSettings.getMessage('OUT_FOR_DELIVERY');
      this.orderStatus5 = this.appSettings.getMessage('DELIVERED');
    }

    const isStopLevelException =
      order.exceptions != null && order.exceptions.find(ex => ex.exceptionTypeId === 1 && ex.exceptionCode.toUpperCase() === 'CANT-PERFORM-STOP');

    const isAllItemRefused =
      order?.items?.length > 0 &&
      order.items.filter(
        it => it.exceptions != null && it.exceptions.find(ex => ex.exceptionTypeId === 2 && ex.exceptionCode.toUpperCase() === 'CANT-PU/DEL-ITEM')
      ).length === order.items.length;

    if (
      (isAllItemRefused || isStopLevelException) &&
      order.currentStatus == 'DL' &&
      (order.salesOrderCategoryId === 2 || order.salesOrderCategoryId === 1 || order.salesOrderCategoryId == 4)
    ) {
      this.orderStatus5 = this.appSettings.getMessage('EXCEPTION_LABEL');
      this.showDialLabelinRed = true;
    }

    this.itemStatusOrTimeWindowColor = this.black;
    this.dateWindowColor = this.darkGreen;
    this.gradientColor1 = this.darkGreen;
    this.gradientColor2 = this.darkGreen;
    this.solidColor = this.darkGreen;
    this.ringBorderColor = this.blue;
    this.segmentGradientId = 'grad' + this.segmentIndex;
    this.segmentGradientId1 = 'grad' + (this.segmentIndex + 1);
    this.segmentGradientUrl = 'url(#' + this.segmentGradientId + ')';
    this.segmentGradientUrl1 = 'url(#' + this.segmentGradientId1 + ')';
    let orderStatus = order.currentStatus;
    this.scheduledStatus = order.scheduledStatus;
    this.isLmx = order.isLMx;

    const isTimeEmpty = this.isEmpty(order.deliveryServiceWindowStartTime) || this.isEmpty(order.deliveryServiceWindowEndTime);
    if (order.smsOptInStatus) {
      this.smsOptStatus = './assets/icons/icon-bell-green.svg';
      this.orderService.callfooterenabledisable(1);
    } else {
      this.smsOptStatus = './assets/icons/icon-bell-gray.svg';
      this.orderService.callfooterenabledisable(0);
    }
    if (this.isException) {
      this.setExceptionForOrderDial(order, orderStatus);
    } else {
      if (orderStatus) {
        orderStatus = orderStatus.toUpperCase();
        // Checking whether the order is on track/running late
        if (!(orderStatus === TrackOrderProgressComponent.STATUS_DELIVERED || orderStatus === TrackOrderProgressComponent.STATUS_CANCELLED)) {
          if (order.etaStatus === TrackOrderProgressComponent.STATUS_LABEL_RUL) {
            this.setOrderStatus(order, this.appSettings.getMessage('STATUS_LABEL_RUNNING_LATE'));
            this.itemProgressStatusColor = this.darkRed;
            this.itemStatusOrTimeWindowColor = this.darkRed;
            this.gradientColor2 = this.red;
            this.solidColor = this.darkOrange;
          } else if (order.etaStatus === TrackOrderProgressComponent.STATUS_LABEL_ONT) {
            this.setOrderStatus(order, this.appSettings.getMessage('STATUS_LABEL_ON_TRACK'));
          }
        }
        // Finding the last stop's date
        const lastStop = null;
        const lastStopNumber = 0;

        if (
          (order.salesOrderCategoryId === 2 || order.salesOrderCategoryId === 4) &&
          (orderStatus === TrackOrderProgressComponent.STATUS_DELIVERED ||
            orderStatus === TrackOrderProgressComponent.STATUS_TRANSIT ||
            orderStatus === TrackOrderProgressComponent.STATUS_SHIPPED ||
            orderStatus === TrackOrderProgressComponent.STATUS_PLACED ||
            orderStatus === TrackOrderProgressComponent.STATUS_RPS ||
            orderStatus === '')
        ) {
          this.setStatusForServiceAndReturnOnlyOrder(orderStatus, order);
        } else if (
          order.salesOrderCategoryId === 8 &&
          (orderStatus === TrackOrderProgressComponent.STATUS_PLACED ||
            orderStatus === TrackOrderProgressComponent.STATUS_SHIPPED ||
            orderStatus === TrackOrderProgressComponent.STATUS_DELIVERED)
        ) {
          this.setStatusForWillCallPickupOrder(orderStatus, order);
        } else {
          if (orderStatus !== TrackOrderProgressComponent.STATUS_DELIVERED) {
            if (orderStatus !== TrackOrderProgressComponent.STATUS_CANCELLED || orderStatus !== TrackOrderProgressComponent.STATUS_EXCEPTION) {
              this.itemStatusOrTimeWindowPrimary = null;
              this.itemStatusOrTimeWindowSecondary = null;
              const today = new Date();
              const todayDate = this.getFormattedDate(today);
              if (order.deliveryServiceWindowStartTime == null) {
                if (todayDate === this.getFormattedDate(order.orderScheduledDate)) {
                  this.itemStatusOrTimeWindow = this.getFormattedDate(order.orderScheduledDate);
                  this.itemStatusOrTimeWindowSize = 20;
                  this.dateWindow = this.appSettings.getMessage('TODAY');
                  this.dateWindowSize = 17;
                  this.dateWindowColor = this.black;
                  this.TimeWindowMessage = '';
                } else if (todayDate !== this.getFormattedDate(order.orderScheduledDate)) {
                  this.itemStatusOrTimeWindow = this.getFormattedDate(order.orderScheduledDate);
                  this.itemStatusOrTimeWindowSize = 18.5;
                }
              } else if (order.deliveryServiceWindowStartTime != null) {
                if (todayDate === this.getFormattedDate(order.orderScheduledDate)) {
                  this.itemStatusOrTimeWindow = this.getFormattedTime(order.deliveryServiceWindowStartTime, order.deliveryServiceWindowEndTime);
                  this.itemStatusOrTimeWindowSize = 20;
                  this.dateWindow = this.appSettings.getMessage('TODAY');
                  this.dateWindowSize = 17;
                  this.dateWindowColor = this.black;
                  this.TimeWindowMessage = '';
                } else if (todayDate !== this.getFormattedDate(order.orderScheduledDate)) {
                  this.itemStatusOrTimeWindow = this.getFormattedTime(order.deliveryServiceWindowStartTime, order.deliveryServiceWindowEndTime);
                  this.itemStatusOrTimeWindowSize = 20;
                  this.dateWindow = this.getFormattedDate(order.orderScheduledDate);
                  this.dateWindowSize = 17;
                  this.dateWindowColor = this.black;
                }
              }
            }
          } else {
            this.dateWindow = this.getFormattedDate(order.orderScheduledDate);
            this.dateWindowSize = 17;
            this.dateWindowColor = this.black;
          }
          this.setStatusNumber(orderStatus, order);
        }
      }
    }
  }

  private setOrderStatus(order, status) {
    const isTimeEmpty = this.isEmpty(order.deliveryServiceWindowStartTime) || this.isEmpty(order.deliveryServiceWindowEndTime);
    if (isTimeEmpty) {
      this.dateWindow = status;
    } else {
      this.itemProgressStatus = status;
      this.itemProgressStatusColor = this.darkGreen;
      if (order.orderScheduledDate) {
        this.itemStatusOrTimeWindowPrimary = null;
        this.itemStatusOrTimeWindowSecondary = null;
        this.itemStatusOrTimeWindow = (order.deliveryServiceWindowStartTime + ' - ' + order.deliveryServiceWindowEndTime).toUpperCase();
        this.dateWindowColor = this.black;
        this.dateWindowSize = 17;
      }
    }
  }
  // multi
  public onPageChange(page) {
    this.orderService.setOrder(this.orderService.orderValue[this.menuService.pageIndex][page]);
    this.menuService.onNavigateCloseDailog(this.order.orderId);
  }

  private rightArrowClick() {
    if (this.menuService.multiOrderCIpageIndex >= 1) {
      this.arrowDisableLeft = true;
    }
    if (this.menuService.multiOrderCIpageIndex === this.subCIpageIndex - 1) {
      this.arrowDisableRight = true;
    }
  }

  private leftArrowClick() {
    if (this.menuService.multiOrderCIpageIndex === 1) {
      this.arrowDisableLeft = false;
      this.arrowDisableRight = false;
    } else {
      this.arrowDisableLeft = true;
      this.arrowDisableRight = false;
    }
  }

  public onRightClick(defultClick) {
    this.menuService.isUIClick = defultClick;
    if (this.menuService.multiOrderCIpageIndex < this.subCIpageIndex - 1) {
      this.menuService.multiOrderCIpageIndex++;
      this.menuService.ismapRefreshNeeded = true;
      this.rightArrowClick();
      this.onPageChange(this.menuService.multiOrderCIpageIndex);
    }
  }

  public onLeftClick(defultClick) {
    this.menuService.isUIClick = defultClick;
    if (this.menuService.multiOrderCIpageIndex > 0) {
      this.leftArrowClick();
      this.menuService.multiOrderCIpageIndex--;
      this.menuService.ismapRefreshNeeded = true;
      this.onPageChange(this.menuService.multiOrderCIpageIndex);
    }
  }

  public onClickSMSOpt() {
    this.locationPathURL = this.router.url.split('?')[0].replace(/\//g, '');
    if ((!this.order.validated && this.locationPathURL !== 'verification') || (this.order.validated && this.locationPathURL !== 'alerts')) {
      this.spinner.showLoader();
      this.appSettings.eventTrack({
        action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
        properties: {
          category: GoogleAnalyticsConstants.EVENT_CATEGORY.ALERT_SUBSCRIPTION,
          label: GoogleAnalyticsConstants.EVENT_CATEGORY.ALERT_SUBSCRIPTION
        }
      });
      this.router.navigate(['alerts'], { queryParams: { orderid: this.order.orderId } });
      this.hideProductDetailsIfMobile();
    }
  }
  public callCustomerCare() {
    window.location.href = 'tel:' + this.order.xpoPhoneNumber;
  }
  public openSurvey() {
    this.locationPathURL = this.router.url.split('?')[0].replace(/\//g, '');
    if ((!this.order.validated && this.locationPathURL !== 'verification') || (this.order.validated && this.locationPathURL !== 'survey')) {
      this.spinner.showLoader();
      this.appSettings.eventTrack({
        action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
        properties: { category: GoogleAnalyticsConstants.EVENT_CATEGORY.SURVEY, label: GoogleAnalyticsConstants.EVENT_CATEGORY.SURVEY }
      });
      this.router.navigate(['survey'], { queryParams: { orderid: this.order.orderId } });
      this.hideProductDetailsIfMobile();
    }
  }
  private hideProductDetailsIfMobile() {
    if (this.menuService.isMobileDevice()) {
      this.menuService.isHomeSelected = false;
      this.menuService.segmentRefreshNeeded = false;
      this.menuService.ismapRefreshNeeded = false;
    }
  }

  public openOrderDetails() {
    const IsOrderDetail = this.router.url.includes('order-details');
    if (!IsOrderDetail) {
      this.spinner.showLoader();
      this.appSettings.eventTrack({
        action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
        properties: {
          category: GoogleAnalyticsConstants.EVENT_CATEGORY.ORDER_DETAILS,
          label: GoogleAnalyticsConstants.EVENT_CATEGORY.ORDER_DETAILS
        }
      });
      this.router.navigate(['order-details'], { queryParams: { orderid: this.order.orderId } });
      this.hideProductDetailsIfMobile();
    }
  }

  public openRescheduler() {
    this.spinner.showLoader();
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.OPEN,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.RESCHEDULE,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.RESCHEDULE
      }
    });
    if (this.router.url && this.router.url.includes('reschedule')) {
      this.spinner.hideLoader();
    }
    this.router.navigate(['reschedule'], { queryParams: { orderid: this.order.orderId } });
    this.hideProductDetailsIfMobile();
  }
  public getFormattedDate(date) {
    if (date) {
      moment.locale(this.i18nService.localeID);
      if (this.i18nService.localeID === XPOConstants.I18N_FR) {
        return moment(date).format('dddd D MMMM');
      } else {
        return moment(date).format('ddd, MMM D');
      }
    }
    return date;
  }

  private getFormattedTime(startDate: string, endDate: string): string {
    const startString = this.datexPipe.transform(startDate, 'hh:mm A');
    const endString = this.datexPipe.transform(endDate, 'hh:mm A');
    return `${startString}-${endString}`;
  }
  private setStatusForWillCallPickupOrder(status: string, order) {
    if (status !== TrackOrderProgressComponent.STATUS_PLACED) {
      const today = new Date();
      const todayDate = this.getFormattedDate(today);

      if (todayDate === this.getFormattedDate(order.orderScheduledDate)) {
        this.itemStatusOrTimeWindowPrimary = null;
        this.itemStatusOrTimeWindowSecondary = null;
        this.itemStatusOrTimeWindow = this.getFormattedDate(order.orderScheduledDate);
        this.itemStatusOrTimeWindowSize = 20;
        this.dateWindow = this.appSettings.getMessage('TODAY');
        this.dateWindowSize = 17;
        this.dateWindowColor = this.black;
        this.TimeWindowMessage = '';
      } else if (todayDate !== this.getFormattedDate(order.orderScheduledDate)) {
        this.dateWindow = this.getFormattedDate(order.orderScheduledDate);
        this.dateWindowSize = 18.5;
        this.dateWindowColor = this.black;
      }
    }

    if (status === TrackOrderProgressComponent.STATUS_SHIPPED) {
      this.statusNumber = 2;
      // for bell icon
      this.iconWidth = TrackOrderProgressComponent.BELL_ICON_WIDTH;
      this.iconHeight = TrackOrderProgressComponent.BELL_ICON_HEIGHT;
      this.itemPlacementStatus = this.scheduledStatus;
      this.willCallOrderStatusMessage1 = this.appSettings.getMessage('STATUS_LABEL_WILL_CALL_ORDER_SH');
      this.willCallOrderStatusMessage2 = order.shipper.pickupAddress;
      this.orderStatusIcon = this.smsOptStatus;
      this.dateWindow = '';
      this.displayTimeWindow = false;
      this.itemStatusOrTimeWindow = '';
    } else if (status === TrackOrderProgressComponent.STATUS_DELIVERED) {
      this.statusNumber = 4;
      // for delivery icon
      if (this.enableMultiCISegements) {
        this.yDL = 40;
        this.xDL = this.centerPoint - 11;
      } else {
        this.yDL = 60;
        this.xDL = this.centerPoint - 11;
      }
      this.iconWidth = TrackOrderProgressComponent.TICK_ICON_SIZE;
      this.iconHeight = TrackOrderProgressComponent.TICK_ICON_SIZE;
      this.itemStatusOrTimeWindow = '';
      this.itemStatusOrTimeWindowColor = this.black;
      this.itemStatusOrTimeWindowSize = 20;
      this.orderStatusIcon = this.isRXO ? './assets/icons/icon-progress-on-rxo.svg' : './assets/icons/icon-progress-on.svg';
      this.itemPlacementStatus = this.scheduledStatus;
      if (this.isRXO) {
        this.itemStatusOrTimeWindow = null;
        this.itemStatusOrTimeWindowPrimary = this.appSettings.getMessage('STATUS_LABEL_WILL_CALL_ORDER_DL_RXO_PRIMARY');
        this.itemStatusOrTimeWindowSecondary = this.appSettings.getMessage('STATUS_LABEL_WILL_CALL_ORDER_DL_RXO_SECONDARY');
      } else {
        this.itemStatusOrTimeWindowPrimary = null;
        this.itemStatusOrTimeWindowSecondary = null;
        this.itemStatusOrTimeWindow = this.appSettings.getMessage('STATUS_LABEL_WILL_CALL_ORDER_DL');
      }
    } else {
      this.statusNumber = 1;
      // for bell icon
      this.iconWidth = TrackOrderProgressComponent.BELL_ICON_WIDTH;
      this.iconHeight = TrackOrderProgressComponent.BELL_ICON_HEIGHT;
      this.willCallOrderStatusMessage1 = this.appSettings.getMessage('STATUS_LABEL_WILL_CALL_ORDER_PS_MESSAGE1');
      this.willCallOrderStatusMessage2 = this.appSettings.getMessage('STATUS_LABEL_WILL_CALL_ORDER_PS_MESSAGE2');
    }
  }

  private setStatusForServiceAndReturnOnlyOrder(status: string, order) {
    this.itemStatusOrTimeWindowPrimary = null;
    this.itemStatusOrTimeWindowSecondary = null;
    if (
      status !== TrackOrderProgressComponent.STATUS_DELIVERED &&
      (status !== TrackOrderProgressComponent.STATUS_TRANSIT || order.salesOrderCategoryId === 4)
    ) {
      const today = new Date();
      const todayDate = this.getFormattedDate(today);
      if (order.deliveryServiceWindowStartTime == null) {
        if (todayDate === this.getFormattedDate(order.orderScheduledDate)) {
          this.itemStatusOrTimeWindow = this.getFormattedDate(order.orderScheduledDate);
          this.itemStatusOrTimeWindowSize = 20;
          this.dateWindow = this.appSettings.getMessage('TODAY');
          this.dateWindowSize = 17;
          this.dateWindowColor = this.black;
          this.TimeWindowMessage = '';
        } else if (todayDate !== this.getFormattedDate(order.orderScheduledDate)) {
          this.itemStatusOrTimeWindow = this.getFormattedDate(order.orderScheduledDate);
          this.itemStatusOrTimeWindowSize = 18.5;
        }
      } else if (order.deliveryServiceWindowStartTime != null) {
        if (todayDate === this.getFormattedDate(order.orderScheduledDate)) {
          this.itemStatusOrTimeWindow = this.getFormattedTime(order.deliveryServiceWindowStartTime, order.deliveryServiceWindowEndTime);
          this.itemStatusOrTimeWindowSize = 20;
          this.dateWindow = this.appSettings.getMessage('TODAY');
          this.dateWindowSize = 17;
          this.dateWindowColor = this.black;
          this.TimeWindowMessage = '';
        } else if (todayDate !== this.getFormattedDate(order.orderScheduledDate)) {
          this.itemStatusOrTimeWindow = this.getFormattedTime(order.deliveryServiceWindowStartTime, order.deliveryServiceWindowEndTime);
          this.itemStatusOrTimeWindowSize = 20;
          this.dateWindow = this.getFormattedDate(order.orderScheduledDate);
          this.dateWindowSize = 17;
          this.dateWindowColor = this.black;
        }
      }
    } else {
      this.dateWindow = this.getFormattedDate(order.orderScheduledDate);
      this.dateWindowSize = 17;
      this.dateWindowColor = this.black;
    }

    if (status === TrackOrderProgressComponent.STATUS_SHIPPED || (status === TrackOrderProgressComponent.STATUS_PLACED && order.orderScheduledDate)) {
      this.statusNumber = 2;
      // for bell icon
      this.iconWidth = TrackOrderProgressComponent.BELL_ICON_WIDTH;
      this.iconHeight = TrackOrderProgressComponent.BELL_ICON_HEIGHT;
    } else if (status === TrackOrderProgressComponent.STATUS_TRANSIT && order.salesOrderCategoryId === 2) {
      this.statusNumber = 3;
      if (this.enableMultiCISegements) {
        this.yDL = 40;
        this.xDL = this.centerPoint - 11;
      } else {
        this.yDL = 60;
        this.xDL = this.centerPoint - 11;
      }
      this.iconWidth = TrackOrderProgressComponent.TICK_ICON_SIZE;
      this.iconHeight = TrackOrderProgressComponent.TICK_ICON_SIZE;
      this.itemStatusOrTimeWindow = this.appSettings.getMessage('STATUS_PICKUP_COMPLETED');
      this.itemStatusOrTimeWindowColor = this.black;
      this.itemStatusOrTimeWindowSize = 20;
      this.orderStatusIcon = this.isRXO ? './assets/icons/icon-progress-on-rxo.svg' : './assets/icons/icon-progress-on.svg';
    } else if (status === TrackOrderProgressComponent.STATUS_TRANSIT && order.salesOrderCategoryId === 4) {
      this.statusNumber = 3;
      // for bell icon
      this.iconWidth = TrackOrderProgressComponent.BELL_ICON_WIDTH;
      this.iconHeight = TrackOrderProgressComponent.BELL_ICON_HEIGHT;
    } else if (status === TrackOrderProgressComponent.STATUS_DELIVERED) {
      this.statusNumber = 4;
      // for delivery icon
      if (this.enableMultiCISegements) {
        this.yDL = 40;
        this.xDL = this.centerPoint - 11;
      } else {
        this.yDL = 60;
        this.xDL = this.centerPoint - 11;
      }
      this.iconWidth = TrackOrderProgressComponent.TICK_ICON_SIZE;
      this.iconHeight = TrackOrderProgressComponent.TICK_ICON_SIZE;
      if (this.isRXO) {
        this.itemStatusOrTimeWindow = null;
        this.itemStatusOrTimeWindowPrimary =
          order.salesOrderCategoryId === 2
            ? this.appSettings.getMessage('STATUS_PICKUP_COMPLETED_RXO_PRIMARY')
            : this.appSettings.getMessage('STATUS_INSTALL_SERVICE_RXO_PRIMARY');

        this.itemStatusOrTimeWindowSecondary =
          order.salesOrderCategoryId === 2
            ? this.appSettings.getMessage('STATUS_PICKUP_COMPLETED_RXO_SECONDARY')
            : this.appSettings.getMessage('STATUS_INSTALL_SERVICE_RXO_SECONDARY');
      } else {
        this.itemStatusOrTimeWindowPrimary = null;
        this.itemStatusOrTimeWindowSecondary = null;
        this.itemStatusOrTimeWindow =
          order.salesOrderCategoryId === 2
            ? this.appSettings.getMessage('STATUS_PICKUP_COMPLETED')
            : this.appSettings.getMessage('STATUS_INSTALL_SERVICE');
      }

      this.itemStatusOrTimeWindowColor = this.black;
      this.itemStatusOrTimeWindowSize = 20;
      this.orderStatusIcon = this.isRXO ? './assets/icons/icon-progress-on-rxo.svg' : './assets/icons/icon-progress-on.svg';
    } else {
      this.statusNumber = 1;
      if (this.order.eligibleForReschedule === true && !order.orderScheduledDate) {
        if (order.salesOrderCategoryId === 2) {
          this.invalidStatus2 = this.appSettings.getMessage('STATUS_LABEL_RPS_SCHEDULE_PICKUP');
        }
        if (order.salesOrderCategoryId === 4) {
          this.invalidStatus2 = this.appSettings.getMessage('STATUS_LABEL_RPS_SCHEDULE_SERVICE');
        }
        this.schedule = this.appSettings.getMessage('RESCHEDULE');
        this.allowSchedule = true;
        order.currentStatus = 'RPS';

        // reschedule icon
        this.orderStatusIcon = './assets/icons/icon-reschedule.svg';
        this.iconWidth = TrackOrderProgressComponent.TICK_ICON_SIZE;
        this.iconHeight = TrackOrderProgressComponent.TICK_ICON_SIZE;
        if (this.enableMultiCISegements) {
          this.yDL = 5;
          this.xDL = this.centerPoint - 8;
        } else {
          this.yDL = -15;
          this.xDL = this.centerPoint - 8;
        }
      }
      this.itemPlacementStatus = this.scheduledStatus;
    }
  }

  private setExceptionForOrderDial(order, status) {
    status = status.toUpperCase();
    this.yDL = 25;
    this.xDL = this.centerPoint - 11;
    this.iconWidth = TrackOrderProgressComponent.TICK_ICON_SIZE;
    this.iconHeight = TrackOrderProgressComponent.TICK_ICON_SIZE;
    this.exceptionIcon = './assets/icons/icon-exception.png';

    if (status === TrackOrderProgressComponent.STATUS_SHIPPED || status === TrackOrderProgressComponent.STATUS_RPS) {
      //displaying red bar for exception at statusNumber 2
      if (this.order.salesOrderCategoryId == 8) {
        this.statusNumber = 2;
        this.gradientColor3 = this.red;
        this.gradientColor4 = this.white;
        this.gradientOffset3 = '100%';
        this.gradientOffset4 = '0%';
      } else {
        this.statusNumber = 2;
        this.gradientColor3 = this.red;
        this.gradientColor4 = this.white;
        this.gradientOffset3 = '50%';
        this.gradientOffset4 = '0%';
      }
    } else if (status === TrackOrderProgressComponent.STATUS_TRANSIT || status === TrackOrderProgressComponent.STATUS_LABEL_ON_BOARD) {
      this.statusNumber = 3;
      this.gradientColor1 = this.darkGreen;
      this.gradientColor2 = this.red;
      this.gradientOffset1 = '50%';
      this.gradientOffset2 = '50%';
    } else if (status === TrackOrderProgressComponent.STATUS_DELIVERED) {
      //displaying red bar for exception at statusNumber 4
      if (this.order.salesOrderCategoryId == 8) {
        this.statusNumber = 3;
        this.gradientColor3 = this.red;
        this.gradientColor4 = this.red;
        this.gradientOffset3 = '0%';
        this.gradientOffset4 = '100%';
      } else {
        this.statusNumber = 4;
        this.gradientColor3 = this.red;
        this.gradientColor4 = this.red;
        this.gradientOffset3 = '0%';
        this.gradientOffset4 = '100%';
      }
    } else {
      if (this.order.salesOrderCategoryId == 8) {
        this.statusNumber = 1;
        this.gradientColor2 = this.red;
        this.gradientColor1 = this.red;
      } else {
        this.statusNumber = 1;
        this.gradientColor2 = this.red;
        this.gradientColor1 = this.red;
      }
    }
  }

  /**
   * Method responsible for setting the statusNumber which helps drawing the progress bar
   * Based on the status parameter, statusNumber is set
   * @param status
   */
  private setStatusNumber(status: string, order) {
    this.itemStatusOrTimeWindowPrimary = null;
    this.itemStatusOrTimeWindowSecondary = null;
    if (status === TrackOrderProgressComponent.STATUS_PENDING && !order.orderScheduledDate) {
      this.statusNumber = 1;
      // for bell icon
      this.iconWidth = TrackOrderProgressComponent.BELL_ICON_WIDTH;
      this.iconHeight = TrackOrderProgressComponent.BELL_ICON_HEIGHT;
      this.itemPlacementStatus = this.appSettings.getMessage('STATUS_LABEL_ACTION_REQUIRED');
      this.itemStatusOrTimeWindow = this.appSettings.getMessage('STATUS_LABEL_CONTACT_XPO').replace('[DOMAIN_NAME]', this.appSettings.domainName);
      this.dateWindow = this.appSettings.getMessage('STATUS_LABEL_NOT_SCHEDULED');
      this.itemStatusOrTimeWindowColor = this.darkRed;
      this.dateWindowColor = this.darkRed;
      this.itemStatusOrTimeWindowSize = 29;
      this.callXPOInfo = this.appSettings.getMessage('STATUS_LABEL_CALL_INFO');
      this.callHelpdesk = order.xpoPhoneNumber;
      this.orderStatusIcon = this.smsOptStatus;
    } else if (status === TrackOrderProgressComponent.STATUS_PLACED) {
      this.statusNumber = 1;
      // for bell icon
      this.iconWidth = TrackOrderProgressComponent.BELL_ICON_WIDTH;
      this.iconHeight = TrackOrderProgressComponent.BELL_ICON_HEIGHT;
      this.itemPlacementStatus = this.scheduledStatus;
      this.orderStatusIcon = this.smsOptStatus;
    } else if (status === TrackOrderProgressComponent.STATUS_SHIPPED) {
      this.statusNumber = 2;
      // for bell icon
      this.iconWidth = TrackOrderProgressComponent.BELL_ICON_WIDTH;
      this.iconHeight = TrackOrderProgressComponent.BELL_ICON_HEIGHT;
      this.itemPlacementStatus = this.scheduledStatus;
      this.orderStatusIcon = this.smsOptStatus;
    } else if (status === TrackOrderProgressComponent.STATUS_TRANSIT || status === TrackOrderProgressComponent.STATUS_LABEL_ON_BOARD) {
      this.statusNumber = 3;
      // for bell icon
      this.iconWidth = TrackOrderProgressComponent.BELL_ICON_WIDTH;
      this.iconHeight = TrackOrderProgressComponent.BELL_ICON_HEIGHT;
      this.itemPlacementStatus = this.scheduledStatus;
      this.gradientOffset1 = '50%';
      this.gradientOffset2 = '50%';
      this.orderStatusIcon = this.smsOptStatus;
    } else if (status === TrackOrderProgressComponent.STATUS_DELIVERED) {
      this.statusNumber = 4;
      // for delivery icon
      if (this.enableMultiCISegements) {
        this.yDL = 45;
        this.xDL = this.centerPoint - 8;
      } else {
        this.yDL = 65;
        this.xDL = this.centerPoint - 8;
      }
      this.iconWidth = TrackOrderProgressComponent.TICK_ICON_SIZE;
      this.iconHeight = TrackOrderProgressComponent.TICK_ICON_SIZE;

      this.itemStatusOrTimeWindow = this.appSettings.getMessage('STATUS_LABEL_DELIVERED');
      this.itemStatusOrTimeWindowColor = this.black;
      this.itemStatusOrTimeWindowSize = 29;
      this.orderStatusIcon = this.isRXO ? './assets/icons/icon-progress-on-rxo.svg' : './assets/icons/icon-progress-on.svg';
      if (this.order.autoSurveyOptInStatus) {
        this.takeSurvey = this.appSettings.getMessage('STATUS_LABEL_TAKE_SURVEY');
      }
    } else if (status === TrackOrderProgressComponent.STATUS_CANCELLED) {
      this.statusNumber = 1;
      this.itemPlacementStatus = this.appSettings.getMessage('STATUS_LABEL_ORDER');
      this.itemStatusOrTimeWindow = this.appSettings.getMessage('STATUS_LABEL_CANCELLED');
      this.itemStatusOrTimeWindowColor = this.darkRed;
      this.itemStatusOrTimeWindowSize = 29;
      this.gradientColor1 = this.red;
      this.gradientColor2 = this.red;
      this.solidColor = this.red;
      this.ringBorderColor = this.lightGrey;
      this.dateWindow = '';
      this.itemProgressStatus = '';
      this.orderStatusIcon = this.smsOptStatus;
    } else if (status === TrackOrderProgressComponent.STATUS_EXCEPTION) {
      this.statusNumber = 1;
      this.expiredStatus1 = this.appSettings.getMessage('STATUS_LABEL_EXCEPTION_MESSAGE1');
      this.expiredStatus2 = this.appSettings.getMessage('STATUS_LABEL_EXCEPTION_MESSAGE2');
      this.gradientColor1 = this.red;
      this.gradientColor2 = this.red;
      this.solidColor = this.red;
      this.ringBorderColor = this.lightGrey;
      this.itemStatusOrTimeWindow = '';
      this.dateWindow = '';
      this.itemProgressStatus = '';
    } else if (status === TrackOrderProgressComponent.STATUS_IS) {
      this.statusNumber = 3;
      this.invalidStatus1 = this.appSettings.getMessage('STATUS_LABEL_IS_MESSAGE1');
      this.invalidStatus2 = this.appSettings.getMessage('STATUS_LABEL_IS_MESSAGE2').replace('[DOMAIN_NAME]', this.appSettings.domainName);
      this.gradientColor1 = this.red;
      this.gradientColor2 = this.red;
      this.solidColor = this.red;
      this.ringBorderColor = this.lightGrey;
      this.itemStatusOrTimeWindow = '';
      this.dateWindow = '';
      this.itemProgressStatus = '';
    } else if (status === TrackOrderProgressComponent.STATUS_PS) {
      this.statusNumber = 1;
      this.pendingStatus1 = this.appSettings.getMessage('STATUS_LABEL_PS_MESSAGE1');
      this.pendingStatus2 = this.appSettings.getMessage('STATUS_LABEL_PS_MESSAGE2');
      this.itemStatusOrTimeWindow = '';
      this.dateWindow = '';
      this.itemProgressStatus = '';
    } else if (status === TrackOrderProgressComponent.STATUS_RPS) {
      this.statusNumber = 2;
      if (this.order.eligibleForReschedule) {
        this.invalidStatus2 = this.appSettings.getMessage('STATUS_LABEL_RPS_SCHEDULE');
        this.schedule = this.appSettings.getMessage('RESCHEDULE');
        this.allowSchedule = true;
        // reschedule icon
        this.orderStatusIcon = './assets/icons/icon-reschedule.svg';
        this.iconWidth = TrackOrderProgressComponent.TICK_ICON_SIZE;
        this.iconHeight = TrackOrderProgressComponent.TICK_ICON_SIZE;
        if (this.enableMultiCISegements) {
          this.yDL = 5;
          this.xDL = this.centerPoint - 8;
        } else {
          this.yDL = -15;
          this.xDL = this.centerPoint - 8;
        }
      } else {
        this.invalidStatus2 = this.appSettings.getMessage('STATUS_LABEL_RPS');
      }
      this.itemStatusOrTimeWindow = '';
      this.dateWindow = '';
      this.itemProgressStatus = '';
    } else {
      this.statusNumber = 0;
      this.orderStatusIcon = this.smsOptStatus;
    }
    this.segmentGradientUrl += ` ${this.solidColor}`;
  }

  get innerCircleRadius(): number {
    return this.radius - 9;
  }

  get outerCircleRadius(): number {
    return this.radius + 9;
  }

  get arc1(): string {
    const x1 = this.centerPoint - this.radius;
    let y1 = this.centerPoint;
    const x2 = this.centerPoint;
    const y2 = this.centerPoint + this.radius;
    if (this.i18nService.localeID === XPOConstants.I18N_FR) {
      y1 = y1 - 10;
    }
    return `M${x1},${y1} A${this.radius},${this.radius} 0,0,0 ${x2},${y2}`;
  }

  get arc2(): string {
    const x1 = this.centerPoint - this.radius;
    const y1 = this.centerPoint;
    let x2 = this.centerPoint;
    let y2 = this.centerPoint - this.radius;
    //Will Call Order
    if (this.order && this.order.salesOrderCategoryId == 8) {
      x2 = 210;
      y2 = 60;
    }
    return `M${x1},${y1} A${this.radius},${this.radius} 0,0,1 ${x2},${y2}`;
  }

  get arc3(): string {
    const x1 = this.centerPoint;
    const y1 = this.centerPoint - this.radius;
    const x2 = this.centerPoint + this.radius;
    const y2 = this.centerPoint;
    return `M${x1},${y1} A${this.radius},${this.radius} 0,0,1 ${x2},${y2}`;
  }

  get arc4(): string {
    const x1 = this.centerPoint;
    const y1 = this.centerPoint + this.radius;
    const x2 = this.centerPoint + this.radius;
    const y2 = this.centerPoint;
    return `M${x1},${y1} A${this.radius},${this.radius} 0,0,0 ${x2},${y2}`;
  }

  get progressArc(): string {
    const radian1 = TrackOrderProgressComponent.degreesToRadians(120);
    const radian2 = TrackOrderProgressComponent.degreesToRadians(60);
    const x1 = this.radius * Math.cos(radian1) + this.centerPoint;
    const y1 = this.radius * Math.sin(radian1) + this.centerPoint;
    const x2 = this.radius * Math.cos(radian2) + this.centerPoint;
    const y2 = this.radius * Math.sin(radian2) + this.centerPoint;
    return `M${x1},${y1} A${this.radius},${this.radius} 0 1,1 ${x2},${y2}`;
  }

  get progressArcBorder1(): string {
    const radian1 = TrackOrderProgressComponent.degreesToRadians(120);
    const radian2 = TrackOrderProgressComponent.degreesToRadians(60);
    const r = this.radius + this.progressArcStrokeWidth / 2;
    const x1 = r * Math.cos(radian1) + this.centerPoint;
    const y1 = r * Math.sin(radian1) + this.centerPoint;
    const x2 = r * Math.cos(radian2) + this.centerPoint;
    const y2 = r * Math.sin(radian2) + this.centerPoint;
    return `M${x1},${y1} A${r},${r} 0 1,1 ${x2},${y2}`;
  }

  get progressArcBorder2(): string {
    const radian1 = TrackOrderProgressComponent.degreesToRadians(120);
    const radian2 = TrackOrderProgressComponent.degreesToRadians(60);
    const r = this.radius - this.progressArcStrokeWidth / 2;
    const x1 = r * Math.cos(radian1) + this.centerPoint;
    const y1 = r * Math.sin(radian1) + this.centerPoint;
    const x2 = r * Math.cos(radian2) + this.centerPoint;
    const y2 = r * Math.sin(radian2) + this.centerPoint;
    return `M${x1},${y1} A${r},${r} 0 1,1 ${x2},${y2}`;
  }

  get progressArcLength(): number {
    let length = (2 * Math.PI * this.radius * 300) / 360;
    if (this.order) {
      //Will Call Order
      if (this.order.salesOrderCategoryId == 8) {
        if (this.statusNumber == 1) {
          if (this.isException) {
            length = 430;
          }
        }
      }
    }
    return length;
  }

  //setting length for path1 to display red bar for exception at statusNumber 2 and 4
  get progressArcLength1(): number {
    let length = (2 * Math.PI * this.radius * 300) / 360;
    if (this.order) {
      //Will Call Order
      if (this.order.salesOrderCategoryId == 8) {
        if (this.statusNumber == 2) {
          if (this.isException) {
            length = 215;
          }
        } else if (this.statusNumber == 3) {
          if (this.isException) {
            length = 525;
          }
        }
      } else {
        if (this.statusNumber == 2) {
          if (this.isException) {
            length = 250;
          }
        } else if (this.statusNumber == 4) {
          if (this.isException) {
            length = 433;
          }
        }
      }
    }
    return length;
  }

  get progressArcLengthFill(): number {
    let lengthFill = this.progressArcLength - (2 * Math.PI * this.radius * (this.statusNumber * 75)) / 360;
    if (this.order) {
      //Will Call Order
      if (this.order.salesOrderCategoryId == 8) {
        if (this.statusNumber == 1) {
          if (this.isException) {
            lengthFill = 245;
          } else {
            lengthFill = 390;
          }
        } else if (this.statusNumber == 2) {
          lengthFill = 175;
        } else if (this.statusNumber == 3) {
          lengthFill = 0;
        } else {
          lengthFill = 0;
        }
      }
    }
    return lengthFill;
  }

  //setting length for path1 to display red bar for exception at statusNumber 2 and 4
  get progressArcLengthFill1(): number {
    let lengthFill = this.progressArcLength - (2 * Math.PI * this.radius * (this.statusNumber * 75)) / 360;
    if (this.order) {
      //Will Call Order
      if (this.order.salesOrderCategoryId == 8) {
        if (this.statusNumber == 1) {
          lengthFill = 390;
        } else if (this.statusNumber == 2) {
          if (this.isException) {
            lengthFill = 245;
          } else {
            lengthFill = 175;
          }
        } else if (this.statusNumber == 3) {
          if (this.isException) {
            lengthFill = 645;
          } else {
            lengthFill = 0;
          }
        } else {
          lengthFill = 0;
        }
      } else {
        if (this.statusNumber == 4) {
          if (this.isException) {
            lengthFill = 434;
          }
        } else if (this.statusNumber == 2) {
          if (this.isException) {
            lengthFill = 354;
          }
        }
      }
    }
    return lengthFill;
  }

  get liney1(): number {
    return this.centerPoint - this.radius - this.progressArcStrokeWidth / 2;
  }

  get liney2(): number {
    return this.centerPoint - this.radius + this.progressArcStrokeWidth / 2;
  }

  ngOnDestroy() {
    this.orderSubscription.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { OrderService } from './order.service';

@Injectable()
export class AuthenticateService  {
  public order;
  constructor(
    private router: Router, private orderService: OrderService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.orderService.order.subscribe(order => {
      if (order) {
        this.order = order;
      }
    });
    if (this.order.validated === false) {
      this.router.navigate([ 'verification' ], { queryParams: { orderid: this.order.orderId, state: state.url } });
      return false;
    }
    return true;
  }

}

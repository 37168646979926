import { Component, OnInit, Input } from '@angular/core';
import { AppSettings } from '../../core/app-settings';
import { MenuService } from '../../core/layout/menu.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: [ './confirmation.component.scss' ]
})
export class ConfirmationComponent implements OnInit {
  @Input() confirmationText: string;

  constructor(private menuService: MenuService, public appSettings: AppSettings) { }

  ngOnInit() {
  }
  public closeDialog(event) {
    this.menuService.hideMenu();
    if (event) { event.preventDefault(); }
  }
}

import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class UnSecureHttp extends HttpClient {

  private TIMEOUT = 3000; // to be verified

  constructor(public connectionBackend: HttpBackend) {
    super(connectionBackend);
  }

}
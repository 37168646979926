<div class="progress-bar">
  <div class="will-call-div" [class.will-call-multiple-wo]="enableMultiCISegements">
    <text *ngIf="order.salesOrderCategoryId === 8" class="wc-status-message"
      [attr.fill]="black">{{willCallOrderStatusMessage1}}</text>
    <text *ngIf="order.salesOrderCategoryId === 8" class="wc-status-sub-message"
      [attr.fill]="black">{{willCallOrderStatusMessage2}}</text>
  </div>
  <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height">
    <g fill="none">
      <circle [attr.cx]="centerPoint" [attr.cy]="centerPoint" [attr.r]="outerCircleRadius"
        [attr.stroke-width]="circleStrokeWidth" [attr.stroke]="lightGrey" />
      <circle [attr.cx]="centerPoint" filter="url(#blurCircle)" [attr.cy]="centerPoint" [attr.r]="outerCircleRadius"
        [attr.stroke-width]="circleOuterBlurStrokeWidth" [attr.stroke]="lightGrey" />
      <circle [attr.cx]="centerPoint" filter="url(#blurCircle)" [attr.cy]="centerPoint" [attr.r]="innerCircleRadius"
        [attr.stroke-width]="circleInnerBlurStrokeWidth" [attr.stroke]="lightGrey" />
      <circle [attr.cx]="centerPoint" [attr.cy]="centerPoint" [attr.r]="innerCircleRadius"
        [attr.stroke-width]="circleStrokeWidth" [attr.stroke]="lightGrey" />
      <filter id="blurCircle">
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
      </filter>

      <path id="arc1" [attr.d]="arc1" [attr.stroke]="transparentGrey"
        [attr.stroke-width]="circleBackgroundStrokeWidth" />
      <path id="arc2" [attr.d]="arc2" [attr.stroke]="transparentGrey"
        [attr.stroke-width]="circleBackgroundStrokeWidth" />
      <path id="arc3" [attr.d]="arc3" [attr.stroke]="transparentGrey"
        [attr.stroke-width]="circleBackgroundStrokeWidth" />
      <path id="arc4" [attr.d]="arc4" [attr.stroke]="transparentGrey"
        [attr.stroke-width]="circleBackgroundStrokeWidth" />

      <text [attr.dx]="arcTextOrderPlaced" [attr.dy]="arcTextOrderPlacedFOrStatus1AndStatus4"
        [attr.font-weight]="statusNumber > 0 ? bold : lighter" [attr.fill]="black" [attr.font-size]="circularFontSize"
        [attr.letter-spacing]="circularTextSpacing">
        <textPath alignment-baseline="top" xlink:href="#arc1">{{orderStatus1}}</textPath>
      </text>
      <text [attr.dx]="arcTextAtXPO" *ngIf="!this.isLmx" name="atxpo" [attr.dy]="arcTextOrderPlacedFOrStatus2AndStatus3"
        [attr.font-weight]="statusNumber > 1 ? bold : lighter" [attr.fill]="black" [attr.font-size]="circularFontSize"
        [attr.letter-spacing]="circularTextSpacing">
        <textPath alignment-baseline="top" xlink:href="#arc2">{{orderStatus2}}</textPath>
      </text>
      <text [attr.dx]="arcTextAtPICKUP" name="atpickup" *ngIf="this.isLmx"
        [attr.dy]="arcTextOrderPlacedFOrStatus2AndStatus3" [attr.font-weight]="statusNumber > 1 ? bold : lighter"
        [attr.fill]="black" [attr.font-size]="circularFontSize" [attr.letter-spacing]="circularTextSpacing">
        <textPath alignment-baseline="top" xlink:href="#arc2">{{orderStatus3}}</textPath>
      </text>

      <text [attr.dx]="arcTextOutForDelivery" [attr.dy]="arcTextOrderPlacedFOrStatus2AndStatus3"
        [attr.font-weight]="statusNumber > 2 ? bold : lighter" [attr.fill]="black" [attr.font-size]="circularFontSize"
        [attr.letter-spacing]="circularTextSpacing">
        <textPath alignment-baseline="top" xlink:href="#arc3">{{orderStatus4}}</textPath>
      </text>
      <text [attr.dx]="arcTextDelivered" [attr.dy]="arcTextOrderPlacedFOrStatus1AndStatus4"
        [attr.font-weight]="statusNumber > 3 ? bold : lighter" [attr.fill]="showDialLabelinRed ? red : black"
        [attr.font-size]="circularFontSize" [attr.letter-spacing]="circularTextSpacing">
        <textPath alignment-baseline="top" xlink:href="#arc4">{{orderStatus5}}</textPath>
      </text>

      <path [attr.d]="progressArc" [attr.stroke-width]="progressArcStrokeWidth" [attr.stroke]="white" />

      <defs>
        <linearGradient [attr.id]="segmentGradientId">
          <stop [attr.offset]="gradientOffset1" [attr.stop-color]="gradientColor1" />
          <stop [attr.offset]="gradientOffset2" [attr.stop-color]="gradientColor2" />
        </linearGradient>
        <!-- displaying red bar for exception at statusNumber 2 and 4 -->
        <linearGradient *ngIf='isException && (
          (order.salesOrderCategoryId != 8 && (statusNumber==2 || statusNumber==4)) ||
          (order.salesOrderCategoryId == 8 && (statusNumber==2 || statusNumber==3)) )' [attr.id]="segmentGradientId1">
          <stop [attr.offset]="gradientOffset3" [attr.stop-color]="gradientColor3" />
          <stop [attr.offset]="gradientOffset4" [attr.stop-color]="gradientColor4" />
        </linearGradient>
      </defs>

      <path [attr.d]="progressArc" [attr.stroke-width]="progressArcStrokeWidth" [attr.stroke]="segmentGradientUrl"
        [attr.stroke-dasharray]="progressArcLength" [attr.stroke-dashoffset]="progressArcLengthFill" />

      <!-- displaying red bar for exception at statusNumber 2 and 4 -->
      <path *ngIf='isException && (
        (order.salesOrderCategoryId != 8 && (statusNumber==2 || statusNumber==4)) ||
        (order.salesOrderCategoryId == 8 && (statusNumber==2 || statusNumber==3)) )' [attr.d]="progressArc"
        [attr.stroke-width]="progressArcStrokeWidth" [attr.stroke]="segmentGradientUrl1"
        [attr.stroke-dasharray]="progressArcLength1" [attr.stroke-dashoffset]="progressArcLengthFill1" />

      <ng-container *ngIf="this.order.salesOrderCategoryId == 8">
        <image attr.xlink:href="{{progressSegmentationIcon1}}" [attr.x]="xAxis-90" [attr.y]="centerPoint-79"
          [attr.width]="21" [attr.height]="21" style="transform-box: fill-box; transform: rotate(21deg)" />
        <image attr.xlink:href="{{progressSegmentationIcon2}}" [attr.x]="xAxis+105" [attr.y]="centerPoint-70"
          [attr.width]="21" [attr.height]="21" style="transform-box: fill-box; transform: rotate(63deg)" />
        <image attr.xlink:href="{{progressSegmentationIcon4}}" [attr.x]="xAxis+52" [attr.y]="centerPoint+82"
          [attr.width]="21" [attr.height]="21" />
        <image attr.xlink:href="{{progressSegmentationIcon5}}" [attr.x]="xAxis-62" [attr.y]="centerPoint+84"
          [attr.width]="21" [attr.height]="21" />
      </ng-container>
      <ng-container *ngIf="this.order.salesOrderCategoryId != 8">
        <image attr.xlink:href="{{progressSegmentationIcon1}}" [attr.x]="xAxis-113" [attr.y]="centerPoint-38"
          [attr.width]="21" [attr.height]="21" />
        <image attr.xlink:href="{{progressSegmentationIcon2}}" [attr.x]="xAxis-6" [attr.y]="centerPoint - 120.5"
          [attr.width]="21" [attr.height]="21" />
        <image attr.xlink:href="{{progressSegmentationIcon3}}" [attr.x]="xAxis+100" [attr.y]="centerPoint-41"
          [attr.width]="21" [attr.height]="21" />
        <image attr.xlink:href="{{progressSegmentationIcon4}}" [attr.x]="xAxis+52" [attr.y]="centerPoint+82"
          [attr.width]="21" [attr.height]="21" />
        <image attr.xlink:href="{{progressSegmentationIcon5}}" [attr.x]="xAxis-62" [attr.y]="centerPoint+84"
          [attr.width]="21" [attr.height]="21" />
      </ng-container>

      <image *ngIf="!isException && this.order.currentStatus==='IT' && this.order.salesOrderCategoryId === 2"
        [attr.x]="xDL" [attr.y]="centerPoint - yDL" text-anchor="middle" attr.xlink:href="{{orderStatusIcon}}"
        [attr.width]="iconWidth" [attr.height]="iconHeight" />
      <image *ngIf="!isException && this.order.currentStatus==='DL'" [attr.x]="xDL" [attr.y]="centerPoint - yDL"
        text-anchor="middle" attr.xlink:href="{{orderStatusIcon}}" [attr.width]="iconWidth"
        [attr.height]="iconHeight" />
      <image *ngIf="isException" (click)="openOrderDetails()" [attr.x]="xDL" [attr.y]="centerPoint - yDL"
        text-anchor="middle" attr.xlink:href="{{exceptionIcon}}" [attr.width]="iconWidth" [attr.height]="iconHeight" />
      <text id="orderCatMsg" *ngIf="scheduleOrderMsgApplicable" [attr.x]="centerPoint" [attr.y]="centerPoint - 35"
        text-anchor="middle" font-weight="bold" [attr.font-size]="12"
        [attr.fill]="black">{{OrderCategoryMessages(order.salesOrderCategoryId)}}</text>
      <text id="itemPlacementStatus" [attr.x]="centerPoint" [attr.y]="centerPoint - 30" text-anchor="middle"
        font-size="18" [attr.fill]="black">{{itemPlacementStatus}}</text>
      <text id="itemStatusOrTimeWindow" [attr.x]="centerPoint" [attr.y]="centerPoint" text-anchor="middle"
        font-weight="bold" [attr.font-size]="itemStatusOrTimeWindowSize"
        [attr.fill]="itemStatusOrTimeWindowColor">{{itemStatusOrTimeWindow}}</text>

        <text *ngIf="isRXO" id="itemStatusOrTimeWindow" [attr.x]="centerPoint" [attr.y]="yItemStatusOrTimeWindow" text-anchor="middle"
        font-weight="bold" [attr.font-size]="itemStatusOrTimeWindowSize"
        [attr.fill]="itemStatusOrTimeWindowColor">{{itemStatusOrTimeWindowPrimary}}</text>
        <text *ngIf="isRXO" id="itemStatusOrTimeWindow" [attr.x]="centerPoint" [attr.y]="yItemStatusOrTimeWindowSecondary" text-anchor="middle"
        font-weight="bold" [attr.font-size]="itemStatusOrTimeWindowSize"
        [attr.fill]="itemStatusOrTimeWindowColor">{{itemStatusOrTimeWindowSecondary}}</text>

        

      <text id="dateWindow" [attr.x]="centerPoint" [attr.y]="centerPoint + 35" text-anchor="middle" font-weight="bold"
        [attr.font-size]="dateWindowSize" [attr.fill]="dateWindowColor">{{dateWindow}}</text>
      <text id="itemProgressStatus" [attr.x]="centerPoint" [attr.y]="centerPoint + 48" text-anchor="middle"
        font-weight="bold" [attr.font-size]="itemProgressStatusSize"
        [attr.fill]="itemProgressStatusColor">{{itemProgressStatus}}</text>
      <a (click)="callCustomerCare()">
        <text class="hidden-desktop" [attr.x]="centerPoint" [attr.y]="centerPoint + 50" text-anchor="middle"
          font-weight="bold" font-size="14" [attr.fill]="blue">{{callXPOInfo}}</text>
      </a>
      <a (click)="callCustomerCare()">
        <text class="hidden-mobile" [attr.x]="centerPoint" [attr.y]="centerPoint + 50" text-anchor="middle"
          font-weight="bold" font-size="14" [attr.fill]="blue">{{callHelpdesk | phone}}</text>
      </a>
      <a (click)="openSurvey()" class="survey-cursor">
        <text class="survey-link" [attr.x]="centerPoint" [attr.y]="centerPoint + 50" text-anchor="middle"
          font-weight="bold" font-size="12" [attr.fill]="blue">{{takeSurvey}}</text>
      </a>
      <a (click)="openRescheduler()" class="survey-cursor">
        <image *ngIf="allowSchedule" [attr.x]="xDL" [attr.y]="centerPoint - yDL" text-anchor="middle"
          attr.xlink:href="{{orderStatusIcon}}" [attr.width]="iconWidth" [attr.height]="iconHeight" />
        <text class="survey-link" [attr.x]="centerPoint" [attr.y]="centerPoint + 50" text-anchor="middle"
          font-weight="bold" font-size="14" [attr.fill]="blue">{{schedule}}</text>
      </a>
      <a>
        <text *ngIf="enableMultiCISegements" class="carousel-btn material-icons" [attr.x]="centerPoint - 67"
          [attr.y]="centerPoint - 40" text-anchor="middle" font-weight="bold" font-size="14"
          [ngStyle]="arrowDisableLeft == false ? {'fill': 'grey'} : {'fill': 'black'}"
          (click)="onLeftClick(true)">&#xE314;</text>
      </a>
      <text [attr.font-size]="12" [attr.fill]="grey" [attr.x]="centerPoint-52" [attr.y]="centerPoint-48"
        class="page-text" *ngIf="enableMultiCISegements">{{appoinmentsCounter}}</text>
      <a>
        <text *ngIf="enableMultiCISegements" class="carousel-btn material-icons" [attr.x]="centerPoint + 67"
          [attr.y]="centerPoint - 40" text-anchor="middle" (click)="onRightClick(true)"
          [ngStyle]="arrowDisableRight == true ? {'fill': 'grey'} : {'fill': 'black'}">&#xE315;</text>
      </a>

    </g>
  </svg>
  <text *ngIf="order.currentStatus === 'EXP'" class="exp-status-message" [attr.fill]="black">{{expiredStatus1}}</text>
  <text *ngIf="order.currentStatus === 'EXP'" class="exp-status-message" [attr.fill]="black">{{expiredStatus2}}</text>
  <text *ngIf="order.currentStatus === 'IS'" class="is-status-message"
    [ngStyle]="enableMultiCISegements == true ? {'top': '-195px', 'width':'185px'} : {'top': '-210px', 'width':'165px'}"
    [attr.fill]="black">{{invalidStatus1}}</text>
  <text *ngIf="order.currentStatus === 'IS'" class="is-status-sub-message"
    [ngStyle]="enableMultiCISegements == true ?  {'top': '-190px', 'width':'165px'} :{'top': '-205px', 'width':'180px'}"
    [attr.fill]="black">{{invalidStatus2}}</text>
  <text *ngIf="order.currentStatus === 'RPS'" class="rpa-status-message" [attr.fill]="black">{{invalidStatus2}}</text>
  <text *ngIf="order.currentStatus === 'PS'"
    [ngClass]="menuService.isMultiOrder == true ?  'ps-status-message':'ps-status-messageSingle'"
    [attr.fill]="black">{{pendingStatus1}} </text>
  <text *ngIf="order.currentStatus === 'PS' && order.expectedHubReceiptDate != null"
    [ngClass]="menuService.isMultiOrder == true ?  'ps-status-message':'ps-status-messageSingle'">{{estimatedHubLabel}}
    {{getFormattedDate(order.expectedHubReceiptDate)}}</text>
  <text *ngIf="order.currentStatus === 'PS'"
    [ngClass]="menuService.isMultiOrder == true ?  'ps-status-sub-message':'ps-status-sub-messageSingle'"
    [attr.fill]="black">{{pendingStatus2}}</text>
  <text *ngIf="displayTimeWindow" class="timewindow-status-sub-message" [attr.fill]="black">{{TimeWindowMessage}}</text>
</div>
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
// angular2-google-maps
// import { AgmCoreModule } from '@agm/core';
import { TrackOrderRoutingModule } from './track-order-routing.module';
import { SharedModule } from '../shared/shared.module';
import { XpoGoogleMapModule } from '../google-map/google-map.module';
// Components
import { TrackOrderComponent } from './track-order/track-order.component';
import { TrackOrderProgressComponent } from './track-order-progress/track-order-progress.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { TrackingEventsComponent } from './widgets/tracking-events/tracking-events.component';
import { TrackOrderMapComponent } from './track-order-map/track-order-map.component';
import { FooterModule } from '../footer/footer.module';
import { FooterComponent } from '../footer/footer.component';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TrafficService, WeatherAlertService } from '../shared';
import { TrackAnotherOrderModule } from '../track-another-order/track-another-order.module';
import { ContactSupportComponent } from './widgets/contact-support/contact-support.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatExpansionModule,
    SharedModule,
    FooterModule,
    MatSlideToggleModule,
    TrackAnotherOrderModule,
    XpoGoogleMapModule.forRoot(),
    TrackOrderRoutingModule // keep at bottom
  ],
  exports: [
    TrackOrderComponent,
    TrackOrderProgressComponent,
    TrackOrderMapComponent,
    ProductDetailsComponent,
    TrackingEventsComponent,
    ContactSupportComponent
  ],
  declarations: [ TrackOrderComponent, ProductDetailsComponent,
    TrackOrderProgressComponent, TrackingEventsComponent,  TrackOrderMapComponent, ContactSupportComponent],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [
    TrafficService, WeatherAlertService, FooterComponent

  ],
  bootstrap: []
})

export class TrackOrderModule { }

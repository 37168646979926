import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { LoaderService } from '../../../../loader/loader';
import { MenuService } from '../../../../core/layout/menu.service';
import { XPOConstants } from '../../../../shared/constants/xpo-constants';
import { OrderService } from '../../../../shared';
import { AppSettings } from '../../../../core/app-settings';
import { SearchContainerComponent } from '../../../../track-another-order/search-container/search-container.component';

@Component({
  selector: 'app-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: [ './page-error.component.scss' ]
})
export class PageErrorComponent implements OnInit {
  public dialogSearchRef;
  public order;
  public showOkay = false;
  public connectionError = false;
  public errHeading: string;
  public errIcon: string;
  public errMessage: string;

  constructor(
    public dialogRef: MatDialogRef<PageErrorComponent>,
    public dialog: MatDialog,
    private menuService: MenuService,
    public spinner: LoaderService,
    private orderService: OrderService,
    private router: Router,
    private location: Location,
    private appSettings: AppSettings,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    switch (data.errorCode) {

      case 403: {
        this.errHeading = this.appSettings.getMessage('FORBIDDEN_TITLE');
        this.errIcon = XPOConstants.ERROR_MESSAGE.FORBIDDEN_ICON;
        this.errMessage = this.appSettings.getMessage('FORBIDDEN_MSG');
        this.showOkay = true;
        break;
      }

      case 404: {
        this.errHeading = this.appSettings.getMessage('PAGE_NOT_FOUND_TITLE');
        this.errIcon = XPOConstants.ERROR_MESSAGE.PAGE_NOT_FOUND_ICON;
        this.errMessage = this.appSettings.getMessage('PAGE_NOT_FOUND_MSG');
        this.showOkay = false;
        break;
      }

      case 410: {
        this.errIcon = XPOConstants.ERROR_MESSAGE.PAGE_NOT_FOUND_ICON;
        this.errMessage = this.appSettings.getMessage('PAGE_NOT_FOUND_MSG_WALMART');
        this.showOkay = true;
        break;
      }

      case 500: {
        this.errHeading = this.appSettings.getMessage('INTERNAL_SERVER_ERROR_TITLE');
        this.errIcon = XPOConstants.ERROR_MESSAGE.INTERNAL_SERVER_ERROR_ICON;
        this.errMessage = this.appSettings.getMessage('INTERNAL_SERVER_ERROR_MSG');
        this.showOkay = true;
        break;
      }

      case 408: {
        this.errHeading = this.appSettings.getMessage('CONNECTION_ERROR_TITLE');
        this.errIcon = XPOConstants.ERROR_MESSAGE.CONNECTION_ERROR_ICON;
        this.errMessage = this.appSettings.getMessage('CONNECTION_ERROR_MSG');
        this.showOkay = true;
        this.connectionError = true;
        break;
      }

      case 422: {
        this.errHeading = this.appSettings.getMessage('INVALID_CODE_TITLE');
        this.errMessage = this.appSettings.getMessage('INVALID_CODE_MSG');
        this.showOkay = true;
        break;
      }

      default: {
        this.errHeading = this.appSettings.getMessage('PAGE_NOT_FOUND_TITLE');
        this.errIcon = XPOConstants.ERROR_MESSAGE.PAGE_NOT_FOUND_ICON;
        this.errMessage = this.appSettings.getMessage('PAGE_NOT_FOUND_MSG');
        this.showOkay = true;
        break;
      }
    }
  }

  ngOnInit() {
    this.orderService.order.subscribe(order => {
      this.order = order;
    });
  }

  search() {
    this.menuService.showCloakView();
    this.menuService.isSearchDialogRequired = true;
    if (this.dialog.openDialogs.length > 0) {
      let margin = window.innerHeight >= XPOConstants.MOBILE_SIZE_LARGE ? XPOConstants.MARGIN_LARGE : XPOConstants.MARGIN_SMALL;
      let top_position = window.innerHeight - XPOConstants.SEARCH_COMPONENT_SIZE - XPOConstants.CONTACT_COMPONENT_SIZE - margin;
      const config: MatDialogConfig = {
        disableClose: true,
        hasBackdrop: true,
        position: {top : this.menuService.isMobileDevice() ? (top_position/2) + 'px' : ''},
        maxWidth: '100%'
      };
      this.dialogSearchRef = this.dialog.open(SearchContainerComponent, config);
      this.dialogSearchRef.afterClosed().subscribe(result => {
        this.dialogSearchRef = null;
      });
    }
  }
  openHome() {
    this.router.navigate([ '' ], { queryParams: { orderid: this.order.orderId } });
    this.menuService.hideMenu();
    this.hideProductDetailsIfMobile();
  }
  closeDialog() {
    this.dialogRef.close();
    if (this.menuService.isSearchDialogRequired) {
      this.menuService.hideCloakView();
      this.menuService.isSearchDialogRequired = false;
    }
    if (!this.order) {
      location.reload();
    } else {
      this.menuService.hideMenu();
    }
  }
  hideProductDetailsIfMobile() {
    if (this.menuService.isMobileDevice()) {
      this.menuService.isHomeSelected = true;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'app/loader/loader';
import { MatDialog } from '@angular/material/dialog';
import { OrderService, XPOConstants, GoogleAnalyticsConstants } from 'app/shared';
import { MenuService } from 'app/core/layout/menu.service';
import { Validators, FormControl } from '@angular/forms';
import { AppSettings } from 'app/core/app-settings';

@Component({
  selector: 'app-track-xpo-reference-number',
  templateUrl: './track-xpo-reference-number.component.html',
  styleUrls: [ './track-xpo-reference-number.component.scss' ]
})
export class TrackXpoReferenceNumberComponent implements OnInit {
  public retailerReferenceNum = '';
  public referencePhoneNum = '';
  public referenceFormControl;
  public referencePhoneFormControl;
  public phoneNumberMask = XPOConstants.PHONE_MASK;

  constructor(
    private spinner: LoaderService,
    public dialog: MatDialog,
    private orderService: OrderService,
    public menuService: MenuService,
    private appSettings: AppSettings
  ) {
    this.referenceFormControl = new FormControl('', [ Validators.required ]);
    this.referencePhoneFormControl = new FormControl('', [ Validators.required, Validators.pattern(XPOConstants.PHONE_REGEX) ]);
  }

  ngOnInit() { }
  public isReferenceFormValid() {
    return (this.retailerReferenceNum && this.referenceFormControl.valid && this.referencePhoneFormControl.valid);
  }

  public retailerSearch(event) {  
    //debugger;
    this.menuService.isUIClick=false;  
    this.appSettings.eventTrack({
      action: GoogleAnalyticsConstants.EVENT_ACTION.RETAILER_SEARCH,
      properties: {
        category: GoogleAnalyticsConstants.EVENT_CATEGORY.SEARCH_ORDER,
        label: GoogleAnalyticsConstants.EVENT_CATEGORY.SEARCH_ORDER
      }
    });
    this.dialog.closeAll();
    this.spinner.showLoader();
    if (this.retailerReferenceNum && this.referencePhoneNum && this.menuService.isNumberValid(this.referencePhoneNum, XPOConstants.PHONE_REGEX)) {
      this.menuService.ismapRefreshNeeded = true;
      this.menuService.segmentRefreshNeeded = true;
      this.menuService.pageIndex = 0;
      this.menuService.multiOrderCIpageIndex = 0;
      this.menuService.needToUpdateCache = true;
      this.menuService.isFromSearch = true;
      
      this.orderService.searchByTrackId(encodeURIComponent(this.retailerReferenceNum.trim()), this.referencePhoneNum.replace(/[\(\)\-\s]+/g, ''))
        .subscribe(() => {
          this.spinner.hideLoader();
          this.menuService.closeDialog(event);
        });
    }
  }
}

import { Component, OnInit, ViewChild, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { XpoGoogleMapLoader } from '../../google-map/services/google-map-api-loader';
import { XpoGoogleMapApi } from '../../google-map/services/google-map-api';
import { MapConstants } from '../../shared/constants/map-constants';
import { MenuService } from '../../core/layout/menu.service';
import { OrderService } from '../../shared/services/order.service';
import { Order, Address, Location, Traffic, TrafficAlertItem, TruckLocation } from '../../shared';
import { TrafficService, WeatherAlertService } from '../../shared';
import { timer } from 'rxjs';
import { AppSettings } from '../../core/app-settings';
import { I18nService } from '../../shared/services/i18n.service';

/**
 * Component responsible from displaying the google map and plot the shipments
 */
@Component({
  selector: 'app-track-order-map',
  templateUrl: './track-order-map.component.html',
  styleUrls: ['./track-order-map.component.scss']
})
export class TrackOrderMapComponent extends XpoGoogleMapApi implements OnInit, OnDestroy {
  @ViewChild('googleMap', { static: true }) mapDiv: ElementRef;

  private mapOptions: any = {
    ...MapConstants.MAP_OPTIONS,
    traffic: true, // Enabling traffic control in the map
    weather: true // Enabling weather control in the map
  };

  public order: Order;
  public validated: boolean;
  // Truck Icons SVG Path
  private truckPath1: string;
  private truckPath2: string;
  private truckPath3: string;
  private circlePath: string;
  private circleMarkers: any[] = [];
  private destinationPath: string;
  private clutteredMarkers: any[] = [];
  private mapTimer: any;
  private markerRescaleSize = 0;
  public showToggle = false;
  public isToggleChecked: boolean;
  public distanceInMiles;
  public isMapUpdateNeedtobeCalled = false;
  private omitStatus = ['CN', 'DL'];
  private mapRefreshedTriggered = false;
  public eligibleToShowTrafficWeatherAlerts = false;
  public showWidget = false;

  constructor(
    private mapLoader1: XpoGoogleMapLoader,
    public orderService: OrderService,
    public menuService: MenuService,
    private trafficServiceChild: TrafficService,
    private weatherAlertServiceChild: WeatherAlertService,
    private ngZoneChild: NgZone,
    public appSettings: AppSettings,
    public i18nService: I18nService
  ) {
    super(mapLoader1, trafficServiceChild, weatherAlertServiceChild, ngZoneChild, appSettings, menuService);
  }

  ngOnInit() {
    this.orderService.order.subscribe(order => {
      if (order) {
        this.order = order;
        this.showWidget = true;
      } else {
        this.showWidget = false;
      }
    });
    this.menuService.ismapRefreshNeeded = true;
    this.truckPath1 = MapConstants.TRUCK_PATH_1;
    this.truckPath2 = MapConstants.TRUCK_PATH_2;
    this.truckPath3 = MapConstants.TRUCK_PATH_3;
    this.circlePath = MapConstants.CIRCLE_PATH;
    this.showToggle = false;
    this.isToggleChecked = true;
    if (this.menuService.isMobileDevice()) {
      this.mapOptions.fullscreenControl = true;
      this.mapOptions.noClear = false;
    }
    if (this.menuService.isLandingPage) {
      this.mapOptions.fullscreenControl = false;
      this.mapOptions.zoomControl = false;
      this.mapOptions.noClear = false;
    } else {
      this.mapOptions.zoomControl = true;
      this.mapOptions.noClear = false;
    }
    const aeris_key = AppSettings.config.AERIS_CLIENT_ID + '_' + AppSettings.config.AERIS_CLIENT_SECRET;
   
      
      {
        this.orderService.order.subscribe(order => { //AppSettings.config.GOOGLE_MAP_KEY
          this.createMap(this.mapDiv.nativeElement, this.mapOptions, 'gme-xpoenterpriseservices', aeris_key, this.i18nService.localeID)
          .then(() =>
          {
          if (order) {
            
            this.order = order;
          // this.order.latitude= 28.041739;
           // this.order.longitude= -82.429187;
                       
            if(this.order.currentStatus === 'IS')
            {
              this.order.isPreCallTrigger = false;
            }
           
            if(this.order.isPreCallTrigger)
            {
              this.order.shipper.latitude = this.order.latitude;
              this.order.shipper.longitude= this.order.longitude;
                     
            setTimeout(() => {
              window.location.reload();
            }, MapConstants.MAP_UPDATE_INTERVAL_ISPRECALL);
            }
            this.orderService.setOrderId(this.order.orderId);
            if (this.order && this.menuService.ismapRefreshNeeded) {
              this.menuService.ismapRefreshNeeded = false;
              const consigneeAddress = this.order.consignee;
              this.validated = this.order.validated;
              this.removeAllMarkers();
              if (this.order.validated) {
                if (consigneeAddress) {
                  if (this.order.middleMileLatitude && this.order.middleMileLongitude && this.order.isLMx) {
                    this.plotMiddleMileMarker();
                  }
                  this.plotHomeMarker(consigneeAddress);
                }
                if ((!this.order.middleMileLatitude && this.order.isLMx)||(this.order.isPreCallTrigger  && !this.order.isLMx) ) {
                  this.plotShipments();
                }
               
                this.eligibleToShowTrafficWeatherAlerts = this.checkForMilesCoverage();
                if (this.eligibleToShowTrafficWeatherAlerts && this.isToggleChecked) {
                  if (this.truckLocations.length > 0) {
                    this.showWeatherAlert();
                  }
                }
                if(this.order.isLMx||(this.order.isPreCallTrigger  && !this.order.isLMx) )  this.drawRoutes();
                if (this.circleMarkers && this.circleMarkers.length === 1) {
                  this.map.setZoom(MapConstants.DEFAULT_ZOOM_LEVEL);
                  // Setting the Map bound based on the markers
                  this.setMapBoundsBasedOnMarkers(this.circleMarkers);
                }
                if (this.circleMarkers && this.circleMarkers.length === 2) {
                  this.map.setZoom(MapConstants.DEFAULT_ZOOM_LEVEL);
                  // Setting the Map bound based on the markers
                  this.setMapBoundsBasedOnMarkers(this.circleMarkers);
                }
                // Map Zoom action listener
                this.map.addListener('zoom_changed', () => {
                  this.mapZoomListener();
                  this.getTrafficBasedOnBounceChange();
                });
              
              } else {
                this.setBoundsForNonValidatedUser(this.order.mapCentroidLatitude, this.order.mapCentroidLongitude);
              }
            }
            this.mapRefreshedTriggered = true;     
          } else {
            this.setBoundsForNonValidatedUser(42.279594, -83.732124);
          }
        }).catch(error => {
        
          // TODO Handle Error Scenario
        });
       
       
        });

      
      if (!this.menuService.isLandingPage) {
        this.updateMap(MapConstants.MAP_UPDATE_INTERVAL);
      }
  }
}

  private checkForMilesCoverage() {
    return this.distanceInMiles && (this.distanceInMiles <= MapConstants.TRAFFIC_COVERAGE) && (this.order.currentStatus === 'IT');
  }

  private getTrafficBasedOnBounceChange() {
    this.map.addListener('bounds_changed', () => {
      if (this.eligibleToShowTrafficWeatherAlerts && this.isToggleChecked) {
        setTimeout(() => {
          this.callWeatherTrafficAlert();
        }, 800);
      }
    });
  }

  private setBoundsForNonValidatedUser(latitude, longitude) {
    const markers = [];
    if (!(latitude && longitude)) {
      return;
    }
    const baseIcon = {
      scale: MapConstants.HOME_DEFAULT_ZOOM,
      strokeWeight: 0,
      fillOpacity: 0,
      anchor: this.createPoint(26, 58)
    };
    const transparentCircleIcon = {
      ...baseIcon,
      path: MapConstants.HOME_PATH_1,
      strokeColor: MapConstants.TRANSPARENT_COLOR,
      fillColor: MapConstants.TRANSPARENT_COLOR,
    };
    const baseMarker = {
      position: {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
      },
      clickable: false
    };
    const nonValidatedUserMarker = this.createMarker({
      ...baseMarker,
      icon: transparentCircleIcon
    }, true);

    markers.push(nonValidatedUserMarker);
    this.setMapBoundsBasedOnMarkers(markers);
    if (this.menuService.isLandingPage) {
      this.map.setZoom(23);
    } else {
      this.map.setZoom(MapConstants.NON_VALIDATED_USER_ZOOM_LEVEL);
    }

  }

  ngOnDestroy() {
    if (this.mapTimer) {
      this.mapTimer.unsubscribe();
    }
  }

  private updateMap(intervalInMS: number) {  
    if (!this.menuService.isUserValidating) {
      this.removeAllMarkers();
      const timer2 = timer(0, intervalInMS);
      this.mapTimer = timer2.subscribe(t => {
        if (this.orderService.orderId && this.isMapUpdateNeedtobeCalled && navigator.onLine && this.mapRefreshedTriggered) {
          this.menuService.segmentRefreshNeeded = false;
          this.menuService.ismapRefreshNeeded = true;
          this.menuService.isTimerTriggered = true;
          this.menuService.needToUpdateCache = false;
          this.orderService.orderRefresh = true;
          this.orderService.searchOrder(this.orderService.orderId).subscribe(() => {
          });
        }
        this.isMapUpdateNeedtobeCalled = true;
       });
    }
  }

  private callWeatherTrafficAlert() {
    if (this.truckLocations.length > 0) {
      if (!this.trafficAlertAPICalled) {
        this.trafficAlertAPICalled = true;
        this.showTrafficAlert(true);
      }
    }
  }

  // Method which handles the map zoom level changes
  private mapZoomListener() {
    const zoomLevel: number = this.map.getZoom();
    let truckScale = 0;
    let homeScale = 0;
    if (zoomLevel <= 3) {
      truckScale = 0.5;
      homeScale = 0.3;
    } else if (zoomLevel <= 5) {
      truckScale = 1;
      homeScale = 0.6;
    } else {
      truckScale = 1.5;
      homeScale = 0.9;
    }
    if (this.markerRescaleSize !== homeScale) {
      this.markerRescaleSize = homeScale;
      this.rescaleMarker(truckScale, homeScale);
    }
  }

  // Method responsible for rescaling the markers
  private rescaleMarker(truckScale: number, homeScale: number) {
    
    for (const clutteredMarker of this.clutteredMarkers) {
      // Iterating the cluttered markers
      for (const markerName of Object.keys(clutteredMarker)) {
        const marker = clutteredMarker[markerName];
        let scale = truckScale;
        if (markerName.startsWith('home')) {
          scale = homeScale;
        }
        const icon = marker.getIcon();
        // Rescaling the marker icon
        if(typeof(icon) !== 'string') {
          icon.scale = scale;
        }
        marker.setIcon(icon);
      }
    }
  }
  // Method responsible for clearing all the markers
  private removeAllMarkers() {
    for (const clutteredMarker of this.clutteredMarkers) {
      // Iterating the cluttered markers
      for (const markerName of Object.keys(clutteredMarker)) {
        const marker = clutteredMarker[markerName];
        marker.setMap(null);
      }
    }
     this.distanceInMiles = 200;
    this.circleMarkers = [];
    this.clutteredMarkers = [];
    this.trafficAlertItems = [];
    this.weatherAlertList = [];
  }
  // Method responsible for plotting the shipments in the map
  private plotShipments() {
    this.addMarker(this.order);
    if (this.order.latitude != null && this.order.longitude != null) {
      this.truckLocations = [];
      this.truckLocations.push(new TruckLocation(this.order.latitude, this.order.longitude));
      if (this.order.consignee.latitude && this.order.consignee.longitude) {
        this.distanceInMiles = this.distance(this.order.latitude, this.order.longitude,
          this.order.consignee.latitude, this.order.consignee.longitude, MapConstants.COVERAGE_IN_UNIT);
      }
    }
  }

  private plotMiddleMileMarker() {
    const latitude = this.order.middleMileLatitude;
    const longitude = this.order.middleMileLongitude;
    if (!(latitude && longitude)) {
      return;
    }
    const baseIcon = {
      scale: MapConstants.HOME_DEFAULT_ZOOM,
      strokeWeight: 1,
      fillOpacity: 1,
      anchor: this.createPoint(26, 58) // Aligning the home marker
    };
    const circleIcon1 = {
      ...baseIcon,
      path: MapConstants.CIRCLE_PATH,
      strokeColor: MapConstants.BLUE_COLOR,
      fillColor: MapConstants.BLUE_COLOR,
    };
    const baseMarker = {
      position: {
        lat: latitude,
        lng: longitude
      },
      clickable: false
    };
    const home0Marker = this.createMarker({
      ...baseMarker,
      icon: {
        url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
      }

    }, true);
    const marker = {
      home0: home0Marker // is fixing home icon missing issue is ios

    };
    this.circleMarkers.push(home0Marker);
    this.clutteredMarkers.push(marker);

  }

  private plotHomeMarker(consigneeAddress: Address) {
    const latitude = consigneeAddress.latitude;
    const longitude = consigneeAddress.longitude;

    if (!(latitude && longitude)) {
      return;
    }
    const baseIcon = {
      scale: MapConstants.HOME_DEFAULT_ZOOM,
      strokeWeight: 1,
      fillOpacity: 1,
      anchor: this.createPoint(26, 58) // Aligning the home marker
    };

    const homeIcon1 = {
      ...baseIcon,
      path: MapConstants.HOME_PATH_1,
      strokeColor: MapConstants.BLUE_COLOR,
      fillColor: MapConstants.BLUE_COLOR,
    };
    const homeIcon2 = {
      ...baseIcon,
      path: MapConstants.HOME_PATH_2,
      strokeColor: MapConstants.WHITE_COLOR,
      fillColor: MapConstants.WHITE_COLOR,
    };
    const homeIcon3 = {
      ...baseIcon,
      path: MapConstants.HOME_PATH_3,
      strokeColor: MapConstants.WHITE_COLOR,
      fillColor: MapConstants.WHITE_COLOR,
    };
    const homeIcon4 = {
      ...baseIcon,
      path: MapConstants.HOME_PATH_4,
      strokeColor: MapConstants.BLUE_COLOR,
      fillColor: MapConstants.BLUE_COLOR,
    };

    const baseMarker = {
      position: {
        lat: latitude,
        lng: longitude
      },
      clickable: false
    };

    const home0Marker = this.createMarker({
      ...baseMarker,
      icon: homeIcon1
    }, true);

    const home1Marker = this.createMarker({
      ...baseMarker,
      icon: homeIcon1
    }, true);

    const home2Marker = this.createMarker({
      ...baseMarker,
      icon: homeIcon2
    }, true);

    const home3Marker = this.createMarker({
      ...baseMarker,
      icon: homeIcon3
    }, true);

    const home4Marker = this.createMarker({
      ...baseMarker,
      icon: homeIcon4
    }, true);

    const marker = {
      home0: home0Marker, // is fixing home icon missing issue is ios
      home1: home1Marker,
      home2: home2Marker,
      home3: home3Marker,
      home4: home4Marker
    };
    this.circleMarkers.push(home1Marker);
    this.clutteredMarkers.push(marker);
  }

  private drawRoutes() {
    var stops = [];
    var latLang;
    if (this.order.middleMileLatitude) {
      latLang = { 'lat': this.order.middleMileLatitude, 'lng': this.order.middleMileLongitude };
      stops.push({ 'latitude': this.order.shipper.latitude, 'longitude': this.order.shipper.longitude });
    } else {
      latLang = { 'lat': this.order.shipper.latitude, 'lng': this.order.shipper.longitude };
      if(this.order.isPreCallTrigger)
      {
        stops.push({ 'latitude': this.order.shipper.latitude, 'longitude': this.order.shipper.longitude });
      }
    }
    const waypts = [];
    stops.forEach(element => {
      waypts.push({
        location: { lat: parseFloat(element.latitude), lng: parseFloat(element.longitude) },
        stopover: true
      });
      if(!this.order.isPreCallTrigger)
      {
      const googleMarkerOrigin = new google.maps.Marker();
      googleMarkerOrigin.setMap(this.map);
      googleMarkerOrigin.setPosition({ lat: parseFloat(element.latitude), lng: parseFloat(element.longitude) });
      googleMarkerOrigin.setIcon({
        url: 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png'
      }
      );
    }
    });
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLang },
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {

        /* eslint-disable max-len */
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            let consigneeLocation = { lat : this.order.consignee.latitude, lng : this.order.consignee.longitude };
            let chkStatus = false;
            const chkstatusMap = ['OP', 'PS'];
            if (chkstatusMap.indexOf(this.order.currentStatus) > -1) {
              chkStatus = true;
            }
            if (this.order.middleMileLatitude) {
              if (!chkStatus) {
                this.drawRoute(latLang, waypts[waypts.length - 1].location, waypts,this.clutteredMarkers, false);
              } else {
                this.drawRoute(latLang, waypts[waypts.length - 1].location, waypts, this.clutteredMarkers, true);
              }
              const isDLOrderStatus = this.order.currentStatus === 'DL';
              this.drawRoute(waypts[waypts.length - 1].location, consigneeLocation, waypts, this.clutteredMarkers, isDLOrderStatus ? false : true);
            } else {

if(this.order.isPreCallTrigger)
{
  this.drawRoute(waypts[waypts.length - 1].location, consigneeLocation, waypts, this.clutteredMarkers, false);

}
else
{
    this.drawRoute(latLang, consigneeLocation, waypts,this.clutteredMarkers, false);
}
                                 }

          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  // Method responsible for plotting the markers in the map
  private addMarker(order: Order) {
    const latitude = order.shipper.latitude;
    const longitude = order.shipper.longitude;

    if (!(latitude && longitude)) {
      return;
    }

    const currentLocation = {
      latitude,
      longitude
    };
    let displayimage = './assets/images/truck_icons_green icon 0.png';
    var heading = this.findTruckDirection(currentLocation, order.consignee);

    if(heading < 0)
    {
      heading = 360 - (-(heading));
    }
    if(heading < 15)
    {
      displayimage = './assets/images/truck_0.png'
    }
    else if(heading >= 15 && heading < 30)
    {
      displayimage = './assets/images/truck_15.png'
    }
    else if(heading >= 30 && heading < 45)
    {
      displayimage = './assets/images/truck_30.jpg'
    }
    else if(heading >= 45 && heading < 60)
    {
      displayimage = './assets/images/truck_45.png'
    }
    else if(heading >= 60 && heading < 75)
    {
      displayimage = './assets/images/truck_60.png'
    }
    else if(heading >= 75 && heading < 90)
    {
      displayimage = './assets/images/truck_75.png'
    }
    else if(heading >= 90 && heading < 105)
    {
      displayimage = './assets/images/truck_90.png'
    }
    else if(heading >= 105 && heading < 120)
    {
      displayimage = './assets/images/truck_105.png'
    }
    else if(heading >= 120 && heading < 135)
    {
      displayimage = './assets/images/truck_120.jpg'
    }
    else if(heading >= 135 && heading < 150)
    {
      displayimage = './assets/images/truck_135.png'
    }
    else if(heading >= 150 && heading < 165)
    {
      displayimage = './assets/images/truck_150.png'
    }

    else if(heading >= 165 && heading < 180)
    {
      displayimage = './assets/images/truck_165.png'
    }
    else if(heading >= 180 && heading < 195)
    {
      displayimage = './assets/images/truck_180.png'
    }

    else if(heading >= 195 && heading < 210)
    {
      displayimage = './assets/images/truck_195.png'
    }

    else if(heading >= 210 && heading < 225)
    {
      displayimage = './assets/images/truck_210.jpg'
    }
    else if(heading >= 225 && heading < 240)
    {
      displayimage = './assets/images/truck_225.png'
    }
    else if(heading >= 240 && heading < 270)
    {
      displayimage = './assets/images/truck_240.png'
    }
    else if(heading >= 270 && heading < 285)
    {
      displayimage = './assets/images/truck_270.png'
    }
    else if(heading >= 285 && heading < 315)
    {
      displayimage = './assets/images/truck_285.png'
    }
    else
    {
      displayimage = './assets/images/truck_315.png'
    }




    const baseIcon = {
      scale: MapConstants.TRUCK_DEFAULT_ZOOM,
      strokeWeight: 1,
      fillOpacity: 1,
      anchor: this.createPoint(16, 16), // Centering the truck icon in the marker
      rotation: heading,
    };



    const truckIcon1 = {
      ...baseIcon,
      path: this.truckPath1,
      strokeColor: MapConstants.TRUCK_PATH_1_COLOR,
      fillColor: MapConstants.TRUCK_PATH_1_COLOR,
      anchor: this.createPoint(6, 10)
    };


    const baseMarker = {
      position: {
        lat: latitude,
        lng: longitude
      },
      clickable: false
    };

    // Creating multiple markers to draw the truck and circle in different colors
    // This is the only technique where we can rotate multiple markers
    const truck1Marker = this.createMarker({
      ...baseMarker,
      icon: (order.isPreCallTrigger || !order.isLMx) ? displayimage : 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png'
    }, true);

  
    const marker = {
      truck1: truck1Marker
    };
    // Array of markers for resizing
    this.clutteredMarkers.push(marker);
  }

  // Method responsible for fetching the heading of the truck from the Google API
  private findTruckDirection(origin: Location, destination: Address) {
    const originMarker = this.createMarker({
      position: {
        lat: origin.latitude,
        lng: origin.longitude
      }
    }, false);

    const destinationMarker = this.createMarker({
      position: {
        lat: destination.latitude,
        lng: destination.longitude
      }
    }, false);

    return this.findHeading(originMarker.getPosition(), destinationMarker.getPosition());
  }

  public onChangeToggle(event) {
    if (event.checked) {
      event.value = 'true';
      this.isToggleChecked = true;
    } else {
      event.value = 'false';
      this.isToggleChecked = false;
    }
  }
  public notificationToggleOnOff() {
    this.showToggle = !this.showToggle;
  }
}

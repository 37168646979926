import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchComponent } from './track-another-order/search.component';
import { AuthenticateService } from './shared/services/authenticate.service';


const appRoutes: Routes = [
  { path: 'search', component: SearchComponent },
  { path: 'order-details', loadChildren: () => import('app/order-details/order-details.module').then(m => m.OrderDetailsModule) },
  { path: 'profile', loadChildren: () => import('app/profile-dialog/profile-dialog.module').then(m => m.ProfileDialogModule), canActivate: [ AuthenticateService ] },
  { path: 'alerts', loadChildren: () => import('app/sms-signup/sms-signup.module').then(m => m.SmsSignupModule), canActivate: [ AuthenticateService ] },
  { path: 'check-list', loadChildren: () => import('app/check-list/check-list.module').then(m => m.CheckListModule) },
  { path: 'e-sign', loadChildren: () => import('app/e-sign/e-sign.module').then(m => m.ESignModule)},
  { path: 'confirmation', loadChildren: () => import('app/e-sign/e-sign.module').then(m => m.ESignModule) },
  { path: 'contact-us', loadChildren: () => import('app/contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: 'verification', loadChildren: () => import('app/verification/verification.module').then(m => m.VerificationModule) },
  { path: 'add-to-calendar', loadChildren: () => import('app/add-to-calendar/add-to-calendar.module').then(m => m.AddToCalendarModule), canActivate: [ AuthenticateService ] },
  { path: 'reschedule', loadChildren: () => import('app/reschedule/reschedule.module').then(m => m.RescheduleModule)},
  { path: 'survey', loadChildren: () => import('app/survey/survey.module').then(m => m.SurveyModule)},
  { path: 'notification', loadChildren: () => import('app/notification/notification.module').then(m => m.NotificationModule), },
  { path: 'unsubscribe', loadChildren: () => import('app/unsubscribe-email/unsubscribe-email.module').then(m => m.UnsubscribeEmailModule) },
  { path: 'images', loadChildren: () => import('app/images/images.module').then(m => m.ImagesModule) },
  { path: 'hours', loadChildren: () => import('app/hours-of-operation/hours-of-operation.module').then(m => m.HoursOfOperationModule)},
  { path: 'fr/search', component: SearchComponent },
  { path: 'fr/order-details', loadChildren: () => import('app/order-details/order-details.module').then(m => m.OrderDetailsModule) },
  { path: 'fr/profile', loadChildren: () => import('app/profile-dialog/profile-dialog.module').then(m => m.ProfileDialogModule), canActivate: [ AuthenticateService ] },
  { path: 'fr/alerts', loadChildren: () => import('app/sms-signup/sms-signup.module').then(m => m.SmsSignupModule), canActivate: [ AuthenticateService ] },
  { path: 'fr/check-list', loadChildren: () => import('app/check-list/check-list.module').then(m => m.CheckListModule) },
  { path: 'fr/e-sign', loadChildren: () => import('app/e-sign/e-sign.module').then(m => m.ESignModule)},
  { path: 'fr/confirmation', loadChildren: () => import('app/e-sign/e-sign.module').then(m => m.ESignModule) },
  { path: 'fr/contact-us', loadChildren: () => import('app/contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: 'fr/verification', loadChildren: () => import('app/verification/verification.module').then(m => m.VerificationModule) },
  { path: 'fr/add-to-calendar', loadChildren: () => import('app/add-to-calendar/add-to-calendar.module').then(m => m.AddToCalendarModule), canActivate: [ AuthenticateService ] },
  { path: 'fr/reschedule', loadChildren: () => import('app/reschedule/reschedule.module').then(m => m.RescheduleModule)},
  { path: 'fr/survey', loadChildren: () => import('app/survey/survey.module').then(m => m.SurveyModule)},
  { path: 'fr/notification', loadChildren: () => import('app/notification/notification.module').then(m => m.NotificationModule), },
  { path: 'fr/unsubscribe', loadChildren: () => import('app/unsubscribe-email/unsubscribe-email.module').then(m => m.UnsubscribeEmailModule) },
  { path: 'fr/images', loadChildren: () => import('app/images/images.module').then(m => m.ImagesModule) },
  { path: 'fr/hours', loadChildren: () => import('app/hours-of-operation/hours-of-operation.module').then(m => m.HoursOfOperationModule)},
  { path: '**', redirectTo: '' }

];

@NgModule({
  imports: [ RouterModule.forRoot(appRoutes, { }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }

export abstract class BaseLoginProvider {

  constructor() { }

  abstract initialize(): Promise<any>;
  abstract openSession(): Promise<any>;
  abstract closeSession(): Promise<any>;
  abstract getCalendarFreeBusyData(orders): Promise<any>;
  abstract addEventToCalendar(calendarAddEventObject): Promise<any>;

  public loadScript(id: string, src: string, onload: any): void {
    if (document.getElementById(id)) { return; }

    const signInJS = document.createElement('script');
    signInJS.async = true;
    signInJS.src = src;
    signInJS.onload = onload;
    document.head.appendChild(signInJS);
  }
}

import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { AddToCalendarRoutingModule } from '../add-to-calendar/add-to-calendar-routing.module';
import { AddToCalendarComponent } from '../add-to-calendar/add-to-calendar.component';
import { CalendarHeaderComponent } from '../calendar-utils/calendar-header/calendar-header.component';
import { AppSettings } from '../core/app-settings';
import { AddToCalendarService } from '../shared';
import { AuthServiceConfig } from '../calendar/services/auth.service';
import { GoogleLoginProvider } from '../calendar/providers/google-login-provider';
import { OutlookLoginProvider } from '../calendar/providers/outlook-login-provider';
import { SharedService } from '../shared/services/shared.service';
import { ConfirmationDialogModule } from '../confirmation-dialog/confirmation-dialog.module';
import { FooterModule } from '../footer/footer.module';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';

export function provideConfig() {
  const config = new AuthServiceConfig([
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(AppSettings.config.GOOGLE_CALENDAR_API_KEY)
    },
    {
      id: OutlookLoginProvider.PROVIDER_ID,
      provider: new OutlookLoginProvider(AppSettings.config.OUTLOOK_CALENDAR_API_KEY)
    }
  ]);
  return config;
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatRadioModule,
    MatButtonModule,
    MatCheckboxModule,
    FooterModule,
    ConfirmationDialogModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    AddToCalendarRoutingModule
  ],
  declarations: [
    AddToCalendarComponent,
    CalendarHeaderComponent
  ],
  providers: [
    AddToCalendarService,
    SharedService
  ],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class AddToCalendarModule { }

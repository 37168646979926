import { Component, OnInit, Input } from '@angular/core';
import { XPOConstants } from '../../../shared';
import { AppSettings } from '../../../core/app-settings';
import { I18nService } from '../../../shared/services/i18n.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: [ './language-selector.component.scss' ]
})
export class LanguageSelectorComponent implements OnInit {
  @Input() orderId: string;
  public Languages = [];
  private prefLanguage;
  public selectedValue;

  constructor(private appSettings: AppSettings,
    private i18nService: I18nService) {
  }

  ngOnInit() {
    this.selectedValue = this.i18nService.getLanguage();
    this.Languages = [
      { value: XPOConstants.I18N_EN, viewValue: this.appSettings.getMessage('ENGLISH') },
      { value: XPOConstants.I18N_FR, viewValue: this.appSettings.getMessage('FRENCH') }
    ];
  }

  onChange(e) {
    this.prefLanguage = e.value;
    this.i18nService.setLanguage(this.prefLanguage, this.orderId);
    this.i18nService.reLoadURL(this.prefLanguage);
  }

}

import { NgModule, ModuleWithProviders } from '@angular/core';
import { XpoGoogleMapLoader } from './services/google-map-api-loader';
import { XpoGoogleMapApi } from './services/google-map-api';

@NgModule()
export class XpoGoogleMapModule {
    // Responsible for loading singleton services
    static forRoot(): ModuleWithProviders<XpoGoogleMapModule> {
        return {
            ngModule: XpoGoogleMapModule,
            providers: [XpoGoogleMapLoader, XpoGoogleMapApi]
        };
    }
}

import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { PlatformLocation, Location } from '@angular/common';
import { XPOConstants } from '../../shared/constants/xpo-constants';
import { OrderService } from '../../shared';

@Injectable()
export class I18nService {

  private XPOLanguage = [ XPOConstants.I18N_EN, XPOConstants.I18N_FR ];
  private userPreferedLanguage;
  private fileName: string;
  public localeID;
  public locationPath = '';
  public tokenOrder;
  public fromSearchDialog = false;

  constructor(public cookieService: CookieService,
    locationPath: Location, @Inject(LOCALE_ID) locale: string) {
    this.fileName = XPOConstants.I18N_FILE_NAME;
    this.userPreferedLanguage = this.cookieService.get(XPOConstants.I18N_COOKIES_KEY);
    this.localeID = XPOConstants.I18N_FR.includes(locale) ? XPOConstants.I18N_FR : XPOConstants.I18N_EN;
    this.locationPath = locationPath.path();
  }

  public getLanguage() {
    if (this.userPreferedLanguage) {
      this.reloadCheck(this.userPreferedLanguage);
      return this.userPreferedLanguage;
    } else if (this.localeID === (XPOConstants.I18N_FR || XPOConstants.I18N_EN)) {
      return this.localeID;
    } else {
      if (this.XPOLanguage.indexOf(navigator.language) !== -1) {
        this.reloadCheck(navigator.language);
        return navigator.language;
      } else {
        this.reloadCheck(XPOConstants.I18N_EN);
        return XPOConstants.I18N_EN;
      }
    }
  }

  private reloadCheck(language) {
    if (language !== this.localeID && !language.includes(this.localeID)) {
      this.reLoadURL(language);
    }
  }

  public setLanguage(prefLanguage: string, searchedOrder: string) {
    this.tokenOrder = searchedOrder;
    this.cookieService.delete(XPOConstants.I18N_COOKIES_KEY, '/fr');
    this.cookieService.delete(XPOConstants.I18N_COOKIES_KEY, '/fr/');
    this.cookieService.delete(XPOConstants.I18N_COOKIES_KEY, '/');
    this.cookieService.set(XPOConstants.I18N_COOKIES_KEY, prefLanguage);
  }

  public loadI18nJson(language) {
    if (language === XPOConstants.I18N_FR) {
      this.fileName += '-fr';
    }
    return this.fileName;
  }

  public reLoadURL(prefLanguage) {

    if (this.tokenOrder) {
      if (this.fromSearchDialog) {
        switch (this.locationPath) {
          case '':
            this.locationPath = this.locationPath + '?orderid=' + this.tokenOrder;
            break;
          case '?orderid=':
            this.locationPath = this.locationPath + this.tokenOrder;
            break;
          case '?orderid':
            this.locationPath = this.locationPath + '=' + this.tokenOrder;
            break;
          case '?':
            this.locationPath = this.locationPath + 'orderid=' + this.tokenOrder;
            break;
        }
        this.fromSearchDialog = false;
      } else {
        this.locationPath = this.locationPath.replace(this.locationPath.split('=', 2)[ 1 ], this.tokenOrder);
      }

      if (prefLanguage === XPOConstants.I18N_FR) {
        this.locationPath = '?orderid=' + this.tokenOrder;
        location.replace(`/${'fr'}/${this.locationPath}`);
      } else {
        this.locationPath = '?orderid=' + this.tokenOrder;
        location.replace(`/${this.locationPath}`);
      }

    } else {
      if (prefLanguage === XPOConstants.I18N_FR) {
        if (this.locationPath.startsWith('/')) {
          location.replace(`/${'fr'}${this.locationPath}`);
        } else {
          location.replace(`/${'fr'}/${this.locationPath}`);
        }
      } else {
        //trimming the fr path to load the en ng version
        if (this.locationPath.startsWith('/fr')) {
          location.replace(`${this.locationPath.substring(3)}`);
        } else {
          location.replace(`${this.locationPath.substring(2)}`);
        }
      }
    }

  }

  public getLocale() {
    if (this.localeID === XPOConstants.I18N_FR) {
      return true;
    } else {
      return false;
    }
  }
}

/**
 * Model class which contains the google map config/constant properties
 */
export class MapConstants {
    static readonly MAP_UPDATE_INTERVAL = 60 * 60000; // 60 Minutes
    static readonly MAP_UPDATE_INTERVAL_ISPRECALL = 2.5 * 60000; // 60 Minutes
    static readonly TRAFFIC_COVERAGE = 20; // in miles
    static readonly COVERAGE_IN_UNIT = 'M'; // M -Miles, K -kilometer, N -nanoMiles

    // Right now centering the map to USA and setting the zoom level to 5
    // Once the markers are plotted, the zoom level will be changed to accommodate all the markers
    static readonly DEFAULT_LOCATION: any = {
        lat: 39,
        lng: -95
    };
    static readonly DEFAULT_ZOOM_LEVEL: number = 5;
    static readonly NON_VALIDATED_USER_ZOOM_LEVEL: number = 14;

    static readonly MAP_OPTIONS: any = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: 'greedy',
        center: MapConstants.DEFAULT_LOCATION,
        zoom: MapConstants.DEFAULT_ZOOM_LEVEL,
        minZoom: 3,
        maxZoom: 15
    };

    static readonly TRUCK_PATH_1: string = `
    M11.3,21.7H0.1V8.2h11.2V21.7z M9.6,8.2L7.7,5.3H3.8L1.9,8.2H9.6z
    `;
    static readonly TRUCK_PATH_2: string = `
    M2.3,7.6l0-6.2l0.1-0.3l1.6-0.7l3.3,0l1.7,0.7l0.1,0.3l0,6.2L7.7,5.3l-3.9,0L2.3,7.6z
    `;
    static readonly TRUCK_PATH_3: string = `
    M3.1,5.1L2.6,2.7l1.5-0.2l3.3,0l1.5,0.2L8.5,5.1L7.4,4.9l-3.3,0L3.1,5.1z M1.7,3.5L1.7,3.5L1.6,3.3L1.1,3.4L1,3.5l0,0.2
    l0.7-0.1l0.8,0l0-0.1L1.7,3.5L1.7,3.5z M9.8,3.5L9,3.5l0,0.1l0.8,0l0.7,0.1l0-0.2l-0.1-0.1L10,3.3L9.8,3.4L9.8,3.5L9.8,3.5z
     M4.1,0.4l3.3,0l1.7,0.7L9,0.8L7.4,0.2L5.8,0.1L4.1,0.2L2.6,0.8L2.5,1.1L4.1,0.4z M3,21.6H1.5v0.1H3V21.6z M9.9,21.6H8.4v0.1h1.5
    V21.6z
    `;
    static readonly CIRCLE_PATH: string = `
        M16,32C7.2,32,0,24.8,0,16C0,7.2,7.2,0,16,0c8.8,0,16,7.2,16,16C32,24.8,24.8,32,16,32z M16,0.1
            C7.2,0.1,0.1,7.2,0.1,16S7.2,31.9,16,31.9c8.8,0,15.9-7.1,15.9-15.9S24.8,0.1,16,0.1z
        M16,0.1C7.2,0.1,0.1,7.2,0.1,16c0,8.8,7.1,15.9,15.9,15.9c8.8,0,15.9-7.1,15.9-15.9C31.9,7.2,24.8,0.1,16,0.1z
    `;

    static readonly TRUCK_PATH_1_COLOR: string = '#dbdbdb';
    static readonly TRUCK_PATH_2_COLOR: string = '#dbdbdb';
    static readonly TRUCK_PATH_3_COLOR: string = '#000000';

    static readonly HOME_PATH_1: string = `
        M26,0 C11.6404889,0 0,11.6404889 0,26 C0,40.3595111 11.6404889,52 26,52 C40.3595111,52 52,
        40.3595111 52,26 C52,11.6404889 40.3595111,0 26,0 Z`;
    static readonly HOME_PATH_2: string = `
        M38.1870667 37.5971556 28.548 37.5971556 28.548 28.5173778 23.4525778 28.5173778 23.4525778
        37.5971556 13.8129333 37.5971556 13.8129333 23.9176889 26 15.2342667 38.1870667 23.9176889 Z`;
    static readonly HOME_PATH_3: string = `
        M40.0284444 23.9176889 26 14.0088 11.9727111 23.9176889 9.29875556 23.9176889 26 12.0905778
        42.7012444 23.9171111 40.0284444 23.9171111 Z`;
    static readonly HOME_PATH_4: string = 'm 34,50 -9,9 -9,-9 z';

    static readonly BLUE_COLOR: string = '#0091EA';
    static readonly WHITE_COLOR: string = '#FFFFFF';
    static readonly TRANSPARENT_COLOR: string = 'transparent';

    static readonly HOME_DEFAULT_ZOOM = 0.9;
    static readonly TRUCK_DEFAULT_ZOOM = 1.5;
}

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { XPOConstants } from '../../../shared';
import { I18nService } from '../../../shared/services/i18n.service';

@Injectable()
export class SetHeaderInterceptor implements HttpInterceptor {
  private cloneReq;
  private i18nLanguage;
  constructor(public i18nService: I18nService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.match(XPOConstants.AERIS_WEATHER_PI_URL)) { // To-Do: need to refine this logic
      this.cloneReq = req.clone(
        {
          headers: req.headers
            .append('Content-Type', 'application/json')
        });
    } else {
      this.i18nLanguage = this.i18nService.getLanguage();
      this.cloneReq = req.clone(
        {
          headers: req.headers
            .append('Content-Type', 'application/json')
            .append('Accept-Language', this.i18nLanguage)
            .append('Access-Control-Allow-Origin', '*')
            .append('X-Requested-With', 'XMLHttpRequest')
            .append('Cache-Control', 'no-cache')
            .append('Pragma', 'no-cache')
        });
    }
    return next.handle(this.cloneReq);
  }
}

